import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { ENABLE_ONLINE_BANKING_PASSWORD_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetDebitCardNumberRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = ENABLE_ONLINE_BANKING_PASSWORD_URL;

  constructor(applicantId, applicationId, debitCardProductId) {
    super();

    this.body = {
      applicationId,
      debitCardProductId,
      getDebitCardNumberOnly: true,
    };
    const paramList = [applicantId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
