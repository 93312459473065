import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { format } from "date-fns";

class LoanLoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      loanDetails: {},
      loanAmount: 500,
      loanTerm: AppConstants.REVOLVING,
      loanRate: 0,
      monthlyPayment: 0,
      paymentType: "monthly",
      paymentTypes: [],
      loanConfig: {
        loanAmount: "",
        loanTerm: "",
        loanRate: "",
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    getDataFromServer(AppConstants.APPLICATIONSTEP.LOAN_DETAILS, (response) => {
      this.setFormData(response);
    });
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { loanProducts, products } = this.props;

    if (loanProducts !== prevProps.loanProducts) {
      this.initialize();
    }
  }

  setFormData = () => {};

  handleChange = (prop) => (event) => {
    console.log("handleChange", prop);
    this.setState({ prop: event.target.value });
    this.doCalculation();
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue(this.doUpdateLoanForm());
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_DETAILS);
  };

  initialize() {
    console.log("initialize");
    const { loanProducts, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);

    if (!activeProduct) {
      return;
    }
    const productId = activeProduct.productId;
    let purposes = [];
    let paymentTypes = [];
    let loanConfig = {};

    if (loanProducts) {
      const loanProduct = loanProducts.find(
        (product) => product.id === productId
      );
      purposes = loanProduct.purpose;
      paymentTypes = loanProduct.paymentTypes;
      loanConfig = {
        loanAmount: loanProduct.loanAmount,
        loanTerm: loanProduct.loanTerm,
        loanRate: loanProduct.loanRate,
      };
    }

    let loanDetails = activeProduct.loanDetails
      ? activeProduct.loanDetails
      : {};

    this.setState(
      {
        paymentTypes: paymentTypes,
        loanConfig: loanConfig,
        loanAmount: loanDetails.loanAmount
          ? loanDetails.loanAmount
          : loanConfig.loanAmount.min || this.state.loanAmount,
        loanTerm: loanDetails.loanTerm
          ? loanDetails.loanTerm
          : this.state.loanTerm,
        loanRate: loanDetails.loanRate
          ? loanDetails.loanRate
          : this.state.loanRate,
        monthlyPayment: loanDetails.monthlyPayment
          ? loanDetails.monthlyPayment
          : this.state.monthlyPayment,
        paymentType: loanDetails.paymentType
          ? loanDetails.paymentType
          : this.state.paymentType,
      },
      () => {
        this.doCalculation();
      }
    );
  }

  setLoanAmountValue = (amount) => {
    console.log("setLoanAmountValue", amount);
    const { products, getActiveProduct } = this.props;
    const { loanConfig } = this.state;
    const activeProduct = getActiveProduct(products);
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    amount = parseFloat(amount).toFixed(2);
    if (amount > loanConfig.loanAmount.max) {
      amount = loanConfig.loanAmount.max;
    }
    this.setState({ loanAmount: amount }, function () {
      this.doCalculation();
    });
  };

  doCalculation() {
    const { loanAmount, loanConfig } = this.state;
    const rates = loanConfig.loanRate;
    let loanRate = 0.0;

    rates.forEach((raw, idx) => {
      const amount = parseInt(loanAmount);
      const minTerm = parseInt(raw.min);
      const maxTerm = parseInt(raw.max);
      if (amount >= minTerm && amount <= maxTerm) {
        loanRate = raw.rate;
      }
    });

    console.log("loanRate", loanRate);

    const rate = loanRate / 100;

    let monthlyPayment = (loanAmount * rate) / 12;
    monthlyPayment = monthlyPayment.toFixed(2);

    this.setState({
      loanRate,
      monthlyPayment,
    });
  }

  doUpdateLoanForm() {
    console.log("doUpdateLoanForm");
    const {
      loanAmount,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanScheduleDate,
      paymentType,
      downPayment,
      downPaymentPercent,
      amortization,
      estimatedMortgage,
    } = this.state;

    return {
      loanAmount: parseFloat(loanAmount),
      loanTerm,
      loanRate: parseFloat(loanRate),
      monthlyPayment: parseFloat(monthlyPayment),
      loanScheduleDate: loanScheduleDate,
      paymentType: paymentType,
      downPayment,
      downPaymentPercent,
      amortization,
      estimatedMortgage,
    };
  }

  commaFormatted(amount) {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  render() {
    const { loanAmount, loanRate, monthlyPayment, loanConfig } = this.state;
    const { steps, activeStepID } = this.props;

    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.LOAN_DETAILS.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-lg-7 ]">
              <div className="[ form-subtitle ]">
                {STRINGS.LOAN_DETAILS.SUBTITLE}
              </div>
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.LABEL_AMOUNT}
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ row ]">
                      <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ] [ pl-0 ]">
                        <div className="[ input-group ] [ loan-amount ]">
                          <div className="[ input-group-prepend ]">
                            <span className="[ input-group-text ]">$</span>
                          </div>
                          <NumberFormat
                            maxLength={AppConstants.LOANS.SALARY_LENGTH}
                            type="text"
                            allowLeadingZeros={false}
                            allowNegative={false}
                            thousandSeparator
                            className="[ form-control ] [ amount-active ]"
                            onChange={(e) =>
                              this.setLoanAmountValue(e.target.value)
                            }
                            value={loanAmount}
                          />
                        </div>
                      </div>

                      <div className="[ col-12 col-lg-8 order-lg-first ]">
                        <div className="[ row ]">
                          <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ]">
                            $ {this.commaFormatted(loanConfig.loanAmount.min)}
                          </div>
                          <div className="[ col-6 ]">
                            <RangeSlider
                              value={loanAmount}
                              min={loanConfig.loanAmount.min}
                              max={loanConfig.loanAmount.max}
                              onChange={(e) =>
                                this.setLoanAmountValue(e.target.value)
                              }
                              tooltipLabel={(currentValue) =>
                                `$ ${this.commaFormatted(currentValue)}`
                              }
                              tooltipPlacement="top"
                              step={loanConfig.loanAmount.steps}
                              className="[ w-100 ]"
                            />
                          </div>
                          <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ]">
                            $ {this.commaFormatted(loanConfig.loanAmount.max)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="[ mb-5 ]">
                <div className="[ form-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.LABEL_ESTIMATED_RATE}
                </div>
                <input
                  disabled={true}
                  value={parseFloat(loanRate).toFixed(2)}
                  type="text "
                  name="interestRate"
                  className="[ form-control ] [ rate ]"
                  placeholder=""
                />
              </div>

              <hr className="[ mb-3 ] [ d-lg-none ]" />
            </div>

            <div className="[ col-lg-5 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-subtitle ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.TABLE_TITLE}
                </div>
                <div className="[ details-section ] [ summary-tab ]">
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.LOC.REQUESTED_CREDIT_AMOUNT}
                    </div>
                    <div className="[ details-section__item-description ]">
                      $ {this.commaFormatted(loanAmount)}
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.LOC.LOAN_TERM}
                    </div>
                    <div className="[ details-section__item-description ]">
                      {STRINGS.LOAN_DETAILS.FORM.LOC.LOAN_TERM_REVOLVING}
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                      }
                    </div>
                    <div className="[ details-section__item-description ]">
                      {parseFloat(loanRate).toFixed(2)}%
                    </div>
                  </div>
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.LOAN_DETAILS.FORM.LOC.FINE_PRINT1}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ details-section ] [ summary-tab ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header ] [ mb-3 ]">
                  <div className="[ col-7 ]">
                    <h3 className="[ d-flex justify-content-start ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_MONTHLY_PAYMENT
                      }
                    </h3>
                  </div>
                  <div className="[ col-5 ]">
                    <h3 className="[ d-flex justify-content-end ]">
                      ${" "}
                      {this.commaFormatted(
                        parseFloat(monthlyPayment).toFixed(2)
                      )}
                    </h3>
                  </div>
                </div>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.LOAN_DETAILS.FORM.LOC.FINE_PRINT2}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

LoanLoc.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  loanProducts: PropTypes.oneOfType([PropTypes.array]),
  loanProductForm: PropTypes.oneOfType(PropTypes.object),
};

LoanLoc.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  loanProducts: [],
  loanProductForm: {},
};
export default LoanLoc;
