import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import Asset from "./Asset";
import { addLoanAsset, updateLoanAsset } from "./Actions/AssetActions";
import {
  getFinancialAnalysisFinancialList,
  deleteLoanFinancial,
} from "Components/LoanDetails/Actions/LoanDetailsAction";
import { showAssetPage } from "../../Utils/LogicUtilities";

class AssetContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: false,
      assets: [],
    };
  }

  componentDidMount() {
    console.log("container");
    const {
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
      handleBack,
      continuehandler,
      actionBack,
      config,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    if (showAssetPage(activeProduct, config)) {
      doGetFinancialAnalysisFinancialList(
        {
          applicantId: activeProduct.applicantId,
          finAnalysisId: activeProduct.finAnalysisId,
        },
        (res) => {
          this.getAssetList(res);
          // console.log(this.state.assets);
        }
      );
    } else {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.ASSET);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.ASSET);
      }
    }
  }

  filterAssets(data) {
    return data.filter((asset) => asset.type === AppConstants.ASSET.TYPE);
  }

  getAssetList = (data) => {
    const filteredAssets = this.filterAssets(data);
    this.setState({
      assets: filteredAssets,
      choice: filteredAssets.length > 0 ? true : false,
    });

    console.log(filteredAssets);
    // console.log(data);
  };

  addAsset(applicantId, finAnalysisId, newList) {
    const { doAddLoanAsset } = this.props;
    console.log(newList);
    if (newList) {
      newList.forEach((raw, idx) => {
        console.log(raw);
        doAddLoanAsset(
          applicantId,
          finAnalysisId,
          {
            SubType: raw.subType,
            Description: raw.description,
            Value: this.parseAmount(raw.value),
            Institution: raw.institution,
            IsPrimaryResidence: false,
          },
          (res) => {
            console.log(res);
          }
        );
      });
    }
  }

  updateAsset(applicantId, finAnalysisId, financialId, updatedAsset) {
    const { doUpdateLoanAsset } = this.props;
    console.log(updatedAsset);
    if (updatedAsset) {
      const assetValue =
        String(updatedAsset.value).indexOf("$") > -1
          ? this.parseAmount(updatedAsset.value)
          : updatedAsset.value;
      console.log(assetValue);
      doUpdateLoanAsset(
        applicantId,
        finAnalysisId,
        financialId,
        {
          SubType: updatedAsset.subType,
          Description: updatedAsset.description,
          Value: assetValue,
          Institution: updatedAsset.institution,
          IsPrimaryResidence: false,
        },
        (res) => {
          console.log(res);
        }
      );
    }
  }

  removeAllAsset(applicantId, finAnalysisId, assets) {
    const { doDeleteLoanAsset } = this.props;
    if (assets.length > 0) {
      console.log("assetList ");
      assets.forEach((asset) => {
        // console.log(asset);
        doDeleteLoanAsset(applicantId, finAnalysisId, asset.financialId);
      });
    }
  }

  getDiff(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return (
            other.value == current.value &&
            other.display == current.display &&
            other.description == current.description &&
            other.subType == current.subType &&
            other.institution == current.institution
          );
        }).length == 0
      );
    };
  }

  parseAmount(str) {
    // const matches = str.match(/(\d+)/);
    const replaces = str.replace(/\D/g, "");

    return replaces;
  }

  handleAssetButtonChange = (choice) => {
    console.log(choice);
    this.setState({ choice: choice });
  };

  handleContinue = (choice) => {
    this.processAsset(choice.assets, choice);
  };

  processAsset(assetList, paramChoice) {
    console.log(assetList);
    const { assets, choice } = this.state;
    const {
      products,
      getActiveProduct,
      doDeleteLoanAsset,
      continuehandler,
      doGetApplicationProperty,
    } = this.props;
    let updateCtr = 0;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (!activeProduct) {
      return;
    }
    // if (!activeProduct.finAnalysisId !== 0 || !activeProduct.finAnalysisId !== "" || !activeProduct.finAnalysisId) {
    //   return;
    // }
    const { applicantId } = activeProduct;
    const { finAnalysisId } = activeProduct;

    console.log(choice);
    if (choice) {
      console.log(assets);
      console.log(assetList);
      if (assets.length > 0) {
        // need to remove old asset if not in new list
        assets.forEach((old) => {
          const oldAssetExist = assetList.find(
            (asset) => asset.financialId === old.financialId
          );
          console.log("search asset", oldAssetExist);
          if (!oldAssetExist) {
            console.log("deleting asset", old.financialId);
            updateCtr += 1;
            doDeleteLoanAsset(
              applicantId,
              finAnalysisId,
              old.financialId,
              (res) => {
                console.log(res);
              }
            );
          }
        });
      }
      if (assetList) {
        const newList = assets.filter(this.getDiff(assetList));
        const diff = assetList.filter(this.getDiff(assets));
        console.log(newList);
        console.log(diff);
        // do update here
        const addedList = [];
        diff.forEach((data) => {
          console.log(data.financialId);
          if (data.financialId && data.financialId !== "undefined") {
            console.log("To Update");
            updateCtr += 1;
            this.updateAsset(
              applicantId,
              finAnalysisId,
              data.financialId,
              data,
              (res) => {
                console.log(res);
              }
            );
          } else {
            console.log("to add");
            addedList.push(data);
          }
        });

        if (addedList && addedList.length !== 0) {
          updateCtr += 1;
          this.addAsset(applicantId, finAnalysisId, addedList);
        }
      }
    } else if (assets.length > 0) {
      updateCtr += 1;
      this.removeAllAsset(applicantId, finAnalysisId, assets);
    }

    console.log("waiting before continuing", updateCtr);
    doGetApplicationProperty(
      {
        applicationId: activeProduct.applicationId,
        delayTime: updateCtr * 1000,
      },
      () => {
        continuehandler(
          { choice: paramChoice },
          AppConstants.APPLICATIONSTEP.ASSET
        );
      }
    );
  }

  render() {
    const { choice, assets } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      assetTypes,
    } = this.props;

    return (
      <>
        <Asset
          handleContinue={this.handleContinue}
          handleAssetButtonChange={this.handleAssetButtonChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          assets={assets}
          assetTypes={assetTypes}
        />
      </>
    );
  }
}

AssetContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

AssetContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  assetTypes: state.LoanDetailsReducer.assetTypes,
  actionBack: state.ApplicationReducer.actionBack,
  config: state.LoanDetailsReducer.config,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doAddLoanAsset: (applicantId, finAnalysisId, payLoad, callback) =>
      dispatch(addLoanAsset(applicantId, finAnalysisId, payLoad, callback)),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    doUpdateLoanAsset: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLoanAsset(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
    doDeleteLoanAsset: (applicantId, finAnalysisId, financialId, callback) =>
      dispatch(
        deleteLoanFinancial(applicantId, finAnalysisId, financialId, callback)
      ),
  };
};

const AssetWrapper = withApplicantHOC(AssetContainer);

export default connect(mapStateToProps, mapDispatchToProps)(AssetWrapper);
