import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import * as ROUTES from "Constants/Routes";

import { addFundMethod } from "Components/FundingAmount/Action/FundAction";

import FundMethod from "./FundMethod";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";
import AppConstants from "../../Constants/AppConstants";
import { getApplicationList } from "../../Redux/Actions/ApplicationAction";

class FundMethodContainer extends Component {
  componentDidMount() {
    // Only checking the products here as it will come from the process it will have products in the reducer
    // if the URL direct hit then reducer would have been cleared
    const {
      fundProduct,
      showNextProductPopUporRedirect,
      doGetApplicationProperty,
      doGetApplicationList,
    } = this.props;
    console.log("FundMethodContainer mount", fundProduct);
    if (
      (Object.keys(fundProduct).length === 0 &&
        fundProduct.constructor === Object) ||
      !fundProduct.applicationId
    ) {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    } else {
      doGetApplicationProperty(
        { applicationId: fundProduct.applicationId },
        (res) => {
          if (!res.uploadedAt) {
            doGetApplicationList(() => {
              showNextProductPopUporRedirect(null, res.inReview);
            });
          }
        }
      );
    }
  }

  handleContinue = () => {
    const { history } = this.props;
    history.push(ROUTES.FUNDING_AMOUNT);
  };

  handleSkip = () => {
    const { showNextProductPopUporRedirect } = this.props;
    showNextProductPopUporRedirect(null, false);
  };

  handleBack = () => {
    const { history } = this.props;
    history.push(ROUTES.VAULT_DASHBOARD);
  };

  render() {
    const { fundProduct, FundState, doAddFundMethod } = this.props;
    return (
      <div>
        <FundMethod
          handleContinue={this.handleContinue}
          productName={fundProduct.productName}
          handleBack={this.handleBack}
          fundMethod={FundState.method}
          doAddFundMethod={doAddFundMethod}
          handleSkip={this.handleSkip}
        />
      </div>
    );
  }
}

FundMethodContainer.propTypes = {
  doAddFundMethod: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  fundProduct: PropTypes.objectOf(PropTypes.any),
  FundState: PropTypes.objectOf(PropTypes.any),
  showNextProductPopUporRedirect: PropTypes.func,
  doGetApplicationProperty: PropTypes.func.isRequired,
};

FundMethodContainer.defaultProps = {
  history: {},
  fundProduct: {},
  FundState: {},
  showNextProductPopUporRedirect: () => {},
};

const mapStateToProps = (state) => ({
  fundProduct: state.VaultReducer.fundProduct,
  FundState: state.FundReducer,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doAddFundMethod: addFundMethod,
      doGetApplicationProperty: getApplicationProperty,
      doGetApplicationList: getApplicationList,
    },
    dispatch
  );
};

const FundMethodWrapper = withApplicantHOC(FundMethodContainer);

export default connect(mapStateToProps, mapDispatchToProps)(FundMethodWrapper);
