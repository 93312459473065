import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import { isScanningAllowed } from "Utils/CommonUtilities";
import Webcam from "react-webcam";
import { confirmAlert } from "react-confirm-alert";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import "../Documents/Documents.scss";
import "react-confirm-alert/src/react-confirm-alert.css";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeIds: [],
      webcamEnabled: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.DOCUMENT_UPLOAD,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.documentList !== prevProps.documentList) {
      console.log("document list updated");
    }
  }

  setFormData = () => {};

  fileDrop = (e) => {
    e.preventDefault();
    const { addDocumentData } = this.props;
    const { files } = e.dataTransfer;
    let uploadFiles = e.dataTransfer.files;
    let currFile = uploadFiles[0];
    const reader = new FileReader();

    reader.readAsBinaryString(currFile);

    reader.onload = (e) => {
      console.log(e);
      console.log(reader);
      console.log(files);

      // const formData = new FormData()
      // formData.append('file', uploadFiles[0])
      // formData.append('file', uploadFiles[0])
      const document = {
        documentName: uploadFiles[0].name,
        documentSource: uploadFiles[0],
        documentId: 0,
      };
      const action = {
        type: "add",
        document: document,
      };
      // this.updateDocumentList(action);
      addDocumentData(document);
      // this.setState({ file: e.target.result });
      // Code below will call API to pass the file to backend
    };
  };
  handleClick = (e) => {
    this.hiddenFileInput.onClick(e);
  };

  handleChange = (e) => {
    const { addDocumentData } = this.props;
    let uploadFiles = e.target.files;
    let currFile = uploadFiles[0];
    let reader = new FileReader();
    // reader.readAsDataURL(uploadFiles[0]);
    reader.readAsBinaryString(currFile);
    // reader.readAsDataURL(currFile);

    reader.onload = (e) => {
      console.log(e);
      console.log(reader);

      // const formData = new FormData()
      // formData.append('file', uploadFiles[0])
      // formData.append('file', uploadFiles[0])
      const document = {
        documentName: uploadFiles[0].name,
        documentSource: uploadFiles[0],
        documentId: 0,
      };
      const action = {
        type: "add",
        document: document,
      };
      // this.updateDocumentList(action);
      addDocumentData(document);
      // this.setState({ file: e.target.result });
      // Code below will call API to pass the file to backend
    };
    console.warn("data file", uploadFiles);
  };

  handleDeleteFile = (idx) => {
    console.log("handleDeleteFile", idx);
    const { deleteDocument } = this.props;
    deleteDocument(idx);
  };

  handleContinue = (onClose) => {
    const { handleContinue } = this.props;
    onClose();
    handleContinue();
  };

  uploadByCamera = () => <Webcam />;

  handleConfirmUpload = () => {
    const isMobile = isScanningAllowed();
    console.log(isMobile);
    const { documentList, handleContinue } = this.props;
    const { removeIds } = this.state;
    console.log(documentList);
    if (documentList.length === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div idname="[ react-confirm-alert ]">
              <div className="[ react-confirm-alert-overlay ]">
                <div className="[ react-confirm-alert ]">
                  <h1 className="[ react-confirm-alert-header ]">WARNING!</h1>
                  <p className="[ react-confirm-alert-body ]">
                    You have not attached any files. Hit cancel to upload files.
                    Hit 'Save & Submit' to skip the file uploads and submit your
                    application as is.
                  </p>
                  <div className="[ react-confirm-alert-button-group ]">
                    <button
                      className="[ confirm-upload-savesubmit-btn ]"
                      onClick={() => this.handleContinue(onClose)}
                    >
                      Save & Submit
                    </button>
                  </div>
                  <div className="[ react-confirm-alert-button-group ]">
                    <button
                      className="[ confirm-upload-cancel-btn ]"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      });
    } else {
      handleContinue();
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.DOCUMENT_UPLOAD);
  };

  render() {
    const isMobile = isScanningAllowed();
    const videoConstraints = {
      width: { min: 480 },
      height: { min: 720 },
      aspectRatio: 0.6666666667,
      facingMode: "user",
    };
    const { choice } = this.state;
    const { steps, activeStepID, documentList, handleSkip } = this.props;
    console.log(steps, activeStepID, documentList);
    const fileListComponent = documentList.map((file, index) => (
      <div key={index} className="[ row ] [ details-section__item ]">
        <div className="[ col-10 ]">
          <div className="[ details-section__item-label ]">
            {file.documentName}
          </div>
        </div>
        <div className="[ col-2 ]">
          <div className="[ details-section__item-description ]">
            <span
              role="button"
              className="[ fas fa-trash ]"
              onClick={() => this.handleDeleteFile(index)}
            >
              <span className="sr-only">delete file</span>
            </span>
          </div>
        </div>
      </div>
    ));
    const actionComponent = (
      <div className="[ uploader-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.DOCUMENT_UPLOAD.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row mb-3 ]">
            <div className="[ col-lg-7 mb-3 mb-lg-0 ]">
              <div className="[ form-subtitle ]">
                {STRINGS.DOCUMENT_UPLOAD.SUBTITLE}
              </div>

              <div className="[ row mb-3 ]">
                <div className="[ col-12 ]">
                  <div className="[ alert alert-info ]" role="alert">
                    <ul>
                      <li>{STRINGS.DOCUMENT_UPLOAD.UPLOAD_MESSAGE1}</li>
                      <li>{STRINGS.DOCUMENT_UPLOAD.UPLOAD_MESSAGE2}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="[ row ]">
                <div className="[ col-12 ]">
                  <div className="[ upload-area-container ]">
                    {isMobile ? (
                      <div
                        className="[ col upload-area ]"
                        onClick={this.uploadByCamera}
                      >
                        <input
                          className="[ click-upload ]"
                          type="file"
                          hidden
                          ref={(ref) => (this.upload = ref)}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <div onClick={() => this.upload.click()}>
                          <span className="[ fas fa-camera ]"></span>
                          <span className="[ camera-text ]">
                            {STRINGS.DOCUMENT_UPLOAD.TAP_FOR_CAMERA}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="[ upload-area ]"
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        onDrop={this.fileDrop}
                      >
                        <input
                          className="[ click-upload ]"
                          type="file"
                          ref={(ref) => (this.upload = ref)}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <span className="[ fas fa-folder-open ]"></span>
                        <span className="[ browse-files-text ]">
                          {STRINGS.DOCUMENT_UPLOAD.CLICK_TO_BROWSE}
                        </span>
                        <span
                          className="[ click-link ]"
                          onClick={() => this.upload.click()}
                        >
                          {STRINGS.DOCUMENT_UPLOAD.CLICK_TO_BROWSE_LINK}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="[ col-lg-5 ]">
              <div className="[ details-section ] [ summary-tab ]">
                <div className="[ form-subtitle ]">
                  {STRINGS.DOCUMENT_UPLOAD.FILES_FOR_UPLOAD}
                </div>
                {fileListComponent}
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.DOCUMENT_UPLOAD.FILES_FOR_UPLOAD_FINE_PRINT1}
                      </small>
                    </div>
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.DOCUMENT_UPLOAD.FILES_FOR_UPLOAD_FINE_PRINT2}
                      </small>
                    </div>
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.DOCUMENT_UPLOAD.FILES_FOR_UPLOAD_FINE_PRINT3}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleConfirmUpload}
              >
                {STRINGS.DOCUMENT_UPLOAD.UPLOAD_BUTTON}
              </button>
              <button
                type="button"
                className="[ btn skip-button ] [ w-100 ]"
                onClick={handleSkip}
              >
                {STRINGS.DOCUMENT_UPLOAD.SKIP_BUTTON}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <div>
          {this.webcamEnabled ? (
            <Webcam
              audio={false}
              ref={this.webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          ) : (
            this.uploadByCamera
          )}
        </div>
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

Documents.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleSkip: PropTypes.func,
  // handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  documentList: PropTypes.arrayOf(PropTypes.object),
};

Documents.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleSkip: () => {},
  steps: [],
  activeStepID: -1,
};

export default Documents;
