import STRINGS from "./Strings";

export const HOME = "/";
export const SIGN_IN = "/signin";
export const FORGOT_PASSWORD = "/signin/forgot-password";
export const REGISTER = "/register";
export const VERIFICATION_CODE = "/register/verification-code";
export const VAULT_PASSWORD = "/register/verification-code/vault-password";
export const CHANGE_PASSWORD = "/change-password";
export const EXISTING_MEMBER = "/existing-member";
export const DEBIT_CARD = "/debit-card";
export const RECOVER_PASSWORD = "/recoverpassword";
export const RECOVER_PASSWORD_BY_EMAIL = `${RECOVER_PASSWORD}/:id`;
export const MEMBER_CREATION = "/terms-and-conditions";
export const ABOUT_YOU = "/about-you";
export const SIGNIN_FORGOT_VERIFICATION =
  "/signin/forgot-password/verification-code";
export const SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD =
  "/signin/forgot-password/verification-code/vault-password";
export const EMPLOYMENT_AND_INCOME = "/employment-and-income";
export const CONFIRM_ACCOUNT_CREATE = "/confirm-account-create";
export const FUNDING_AMOUNT = "/fund-amount";
export const FUND_ACCOUNT = "/fund-account";
export const GETTING_STARTED = "/getting-started";
export const COMPLIANCE = "/compliance";
export const THIRD_PARTY = "/thirdParty";
export const ACCOUNT_INFORMATION = "/account-information";
export const FUND_METHOD = "/fund-method";
export const MARKETPLACE = "/marketplace";
export const VAULT_DASHBOARD = "/dashboard";
export const BENEFICIARIES = "/beneficiaries";
export const OVERDRAFT_PROTECTION = "/overdraft-protection";
// joint-applicant
export const JOINT_APPLICANT = "/joint-applicant";
export const JOINT_APPLICANT_STATUS = "/joint-applicant-status";
export const ONLINE_BANKING = "/online-banking";
export const SPOUSAL_INFO = "/spousal-info";
export const SIGNATURE = "/signature";
export const INVITE_APPLY = "/inviteapply/:id";
export const MEMBERSHIP = "/membership";
export const LOAN_DETAILS = "/loan-details";
export const LOAN_PURPOSE = "/loan-purpose";
export const PROPERTY_DETAILS = "/property-details";
export const SECURITY_VEHICLE = "/security-vehicle";
export const INCOME = "/income";
export const EXPENSE = "/expense";
export const ASSET = "/asset";
export const LIABILITIES = "/liabilities";
export const DOCUMENT_UPLOAD = "/documents";
export const FEES = "/fees";
export const COST_OF_BORROWING = "/cost-of-borrowing";
export const LOAN_SUMMARY = "/loan-summary";
export const LOAN_ADJUDICATION_RESULTS = "/loan-result";
export const BEFORE_WE_START = "/before-we-start";
export const DISBURSEMENT = "/disbursement";
export const REPAYMENT = "/repayment";
export const APPLY_PRODUCT = "/apply";
export const BUSINESS_EXIST = "/business-exist";
export const BUSINESS_CLIENT = "/business-client";
export const BUSINESS_KYC = "/business-kyc";
export const BUSINESS_TYPE = "/business-type";
export const BUSINESS_ELIGIBILITY = "/business-eligibility";
export const BUSINESS_START = "/business-start";
export const BUSINESS_INFO_ADDRESS = "/business-info-address";
export const BUSINESS_INFO_MAIL = "/business-info-mail";
export const BUSINESS_INFO = "/business-info";
export const BUSINESS_LOOKUP = "/business-lookup";
export const BUSINESS_CONTACT = "/business-contact";
export const BUSINESS_DETAILS = "/business-details";
export const BUSINESS_OPERATIONS = "/business-operations";
export const BUSINESS_TAX = "/business-tax";
export const BUSINESS_COMPLIANCE = "/business-compliance";
export const BUSINESS_OVERDRAFT = "/business-overdraft";
export const BUSINESS_CHEQUE = "/business-cheque";
export const BUSINESS_DEBIT = "/business-debit";
export const BUSINESS_ONLINE = "/business-online";
export const BUSINESS_EMPLOYMENT = "/business-employment";
export const BUSINESS_REVIEW = "/business-review";
export const BUSINESS_INDIVIDUAL = "/business-individual";
export const BUSINESS_MEMBER = "/business-member";
export const BUSINESS_MEMBER_REVIEW = "/business-member-review";
export const BUSINESS_ABOUT_YOU = "/business-about-you";
export const BUSINESS_CONFIRM = "/business-confirm";
export const BUSINESS_UPLOAD = "/business-upload";
export const BUSINESS_DOC_REVIEW = "/business-document-review";
export const TAX_INFO = "/tax-info";
export const GIC_INFO = "/gic-info";
export const QUESTIONNAIRE = "/help-me-choose";

export const BREADCRUMBS = {
  [HOME]: STRINGS.MARKETPLACE.TITILE,
  [MARKETPLACE]: STRINGS.MARKETPLACE.TITILE,
  [VAULT_DASHBOARD]: STRINGS.HOME_BREADCRUMB,
  [SIGN_IN]: STRINGS.SIGNIN.SIGN_IN_BREADCRUMB,
  [FORGOT_PASSWORD]: STRINGS.FORGOT_PASSWORD_BREADCRUMB,
  [REGISTER]: STRINGS.REGISTRATION.REGISTER_BREADCRUMB,
  [VERIFICATION_CODE]: STRINGS.VERIFICATIONCODE.VERIFICATION_CODE_BREADCRUMB,
  [VAULT_PASSWORD]: STRINGS.VAULT.VAULT_PASSWORD_BREADCRUMB,
  [EXISTING_MEMBER]: STRINGS.EXISTING_MEMBER_BREADCRUMB,
  [DEBIT_CARD]: STRINGS.DEBIT_CARD_BREADCRUMB,
  [SIGNIN_FORGOT_VERIFICATION]:
    STRINGS.VERIFICATIONCODE.VERIFICATION_CODE_BREADCRUMB,
  [SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD]:
    STRINGS.VAULT.VAULT_PASSWORD_BREADCRUMB,
  [RECOVER_PASSWORD]: STRINGS.RECOVER_PASSWORD_BREADCRUMB,
  [MEMBER_CREATION]: STRINGS.MEMBER_CREATION_BREADCRUMB,
  [ABOUT_YOU]: STRINGS.MEMBER_CREATION_BREADCRUMB,
  [SIGNATURE]: STRINGS.SIGNATURE_BREADCRUMB,
  [RECOVER_PASSWORD_BY_EMAIL]: STRINGS.RECOVER_PASSWORD_BREADCRUMB,
  [EMPLOYMENT_AND_INCOME]: STRINGS.EMPLOYMENT_AND_INCOME_BREDCRUM,
  [GETTING_STARTED]: STRINGS.GETTING_STARTED_BREADCRUMB,
  [CONFIRM_ACCOUNT_CREATE]: STRINGS.CONFIRM_ACCOUNT_CREATE_BREADCRUMB,
  [FUNDING_AMOUNT]: STRINGS.FUND_ACCOUNT_BREADCRUMB,
  [COMPLIANCE]: STRINGS.COMPLIANCE_BREADCRUMB,
  [THIRD_PARTY]: STRINGS.THIRD_PARTY_BREADCRUMB,
  [ACCOUNT_INFORMATION]: STRINGS.ACCOUNT_INFORMATION_BREADCRUMB,
  [FUND_METHOD]: STRINGS.FUND_ACCOUNT_BREADCRUMB,
  [BENEFICIARIES]: STRINGS.BENEFICIARIES_BREADCRUMB,
  [OVERDRAFT_PROTECTION]: STRINGS.OVERDRAFT_PROTECTION_BREADCRUMB,
  [JOINT_APPLICANT]: STRINGS.JOINT_APPLICANT.JOINT_APPLICANT_BREADCRUMB,
  [JOINT_APPLICANT_STATUS]:
    STRINGS.JOINT_APPLICANT_STATUS.JOINT_APPLICANT_STATUS_BREADCRUMB,
  [ONLINE_BANKING]: STRINGS.ONLINE_BANKING.ONLINE_BANKING_BREADCRUMB,
  [SPOUSAL_INFO]: STRINGS.SPOUSAL_INFO.SCREENTITLE,
  [LOAN_DETAILS]: STRINGS.LOAN_DETAILS.BREADCRUMB,
  [LOAN_PURPOSE]: STRINGS.LOAN_PURPOSE.BREADCRUMB,
  [PROPERTY_DETAILS]: STRINGS.PROPERTY_DETAILS.BREADCRUMB,
  [SECURITY_VEHICLE]: STRINGS.SECURITY_VEHICLE.BREADCRUMB,
  [INCOME]: STRINGS.INCOME.BREADCRUMB,
  [EXPENSE]: STRINGS.EXPENSE.BREADCRUMB,
  [ASSET]: STRINGS.ASSET.BREADCRUMB,
  [LIABILITIES]: STRINGS.LIABILITIES.BREADCRUMB,
  [DOCUMENT_UPLOAD]: STRINGS.DOCUMENT_UPLOAD.BREADCRUMB,
  [FEES]: STRINGS.FEES.BREADCRUMB,
  [COST_OF_BORROWING]: STRINGS.COST_OF_BORROWING.BREADCRUMB,
  [LOAN_SUMMARY]: STRINGS.LOAN_SUMMARY.BREADCRUMB,
  [LOAN_ADJUDICATION_RESULTS]: STRINGS.LOAN_ADJUDICATION_RESULTS.BREADCRUMB,
  [BEFORE_WE_START]: STRINGS.BEFORE_WE_START.BREADCRUMB,
  [DISBURSEMENT]: STRINGS.DISBURSEMENT.BREADCRUMB,
  [REPAYMENT]: STRINGS.REPAYMENT.BREADCRUMB,
  [INVITE_APPLY]: STRINGS.REGISTRATION.REGISTER_BREADCRUMB,
  [APPLY_PRODUCT]: STRINGS.APPLY_PRODUCT.APPLY_PRODUCT_BREADCRUMB,
  [CHANGE_PASSWORD]: STRINGS.VAULT.VAULT_PASSWORD_BREADCRUMB,
  [MEMBERSHIP]: STRINGS.MEMBERSHIP.BREADCRUMB,
  [BUSINESS_EXIST]: STRINGS.BUSINESS_EXIST.BREADCRUMB,
  [BUSINESS_CLIENT]: STRINGS.BUSINESS_CLIENT.BREADCRUMB,
  [BUSINESS_KYC]: STRINGS.BUSINESS_KYC.BREADCRUMB,
  [BUSINESS_TYPE]: STRINGS.BUSINESS_TYPE.BREADCRUMB,
  [BUSINESS_ELIGIBILITY]: STRINGS.BUSINESS_ELIGIBILITY.BREADCRUMB,
  [BUSINESS_START]: STRINGS.BUSINESS_START.BREADCRUMB,
  [BUSINESS_INFO_ADDRESS]: STRINGS.BUSINESS_INFO_ADDRESS.BREADCRUMB,
  [BUSINESS_INFO_MAIL]: STRINGS.BUSINESS_INFO_MAIL.BREADCRUMB,
  [BUSINESS_INFO]: STRINGS.BUSINESS_INFO.BREADCRUMB,
  [BUSINESS_LOOKUP]: STRINGS.BUSINESS_LOOKUP.BREADCRUMB,
  [BUSINESS_CONTACT]: STRINGS.BUSINESS_CONTACT.BREADCRUMB,
  [BUSINESS_DETAILS]: STRINGS.BUSINESS_DETAILS.BREADCRUMB,
  [BUSINESS_OPERATIONS]: STRINGS.BUSINESS_OPERATIONS.BREADCRUMB,
  [BUSINESS_TAX]: STRINGS.BUSINESS_TAX.BREADCRUMB,
  [BUSINESS_COMPLIANCE]: STRINGS.BUSINESS_COMPLIANCE.BREADCRUMB,
  [BUSINESS_OVERDRAFT]: STRINGS.BUSINESS_OVERDRAFT.BREADCRUMB,
  [BUSINESS_CHEQUE]: STRINGS.BUSINESS_CHEQUE.BREADCRUMB,
  [BUSINESS_DEBIT]: STRINGS.BUSINESS_DEBIT.BREADCRUMB,
  [BUSINESS_ONLINE]: STRINGS.BUSINESS_ONLINE.BREADCRUMB,
  [BUSINESS_EMPLOYMENT]: STRINGS.BUSINESS_EMPLOYMENT.BREADCRUMB,
  [BUSINESS_REVIEW]: STRINGS.BUSINESS_REVIEW.BREADCRUMB,
  [BUSINESS_INDIVIDUAL]: STRINGS.BUSINESS_INDIVIDUAL.BREADCRUMB,
  [BUSINESS_MEMBER]: STRINGS.BUSINESS_MEMBER.BREADCRUMB,
  [BUSINESS_MEMBER_REVIEW]: STRINGS.BUSINESS_MEMBER_REVIEW.BREADCRUMB,
  [BUSINESS_ABOUT_YOU]: STRINGS.BUSINESS_ABOUT_YOU.BREADCRUMB,
  [BUSINESS_CONFIRM]: STRINGS.BUSINESS_CONFIRM.BREADCRUMB,
  [BUSINESS_UPLOAD]: STRINGS.BUSINESS_UPLOAD.BREADCRUMB,
  [BUSINESS_DOC_REVIEW]: STRINGS.BUSINESS_DOC_REVIEW.BREADCRUMB,
  [TAX_INFO]: STRINGS.TAX_INFO.BREADCRUMB,
  [GIC_INFO]: STRINGS.GIC_INFO.BREADCRUMB,
  [QUESTIONNAIRE]: STRINGS.QUESTIONNAIRE_BREADCRUMB,
};

export const PUBLIC_ROUTES = [
  HOME,
  SIGN_IN,
  FORGOT_PASSWORD,
  REGISTER,
  VERIFICATION_CODE,
  VAULT_PASSWORD,
  SIGNIN_FORGOT_VERIFICATION,
  SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD,
  MARKETPLACE,
  INVITE_APPLY,
  APPLY_PRODUCT,
  QUESTIONNAIRE,
];

export const PRIVATE_ROUTES = [
  VAULT_DASHBOARD,
  EXISTING_MEMBER,
  MEMBER_CREATION,
  GETTING_STARTED,
  ABOUT_YOU,
  EMPLOYMENT_AND_INCOME,
  COMPLIANCE,
  ACCOUNT_INFORMATION,
  OVERDRAFT_PROTECTION,
  SPOUSAL_INFO,
  DEBIT_CARD,
  FUNDING_AMOUNT,
  FUND_METHOD,
  CONFIRM_ACCOUNT_CREATE,
  LOAN_ADJUDICATION_RESULTS,
  CHANGE_PASSWORD,
  BUSINESS_EXIST,
  BUSINESS_CLIENT,
  BUSINESS_TYPE,
  BUSINESS_ELIGIBILITY,
  BUSINESS_START,
  BUSINESS_INFO_ADDRESS,
  BUSINESS_INFO_MAIL,
  BUSINESS_INFO,
  BUSINESS_LOOKUP,
  BUSINESS_CONTACT,
  BUSINESS_DETAILS,
  BUSINESS_OPERATIONS,
  BUSINESS_TAX,
  BUSINESS_COMPLIANCE,
  BUSINESS_OVERDRAFT,
  BUSINESS_CHEQUE,
  BUSINESS_DEBIT,
  BUSINESS_ONLINE,
  BUSINESS_EMPLOYMENT,
  BUSINESS_REVIEW,
  BUSINESS_INDIVIDUAL,
  BUSINESS_MEMBER,
  BUSINESS_MEMBER_REVIEW,
  BUSINESS_ABOUT_YOU,
  BUSINESS_CONFIRM,
  BUSINESS_UPLOAD,
  BUSINESS_DOC_REVIEW,
  TAX_INFO,
  GIC_INFO,
  THIRD_PARTY,
];
