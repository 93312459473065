import BaseApiRequest from "./BaseApiRequest";
import { RECOVERPASSWORD_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

class ForgotPasswordRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  constructor(userDetail) {
    super();
    this.body = userDetail;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return RECOVERPASSWORD_URL;
  };

  getBody = () => {
    return this.body;
  };
}

export default ForgotPasswordRequest;
