import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

import { GET_PROSPECT } from "../Actions/actionTypes";

import GetProspectRequest from "../../Communication/ApiClasses/GetProspectRequest";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(`${GET_PROSPECT}_SAGA`, action.waitMessage));
  const getProspectRequest = new GetProspectRequest(action.request);
  try {
    const result = yield call(getProspectRequest.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error);
  } finally {
    yield put(doLoadingFinish(`${GET_PROSPECT}_SAGA`));
  }
}

// watcher
export default function* handleSagaWatcher() {
  yield takeLatest(GET_PROSPECT, handleSaga);
}
