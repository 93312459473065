export const METHOD = {
  HTTP: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
};

export const REFRESH_TOKEN_TIME = 1500000;
//TODO should be less (1 minute?)
export const REFRESH_TRIGGER_SECONDS = 240;

export const TOKEN_KEY = "token";
export const S_KYC = "kyc";
export const S_MSISDN = "msisdn";
export const S_EMAIL = "email";
export const S_SSO = "sso";
export const S_INSTNT_TXN_ID = "instntTxnId";
export const S_INSTNT_FINGER_PRINT = "fingerprint";
export const S_PERSONAL = "personal";
export const S_BANKING_TYPE = "bankingType";
export const S_BANKING_TYPE_SWITCH = "bankingTypeSwitch";
export const TIMEOUT = "timeout";
export const EXPIRES_AT = "expiresAt";
export const REFRESH_EXPIRES_AT = "refreshExpiresAt";
export const PERSIST_KEY = "persist:root";
export const PRODUCTION_ENV = "production";
export const REFRESH_TIMER_ID = "timerID";
export const S_APPLICANT_ID = "applicantID";
export const EXISTING_OR_SAME_PRODUCT_MESSAGE =
  "getExistingOrSameProductMessage";
export const IS_LOGOUT = "isLogout";

export const TERMDEPOSITEPRODUCT = "termdepositproduct";
export const REGISTERCONTRACTPRODUCT = "RegisterContractProduct";
export const VAULT_PASSWORD = "vault-password";
export const VERIFICATION_CODE = "/verification-code";
export const IS_SET_PASSWORD_VISIT = "isSetPasswordVisit";
export const SORT_ON_PRIORITY = "priority";
export const LENDING_PRODUCT = "lendingproduct";
export const DEBIT_CARD_ONLINE_BANKING = "debitCard";
export const QUESTIONNAIRE_PRODUCT_ID = "questionnaireProductId";

export const HTTP_STATUS = {
  OK: 200,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  UNAUTHORISED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const ERROR_CODE = {
  PERMISSION_DENIED: "100000",
  FAIL_CREATE_TOKEN: "100001",
  INVALID_PASSWORD: "100002",
  ACCOUNT_EXIST: "100003",
  CREDENTIAL_CONFLICT: "100004",
  ACCOUNT_NOT_EXIST: "100005",
  ACCOUNT_CREATE_FAIL: "100006",
  ACCOUNT_MODIFY_FAIL: "100007",
  ACCOUNT_DEL_FAIL: "100008",
  PASSWORD_CRITERIA_FAIL: "100009",
  ACCOUNT_SET_PASSWORD_FAIL: "100010",
  REFRESH_EXPIRED: "100011",
  VAULT_JSON_EXCEED_LIMIT: "100012",
  VAULT_JSON_INVALID: "100013",
  VAULT_EXCEED_LIMIT: "100014",
  USER_INACTIVE: "100015",
  USER_LOGOUT: "100016",
  ACCOUNT_LOCKED: "100017",
  USER_DUPLICATE_LOGIN: "100018",
  TOO_EARLY_REFRESH: "100019",
  OTP_FAIL_TO_SAVE: "100101",
  OTP_EXPIRES: "100102",
  OTP_INVALID: "100103",
  OTP_WRONG_PURPOSE: "100104",
  OTP_EXCEED_LIMIT: "100105",
  VALIDATION_ERROR: "44003",
  ACCESS_DENIED: "44404",
  EXISTING_MEMBER: "103004",
  FAIL_ASSERTION: "102001",
  DATA_NOT_AVAILABLE: "102006",
  NO_ACTIVITY: "100020",
  DOCS_STILL_CREATING: "103023",
  PARTIAL_MATCH: "103018",
  APPLICATION_IN_REVIEW: "103025",
  MOBILE_NOT_EXIST: "100026",
};
