import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessEmail: "",
      businessPhoneNumber: "",
      businessPhoneExtension: "",

      errors: {
        businessEmailErrorList: [],
        businessPhoneNumberErrorList: [],
      },

      formIsValid: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_CONTACT,
      (response) => {}
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { applicantData } = this.props;

    if (applicantData && applicantData !== prevProps.applicantData) {
      this.setFormData(applicantData);
    }
  }

  setFormData = (data) => {
    this.setState({
      businessEmail: data.member.workEmail,
      businessPhoneNumber:
        data.address.currentAddressPhoneArea + data.address.currentAddressPhone,
      businessPhoneExtension: data.address.extension,
    });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const {
      businessEmail,
      businessPhoneNumber,
      businessPhoneExtension,
    } = this.state;

    if (this.verifyData()) {
      handleContinue({
        contact: {},
        member: {
          workEmail: businessEmail,
        },
        address: {
          currentAddressPhoneArea: businessPhoneNumber
            .replace(AppConstants.REGISTRATION.CONTACT_NO_PATTERN, "")
            .substring(0, 3),
          currentAddressPhone: businessPhoneNumber
            .replace(AppConstants.REGISTRATION.CONTACT_NO_PATTERN, "")
            .substring(3),
          extension: businessPhoneExtension,
        },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_CONTACT);
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target.name, event?.target.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    const { businessEmail, businessPhoneNumber, errors } = this.state;
    const businessEmailRes = checkErrors(
      businessEmail,
      VALIDATIONS.BUSINESS_CONTACT.EMAIL
    );

    console.log("businessPhoneNumber", businessPhoneNumber);
    const mobileRes = checkErrors(
      businessPhoneNumber,
      VALIDATIONS.BUSINESS_CONTACT.BUSINESS_PHONE_NO
    );

    this.setState({
      errors: {
        ...errors,
        businessEmailErrorList: businessEmailRes,
        businessPhoneNumberErrorList: mobileRes,
      },
    });

    if (businessEmailRes.length > 0 || mobileRes.length > 0) {
      formIsValid = false;
    }
    return formIsValid;
  };

  render() {
    const { steps, activeStepID } = this.props;
    const {
      businessPhoneNumber,
      businessEmail,
      businessPhoneExtension,
      errors,
    } = this.state;

    const actionComponent = (
      <div className="[ business-contact-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_CONTACT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_CONTACT.CONTACT_FORM_TITLE}
          </div>

          <div className="[ row ] [ mb-3 ]">
            <div className="[ col-12 ]">
              <div className="[ alert alert-info ]">
                <div className="[ alert-heading ]">
                  {STRINGS.BUSINESS_CONTACT.CORRESPONDENCE_TITLE}
                </div>
                <p>{STRINGS.BUSINESS_CONTACT.CORRESPONDENCE_DESCRIPTION}</p>
                <p>{STRINGS.BUSINESS_CONTACT.CORRESPONDENCE_DESCRIPTION1}</p>
              </div>
            </div>
          </div>

          <div className="[ row ] [ mb-3 ]">
            <div className="[ col-md-8 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_CONTACT.FORM.BUSINESS_PHONE_NO}
              </div>
              <NumberFormat
                format={AppConstants.REGISTRATION.NUMBERFORMAT}
                className={
                  errors?.businessPhoneNumberErrorList &&
                  errors?.businessPhoneNumberErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                type="tel"
                name="businessPhoneNumber"
                value={businessPhoneNumber}
                placeholder={`${STRINGS.BUSINESS_CONTACT.FORM.BUSINESS_PHONE_NO_PLACEHOLDER}`}
                onChange={(event) => this.handleChange(event)}
              />
              <div className="[ error-msg ]">
                {errors?.businessPhoneNumberErrorList[0]}
              </div>
            </div>
            <div className="[ col-md-4 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_CONTACT.FORM.EXTENSION}
              </div>
              <input
                type="text"
                id="businessPhoneExtension"
                name="businessPhoneExtension"
                className={"[ form-control ]"}
                placeholder={
                  STRINGS.BUSINESS_CONTACT.FORM.EXTENSION_PLACEHOLDER
                }
                value={businessPhoneExtension}
                onChange={this.handleChange}
              />
              <div className="[ error-msg ]"></div>
            </div>
            <div className="[ col-12 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_CONTACT.FORM.EMAIL}
              </div>
              <input
                type="businessEmail"
                name="businessEmail"
                id="businessEmail"
                className={
                  errors?.businessEmailErrorList &&
                  errors?.businessEmailErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder={STRINGS.BUSINESS_CONTACT.FORM.EMAIL_PLACEHOLDER}
                value={businessEmail}
                onChange={this.handleChange}
              />
              <div className="[ error-msg ]">
                {errors?.businessEmailErrorList[0]}
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessContact.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessContact.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessContact;
