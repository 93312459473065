import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "../Common/AuthBody/AuthBody";

class ExistingMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (choice) => {
    this.setState({ choice });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { choice } = this.state;
    this.clearChoice();
    handleContinue(choice);
  };

  clearChoice = () => {
    this.setState({ choice: "" });
  };

  render() {
    const { choice } = this.state;
    const actionComponent = (
      <div className="[ existing-client-container ]">
        <div className="[ form-title-existing ]">
          {STRINGS.EXISTING_MEMBER.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EXISTING_MEMBER.CHOICE_MESSAGE}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    choice !== "" && !choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(false, event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                disabled={choice === ""}
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    const infoComponent = (
      <div className="[ existing-client-secondary-container ]">
        <div className="[ info-title ]">
          <span className="lock-icon">
            {STRINGS.EXISTING_MEMBER.INFOBLOCK.TITLE}
          </span>
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          <span
            dangerouslySetInnerHTML={{
              __html: STRINGS.EXISTING_MEMBER.INFOBLOCK.DESCRIPTION,
            }}
          />
        </div>
      </div>
    );
    return (
      <AuthBody
        actionComponent={actionComponent}
        infoComponent={infoComponent}
        handleBack={this.handleBack}
        renderInfoComponent
        showNavigationBar={false}
      />
    );
  }
}

ExistingMember.propTypes = {
  handleContinue: PropTypes.func,
};

ExistingMember.defaultProps = {
  handleContinue: () => {},
};

export default ExistingMember;
