import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetFinInfoApiRequest from "Communication/ApiClasses/GetFinInfoApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import { S_BANKING_TYPE } from "../../../Communication/Constants";
import AppConstants from "../../../Constants/AppConstants";

// worker
function* handleProduct(action) {
  yield put(doLoadingStart(STRINGS.MARKETPLACE.API_KEYS.GET_FI_INFO));
  const finInfoApiRequest = new GetFinInfoApiRequest();
  try {
    const result = yield call(finInfoApiRequest.getData);
    yield call(action.callback, result);
    yield put({
      type: actionTypes.STORE_FI_INFO,
      data: result.data,
    });
    const bankingType = sessionStorage.getItem(S_BANKING_TYPE);
    if (!bankingType) {
      const newBankingType =
        result.data.defaultBankingType || AppConstants.BANKING_TYPE.PERSONAL;
      console.log("default banking type", newBankingType);
      sessionStorage.setItem(S_BANKING_TYPE, newBankingType);
      yield put({
        type: actionTypes.FILTER_BANKING_TYPE,
        bankingType: newBankingType,
      });
    }
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.MARKETPLACE.API_KEYS.GET_FI_INFO));
  }
}

// watcher
export default function* productWatcherSaga() {
  yield takeLatest(actionTypes.GET_FI_INFO, handleProduct);
}
