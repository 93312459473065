import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import * as ROUTES from "Constants/Routes";
import PopupModal from "../Common/PopupModal/PopupModal";

class BusinessIndividual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      employmentStatus: "",
      employerPosition: "",
      currentEmployer: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      address: "",
      unit: "",
      city: "",
      province: "",
      postalCode: "",
      mailingSameAsCivic: false,
      mailingAddress: "",
      mailingUnit: "",
      mailingCity: "",
      mailingProvince: "",
      mailingPostalCode: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct, processTemplate } = this.props;
    if (prevProps !== this.props) {
      this.setFormData();
      const processFlow = processTemplate.flow;
      const show = processFlow.map((item) => item.componentName);
      this.setState({
        processFlow,
        show,
      });
    }
  }

  setFormData = () => {
    const { activeProduct, personalData, finInfo } = this.props;
    if (personalData?.id) {
      this.setState({
        employmentStatus: finInfo?.config.businessEmployment?.find(
          (x) => x.code === personalData.employment?.employmentStatus
        )?.description,
        employerPosition: activeProduct?.position,
        currentEmployer: activeProduct?.employerName,
        firstName: personalData.member?.name,
        middleName: personalData.member?.middleName,
        lastName: personalData.member?.surname,
        dob: personalData.member?.dob,
        address: personalData?.address?.currentAddressLine1,
        unit: personalData?.address?.currentAddressUnitNumber,
        city: personalData?.address?.currentAddressCity,
        province: personalData?.address?.currentAddressProvince,
        postalCode: personalData?.address?.currentAddressPostalCode,
        mailingSameAsCivic: personalData?.address?.mailingSameAsCivic,
        mailingAddress: personalData?.address?.mailingAddressLine1,
        mailingUnit: personalData?.address?.mailingUnit,
        mailingCity: personalData?.address?.mailingCity,
        mailingProvince: personalData?.address?.mailingProvince,
        mailingPostalCode: personalData?.address?.mailingPostalCode,
      });
    } else {
      this.setState({
        employmentStatus: finInfo?.config.businessEmployment?.find(
          (x) => x.code === activeProduct?.employmentStatus
        )?.description,
        employerPosition: activeProduct?.position,
        currentEmployer: activeProduct?.employerName,
        firstName: activeProduct?.personal?.firstName,
        middleName: activeProduct?.personal?.surname,
        lastName: activeProduct?.personal?.lastName,
        dob: activeProduct?.personal?.dateOfBirth,
        address: activeProduct?.personal?.address,
        unit: activeProduct?.personal?.currentAddressUnitNumber,
        city: activeProduct?.personal?.city,
        province: activeProduct?.personal?.state,
        postalCode: activeProduct?.personal?.postalCode,
        mailingSameAsCivic: activeProduct?.personal?.mailingSameAsCivic,
        mailingAddress: activeProduct?.personal?.mailingAddressLine1,
        mailingUnit: activeProduct?.personal?.mailingUnit,
        mailingCity: activeProduct?.personal?.mailingCity,
        mailingProvince: activeProduct?.personal?.mailingProvince,
        mailingPostalCode: activeProduct?.personal?.mailingPostalCode,
      });
    }
  };

  toggleModal = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    if (valid) {
      handleContinue(property);
    }
  };
  handleContinue = () => {
    const { property } = this.state;
    let valid = true;

    if (valid) {
      this.setState({ showModal: true });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL);
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    return formIsValid;
  };

  render() {
    const {
      steps,
      activeStepID,
      handleEditPage,
      finInfo,
      applicantData,
      activeProduct,
    } = this.props;
    const {
      showModal,
      employmentStatus,
      employerPosition,
      currentEmployer,
      firstName,
      middleName,
      lastName,
      dob,
      address,
      unit,
      city,
      province,
      postalCode,
      mailingSameAsCivic,
      mailingAddress,
      mailingUnit,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      show,
    } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.BUSINESS_INDIVIDUAL.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_INDIVIDUAL.REVIEW_DETAILS_MSG1}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_INDIVIDUAL.REVIEW_DETAILS_MSG2}
          </div>

          {show?.includes(AppConstants.APPLICATIONSTEP.BUSINESS_KYC) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.BUSINESS_ABOUT_YOU}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.BUSINESS_KYC)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_INDIVIDUAL.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {firstName}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {middleName}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {lastName}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {dob}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {address}
                    </div>
                  </div>
                </div>

                {finInfo?.coreBankingType !==
                  AppConstants.CORE_BANKING_TYPE.DNA && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {unit}
                      </div>
                    </div>
                  </div>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {city}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {province}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {postalCode}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSCONFIRMQUESTION}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {mailingSameAsCivic ? "Yes" : "No"}
                    </div>
                  </div>
                </div>

                {!mailingSameAsCivic && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingAddress}
                        </div>
                      </div>
                    </div>

                    {finInfo?.coreBankingType !==
                      AppConstants.CORE_BANKING_TYPE.DNA && (
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {mailingUnit}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingCity}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingProvince}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingPostalCode}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {show?.includes(AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT) && (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.BUSINESS_EMPLOYMENT}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_EMPLOYMENT)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_INDIVIDUAL.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYMENT_STATUS_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentStatus}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_EMPLOYMENT.FORM.POSITION_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employerPosition}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYER_NAME_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {currentEmployer}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title={STRINGS.BUSINESS_INDIVIDUAL.SUCCESS_POP_UP_TITLE}
            description={STRINGS.BUSINESS_INDIVIDUAL.SUCCESS_POP_UP_DESCRIPTION}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessIndividual.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  handleSample: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessIndividual.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  handleSample: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessIndividual;
