import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AppConstants from "Constants/AppConstants";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import { bindActionCreators } from "redux";
import { add, format } from "date-fns";
import GICInfo from "./GICInfo";
import { getCountryList } from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { getTermProduct, updateTermProduct } from "./Actions/GICActions";
import getIntendedUseList from "../AccountInformation/Actions/AccountInformationAction";

class GICInfoContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      newMember: true,
      amount: 0,
      term: 0,
      intendedUse: null,
      productIntendedUseList: [],
      origProductInfo: null,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      actionBack,
      handleBack,
      doGetTermProduct,
      products,
      getActiveProduct,
      intendedUseList,
      doGetIntendedUseList,
    } = this.props;

    if (intendedUseList.length === 0) {
      doGetIntendedUseList();
    }

    const activeProduct = getActiveProduct(products);

    this.getProductIntendedUseList();

    doGetTermProduct(
      {
        applicationId: activeProduct.applicationId,
        doximProductId: activeProduct.doximProductId,
      },
      (res) => {
        console.log(res);
        let { intendedUse } = res;
        if (activeProduct.intendedUse) {
          intendedUse = res.udfList[activeProduct.intendedUse];
        }
        this.setState({
          amount: res.amount === 0 ? activeProduct.amount.min : res.amount,
          term: Array.isArray(activeProduct.term) ? 0 : activeProduct.term,
          intendedUse,
          origProductInfo: res,
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intendedUseList } = this.props;
    if (intendedUseList !== prevProps.intendedUseList) {
      this.getProductIntendedUseList();
    }
  }

  getProductIntendedUseList() {
    const { intendedUseList, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log("getProductIntendedUseList", intendedUseList);
    const productIntendedUseList = intendedUseList.filter(
      (item) =>
        item.productType === activeProduct.type &&
        ((item.productCategory &&
          item.productCategory === activeProduct.productCategory) ||
          !item.productCategory)
    );
    this.setState({
      productIntendedUseList,
    });
  }

  handleChange = () => {};

  handleContinue = (gicInfo) => {
    console.log("handleContinue", gicInfo);
    const {
      continuehandler,
      finInfo,
      doUpdateTermProduct,
      products,
      getActiveProduct,
    } = this.props;
    const { origProductInfo } = this.state;

    const activeProduct = getActiveProduct(products);
    let depositDate = new Date();

    if (gicInfo.term && finInfo.addTermToDepositDate) {
      depositDate = add(depositDate, { months: gicInfo.term });
    }

    const productInfo = {
      ...origProductInfo,
      amount: gicInfo.amount,
      rate: activeProduct.rate,
      depositDate: format(depositDate, "yyyy-MM-dd"),
    };

    if (activeProduct.defaultFundSource) {
      if (productInfo.udfList) {
        productInfo.udfList.udf2 = activeProduct.defaultFundSource;
      } else {
        productInfo.udfList = {
          udf2: activeProduct.defaultFundSource,
        };
      }
    }

    if (activeProduct.intendedUse) {
      productInfo.udfList[activeProduct.intendedUse] = gicInfo.intendedUse;
    } else {
      productInfo.intendedUse = gicInfo.intendedUse;
    }

    doUpdateTermProduct(
      activeProduct.applicationId,
      activeProduct.doximProductId,
      productInfo,
      (res) => {
        console.log("doUpdateTermProduct", res);
        if (res.data.success) {
          continuehandler(null, AppConstants.APPLICATIONSTEP.GIC_INFO);
        }
      }
    );
  };

  render() {
    const {
      editMode,
      newMember,
      amount,
      term,
      intendedUse,
      productIntendedUseList,
    } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    return (
      <>
        <GICInfo
          editMode={editMode}
          newMember={newMember}
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          finInfo={finInfo}
          activeProduct={activeProduct}
          amount={amount}
          term={term}
          intendedUse={intendedUse}
          intendedUseList={productIntendedUseList}
        />
      </>
    );
  }
}

GICInfoContainer.propTypes = {
  EmploymentAndIncomeStatus: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

GICInfoContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  EmploymentAndIncomeStatus: state.EmploymentAndIncomeReducer,
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
  intendedUseList: state.AccountInformationReducer.intendedUseList,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      countryList: getCountryList,
      doGetTermProduct: getTermProduct,
      doUpdateTermProduct: updateTermProduct,
      doGetIntendedUseList: getIntendedUseList,
    },
    dispatch
  );
};

const GICInfoWrapper = withApplicantHOC(GICInfoContainer);

export default connect(mapStateToProps, mapDispatchToProps)(GICInfoWrapper);
