import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import ExistingUser from "Communication/ApiClasses/ExistingUser";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

//  worker
function* handleExistingUser(action) {
  yield put(doLoadingStart(STRINGS.REGISTRATION.API_KEYS.EXISTING_USER));

  const existingUserApi = new ExistingUser(action.data);
  try {
    const result = yield call(existingUserApi.getData);
    yield put({
      type: actionTypes.EXISTINGUSER_RESPONSE,
      success: result,
    });
    if (action.callback) {
      yield call(action.callback, result);
    }
  } catch (error) {
    yield put({
      type: actionTypes.EXISTINGUSER_ERROR,
      error: error.response.data.description,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.REGISTRATION.API_KEYS.EXISTING_USER));
  }
}

//  watcher

export default function* existingUserWatcherSaga() {
  yield takeLatest(actionTypes.EXISTINGUSER_REQUEST, handleExistingUser);
}
