import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessInfoMail from "./BusinessInfoMail";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import {
  doAddressKey,
  doGetAddress,
  doGetProvinceList,
  updateVaultMember,
} from "../AboutYou/Actions/AboutYouActions";
import { HTTP_STATUS } from "../../Communication/Constants";
import { profileEsc } from "../../Redux/Actions/ApplicantAction";
import { getBusinessAddress } from "../../Utils/CommonUtilities";

class BusinessInfoMailContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      provinceList,
      products,
      getActiveProduct,
      doProfileEsc,
      doUpdateApplicant,
      doGetApplicant,
      vaultMember,
      doUpdateVaultMember,
      doUpdateProductToVault,
    } = this.props;
    console.log("componentDidMount");
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    }
    const activeProduct = getActiveProduct(products);
    if (vaultMember?.orderId) {
      doProfileEsc({ orderId: vaultMember.orderId, retry: 1 }, (profileRes) => {
        console.log(profileRes);
        if (profileRes.status === HTTP_STATUS.OK) {
          if (profileRes.data.status === "Complete") {
            const updatedVaultProductList = products;
            const idx = updatedVaultProductList.findIndex(
              (x) => x === activeProduct
            );
            updatedVaultProductList[idx].parties = profileRes.data.parties;
            doUpdateProductToVault(updatedVaultProductList);
            const updateRequest = {
              member: {
                businessNumber: profileRes.data.entity?.businessNumber,
              },
              business: {
                incorporationNumber: profileRes.data.entity?.number,
              },
              contact: {},
              address: getBusinessAddress(profileRes.data.entity?.addresses),
            };
            doUpdateApplicant(
              updateRequest,
              activeProduct.applicantId,
              (res) => {
                console.log("update result", res);

                delete vaultMember.orderId;
                doUpdateVaultMember(vaultMember, () => {
                  doGetApplicant({ id: activeProduct.applicantId }, () => {
                    console.log("esc profile pushed");
                  });
                });
              }
            );
          }
        }
      });
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      doUpdateApplicant,
      applicantData,
      doDisableBack,
    } = this.props;
    doUpdateApplicant(data, applicantData.id, (res) => {
      console.log(res);
      if (res.status === HTTP_STATUS.OK) {
        doDisableBack(false);
        continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_INFO_MAIL);
      }
    });
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      provinceList,
      doGetAddress,
      doGetProvinceData,
      doAddressKey,
      applicantData,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessInfoMail
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          finInfo={finInfo}
          provinceList={provinceList}
          doGetAddress={doGetAddress}
          doGetProvinceData={doGetProvinceData}
          doAddressKey={doAddressKey}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessInfoMailContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessInfoMailContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  vaultMember: state.ApplicationReducer.vaultMember,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doAddressKey: (callback) => dispatch(doAddressKey(callback)),
    doGetAddress: (params, callback) =>
      dispatch(doGetAddress(params, callback)),
    doProfileEsc: (payLoad, callback) =>
      dispatch(profileEsc(payLoad, callback)),
    doUpdateVaultMember: (member, callback) =>
      dispatch(updateVaultMember(member, callback)),
  };
};

const BusinessInfoMailWrapper = withBusinessHOC(BusinessInfoMailContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessInfoMailWrapper);
