import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

// Components
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

// Constants
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";

// Styles
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class BusinessTax extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusinessNotForProfit: false,
      isBusinessFileTaxesUSA: false,
      isBusinessFileTaxesOthers: false,
      isBusinessSolicitDonations: false,

      businessNotForProfit: "",
      businessTinUSA: "",
      businessFileTaxesOthersList: [
        {
          country: "",
          tin: "",
        },
      ],

      errors: {
        businessNotForProfitErrorList: [],
        businessTinUSAErrorList: [],
        businessFileTaxesOthersCountryErrorList: [],
        businessFileTaxesOthersTinErrorList: [],
      },
      formIsValid: true,

      testOptions: [
        { code: "ontario", description: "Ontario" },
        { code: "vancouver", description: "Vancouver" },
      ],

      countryOptions: [
        { code: "USA", description: "USA" },
        { code: "CANADA", description: "CANADA" },
      ],

      notForProfitOptions: [
        {
          code: STRINGS.BUSINESS_TAX.FORM.REGISTERED_CRA_ID,
          description: STRINGS.BUSINESS_TAX.FORM.REGISTERED_CRA,
        },
        {
          code: STRINGS.BUSINESS_TAX.FORM.NOT_REGISTERED_CRA_ID,
          description: STRINGS.BUSINESS_TAX.FORM.NOT_REGISTERED_CRA,
        },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.BUSINESS_TAX, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        isBusinessNotForProfit: activeProduct?.isBusinessNotForProfit,
        isBusinessFileTaxesUSA: activeProduct?.isBusinessFileTaxesUSA,
        isBusinessFileTaxesOthers: activeProduct?.isBusinessFileTaxesOthers,
        isBusinessSolicitDonations: activeProduct?.isBusinessSolicitDonations,
        businessNotForProfit: activeProduct?.businessNotForProfit ?? "",
        businessTinUSA: activeProduct?.businessTinUSA ?? "",
        businessFileTaxesOthersList:
          activeProduct?.businessFileTaxesOthersList ?? [],
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue, applicantData } = this.props;
    const {
      isBusinessNotForProfit,
      isBusinessFileTaxesUSA,
      isBusinessFileTaxesOthers,
      isBusinessSolicitDonations,
      businessNotForProfit,
      businessTinUSA,
      businessFileTaxesOthersList,
    } = this.state;
    let valid = false;
    valid = this.verifyData();
    if (valid) {
      let applicantComments = applicantData.applicantComments;
      if (applicantComments.search("<p>Not for profit business:.*</p>") > -1) {
        applicantComments = applicantComments
          .split(new RegExp("<p>Not for profit business:.+?</p>"))
          .join(
            `<p>Not for profit business: ${
              isBusinessNotForProfit ? "Yes" : "No"
            }</p>`
          );
      } else {
        applicantComments += `<p>Not for profit business: ${
          isBusinessNotForProfit ? "Yes" : "No"
        }</p>`;
      }
      if (isBusinessNotForProfit) {
        if (applicantComments.search("<p>Solicit donations:.*</p>") > -1) {
          applicantComments = applicantComments
            .split(new RegExp("<p>Solicit donations:.+?</p>"))
            .join(
              `<p>Solicit donations: ${
                isBusinessSolicitDonations ? "Yes" : "No"
              }</p>`
            );
        } else {
          applicantComments += `<p>Solicit donations: ${
            isBusinessSolicitDonations ? "Yes" : "No"
          }</p>`;
        }
      } else {
        if (applicantComments.search("<p>Solicit donations:.*</p>") > -1) {
          applicantComments = applicantComments
            .split(new RegExp("<p>Solicit donations:.+?</p>"))
            .join(``);
        }
      }
      if (applicantComments.search("<p>File taxes in USA:.*</p>") > -1) {
        applicantComments = applicantComments
          .split(new RegExp("<p>File taxes in USA:.+?</p>"))
          .join(
            `<p>File taxes in USA: ${isBusinessFileTaxesUSA ? "Yes" : "No"}</p>`
          );
      } else {
        applicantComments += `<p>File taxes in USA: ${
          isBusinessFileTaxesUSA ? "Yes" : "No"
        }</p>`;
      }
      if (isBusinessFileTaxesUSA) {
        if (
          applicantComments.search("<p>Tax Identification Number USA:.*</p>") >
          -1
        ) {
          applicantComments = applicantComments
            .split(new RegExp("<p>Tax Identification Number USA:.+?</p>"))
            .join(`<p>Tax Identification Number USA: ${businessTinUSA}</p>`);
        } else {
          applicantComments += `<p>Tax Identification Number USA: ${businessTinUSA}</p>`;
        }
      } else {
        if (
          applicantComments.search("<p>Tax Identification Number USA:.*</p>") >
          -1
        ) {
          applicantComments = applicantComments
            .split(new RegExp("<p>Tax Identification Number USA:.+?</p>"))
            .join(``);
        }
      }
      if (isBusinessFileTaxesOthers) {
        if (
          applicantComments.search("<p>File taxes in other countries:.*</p>") >
          -1
        ) {
          applicantComments = applicantComments
            .split(new RegExp("<p>File taxes in other countries:.+?</p>"))
            .join(
              `<p>File taxes in other countries: ${businessFileTaxesOthersList.map(
                (x) => {
                  return x.country + "/" + x.tin;
                }
              )}</p>`
            );
        } else {
          applicantComments += `<p>File taxes in other countries: ${businessFileTaxesOthersList.map(
            (x) => {
              return x.country + "/" + x.tin;
            }
          )}</p>`;
        }
      } else {
        if (
          applicantComments.search("<p>File taxes in other countries:.*</p>") >
          -1
        ) {
          applicantComments = applicantComments
            .split(new RegExp("<p>File taxes in other countries:.+?</p>"))
            .join(``);
        }
      }
      handleContinue({
        applicant: {
          contact: {},
          applicantComments,
        },
        vault: {
          isBusinessNotForProfit,
          isBusinessFileTaxesUSA,
          isBusinessFileTaxesOthers,
          isBusinessSolicitDonations,
          businessNotForProfit,
          businessTinUSA,
          businessFileTaxesOthersList,
        },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_TAX);
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  handleTaxOthersChange = (raw, prop) => (event) => {
    console.log("handleTaxOthersChange", raw, prop);
    const { businessFileTaxesOthersList, errors } = this.state;
    const tax = raw;
    tax[prop] = event.currentTarget.value;
    this.setState({
      businessFileTaxesOthersList,
      errors: {
        ...errors,
        businessFileTaxesOthersCountryErrorList: "",
        businessFileTaxesOthersTinErrorList: "",
      },
    });
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  /* Start Functions For Business Tax Other Country List*/
  handleAddTaxCountryOthers = (event) => {
    console.log("handleAddTaxCountry");
    if (this.validateTaxCountryOthers()) {
      const taxOthers = {
        country: "",
        tin: "",
      };
      const action = {
        type: "add",
        data: taxOthers,
      };
      this.updateTaxCountryOthersList(action);
    }
  };

  handleDeleteTaxCountryOthers = (idx, transact) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateTaxCountryOthersList(action);
  };

  validateTaxCountryOthers = () => {
    let valid = true;
    const {
      businessFileTaxesOthersList,
      isBusinessFileTaxesOthers,
      errors,
    } = this.state;

    if (businessFileTaxesOthersList.length > 0 && isBusinessFileTaxesOthers) {
      const countryRes = checkErrors(
        businessFileTaxesOthersList[businessFileTaxesOthersList.length - 1]
          .country,
        VALIDATIONS.BUSINESS_TAX.TAX_COUNTRY_OTHERS
      );
      const tinRes = checkErrors(
        businessFileTaxesOthersList[businessFileTaxesOthersList.length - 1].tin,
        VALIDATIONS.BUSINESS_TAX.TIN_OTHERS
      );

      this.setState({
        errors: {
          ...errors,
          businessFileTaxesOthersCountryErrorList: countryRes,
          businessFileTaxesOthersTinErrorList: tinRes,
        },
      });

      if (countryRes.length > 0 || tinRes.length > 0) {
        valid = false;
      }
    }

    return valid;
  };

  updateTaxCountryOthersList = (action) => {
    const { businessFileTaxesOthersList, errors } = this.state;
    if (action.type === "add") {
      businessFileTaxesOthersList.push(action.data);
    } else if (action.type === "remove") {
      if (action.index === businessFileTaxesOthersList.length - 1) {
        this.setState({
          errors: {
            ...errors,
            businessFileTaxesOthersCountryErrorList: [],
            businessFileTaxesOthersTinErrorList: [],
          },
        });
      }
      if (action.index > -1) {
        businessFileTaxesOthersList.splice(action.index, 1);
      }
    }
    this.setState({ businessFileTaxesOthersList });
    if (businessFileTaxesOthersList.length === 0) {
      // const { handleChange } = this.props;
      // handleChange(false);
    }
  };

  /* END Functions For Business Tax Other Country */

  verifyData = () => {
    let formIsValid = true;
    const {
      isBusinessNotForProfit,
      businessNotForProfit,

      isBusinessFileTaxesUSA,
      businessTinUSA,

      isBusinessFileTaxesOthers,
      businessFileTaxesOthersList,
      errors,
    } = this.state;

    let businessNotForProfitRes = [];
    let businessTinUSARes = [];
    let businessFileTaxesOthersCountryRes = [];
    let businessFileTaxesOthersTinRes = [];

    if (isBusinessNotForProfit)
      businessNotForProfitRes = checkErrors(
        businessNotForProfit,
        VALIDATIONS.BUSINESS_TAX.NOT_FOR_PROFIT
      );

    if (isBusinessFileTaxesUSA)
      businessTinUSARes = checkErrors(
        businessTinUSA,
        VALIDATIONS.BUSINESS_TAX.TIN_USA
      );

    if (
      isBusinessFileTaxesOthers &&
      businessFileTaxesOthersList[businessFileTaxesOthersList.length - 1]
    ) {
      businessFileTaxesOthersCountryRes = checkErrors(
        businessFileTaxesOthersList[businessFileTaxesOthersList.length - 1]
          .country,
        VALIDATIONS.BUSINESS_TAX.TAX_COUNTRY_OTHERS
      );
      businessFileTaxesOthersTinRes = checkErrors(
        businessFileTaxesOthersList[businessFileTaxesOthersList.length - 1].tin,
        VALIDATIONS.BUSINESS_TAX.TIN_OTHERS
      );
    }

    this.setState({
      errors: {
        ...errors,
        businessNotForProfitErrorList: businessNotForProfitRes,
        businessTinUSAErrorList: businessTinUSARes,
        businessFileTaxesOthersCountryErrorList: businessFileTaxesOthersCountryRes,
        businessFileTaxesOthersTinErrorList: businessFileTaxesOthersTinRes,
      },
    });

    if (
      businessNotForProfitRes.length > 0 ||
      businessTinUSARes.length > 0 ||
      businessFileTaxesOthersCountryRes.length > 0 ||
      businessFileTaxesOthersTinRes.length > 0
    ) {
      formIsValid = false;
    }
    return formIsValid;
  };

  render() {
    const { steps, activeStepID } = this.props;
    const {
      isBusinessNotForProfit,
      businessNotForProfit,
      isBusinessSolicitDonations,

      isBusinessFileTaxesUSA,
      businessTinUSA,

      isBusinessFileTaxesOthers,
      businessFileTaxesOthersList,

      errors,
      testOptions,
      countryOptions,
      notForProfitOptions,
    } = this.state;

    const optionList = testOptions.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const countryListComponent = countryOptions.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const notForProfitOptionsComponent = notForProfitOptions.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const businessNotForProfitComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_TAX.FORM.NOT_FOR_PROFIT}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_TAX.FORM.NOT_FOR_PROFIT_LABEL}
            </div>
            <select
              value={businessNotForProfit}
              name={"businessNotForProfit"}
              className={
                errors?.businessNotForProfitErrorList &&
                errors?.businessNotForProfitErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handleChange}
              id={"businessNotForProfit"}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {notForProfitOptionsComponent}
            </select>
            <div className="[ error-msg ]">
              {errors?.businessNotForProfitErrorList[0]}
            </div>
          </div>

          {/* BUSINESS SOLICIT NOT FOR PROFIT */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_TAX.FORM.DOES_BUSINESS_SOLICIT_DONATIONS}
            </div>
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    isBusinessSolicitDonations
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isBusinessSolicitDonations"
                  onClick={(event) =>
                    this.handleChoice("isBusinessSolicitDonations", true, event)
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    !isBusinessSolicitDonations
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  name="isBusinessSolicitDonations"
                  onClick={(event) =>
                    this.handleChoice(
                      "isBusinessSolicitDonations",
                      false,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    {
      /* Business File Taxes Usa Component */
    }

    const businessFileTaxesUSAComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_TAX.FORM.TAX_IDENTITY_NUMBER_USA}
          </div>

          <div className="[ info-message  ]">
            {STRINGS.BUSINESS_TAX.TAX_IDENTITY_NUMBER_USA_LABEL}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_TAX.FORM.LEGAL_BUSINESS_NAME}
            </div>

            <NumberFormat
              format={AppConstants.BUSINESS_TAX.US_TIN_FORMATE}
              className={
                errors?.businessTinUSAErrorList &&
                errors?.businessTinUSAErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              type="text"
              name="businessTinUSA"
              value={businessTinUSA}
              placeholder={`${STRINGS.BUSINESS_TAX.FORM.TAX_IDENTITY_NUMBER_USA_PLACEHOLDER}`}
              onChange={(event) => this.handleChange(event)}
            />
            <div>
              <small>
                {STRINGS.BUSINESS_TAX.FORM.TAX_IDENTITY_NUMBER_USA_DESCRIPTION}
              </small>
            </div>
            <div className="[ error-msg ]">
              {errors?.businessTinUSAErrorList[0]}
            </div>
          </div>
        </div>
      </div>
    );

    const businessFileTaxesOthersListComponent = businessFileTaxesOthersList.map(
      (file, index) => (
        <>
          <div className="[ mb-3 mt-3 ]">
            <div className="[ divider ]" />
            <div className="[ info-title ]">
              {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY} {index + 1}
              <button
                type="button"
                className="[ fas fa-trash ]"
                onClick={() => this.handleDeleteTaxCountryOthers(index, file)}
                aria-label="remove"
              >
                <span className="[ sr-only ]">
                  {STRINGS.INCOME.FORM.REMOVE_INCOME}
                </span>
              </button>
            </div>

            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_LABEL}
              </div>

              <select
                value={file?.country}
                name={"country"}
                className={
                  errors?.businessFileTaxesOthersCountryErrorList &&
                  errors?.businessFileTaxesOthersCountryErrorList.length > 0 &&
                  businessFileTaxesOthersList.length === index + 1
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleTaxOthersChange(file, "country")}
                id={"country"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {countryListComponent}
              </select>

              <div className="[ error-msg ]">
                {businessFileTaxesOthersList.length === index + 1 &&
                  errors?.businessFileTaxesOthersCountryErrorList[0]}
              </div>
            </div>
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_TAX.FORM.TIN_LABEL}
              </div>
              <input
                type="text"
                id="tin"
                name="tin"
                className={
                  errors?.businessFileTaxesOthersTinErrorList &&
                  errors?.businessFileTaxesOthersTinErrorList.length > 0 &&
                  businessFileTaxesOthersList.length === index + 1
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                placeholder={STRINGS.BUSINESS_TAX.FORM.TIN_PLACEHOLDER}
                value={file?.tin}
                onChange={this.handleTaxOthersChange(file, "tin")}
              />
              <div className="[ error-msg ]">
                {businessFileTaxesOthersList.length === index + 1 &&
                  errors?.businessFileTaxesOthersTinErrorList[0]}
              </div>
            </div>
          </div>
        </>
      )
    );

    {
      /* Business File Taxes For Other Countries Component */
    }
    const businessFileTaxesOthersComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_TAX.FORM.TAX_IDENTITY_NUMBER}
          </div>

          <div className="[ info-message  ]">
            {STRINGS.BUSINESS_TAX.COUNTRY_FILE_LABEL}
          </div>

          {businessFileTaxesOthersListComponent}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                  onClick={(event) => this.handleAddTaxCountryOthers(event)}
                >
                  <span className="[ fas fa-plus ]" />
                  {STRINGS.BUSINESS_TAX.FORM.ADD_BTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="[ business-tax-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_TAX.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              {/* Business Not For Profit */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {
                    STRINGS.BUSINESS_TAX.FORM
                      .IS_BUSINESS_CONSIDERED_NOT_FOR_PROFIT
                  }
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessNotForProfit
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isBusinessNotForProfit"
                      onClick={(event) =>
                        this.handleChoice("isBusinessNotForProfit", true, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        !isBusinessNotForProfit
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isBusinessNotForProfit"
                      onClick={(event) =>
                        this.handleChoice(
                          "isBusinessNotForProfit",
                          false,
                          event
                        )
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isBusinessNotForProfit && businessNotForProfitComponent}

              {/* Business File Taxes in USA */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_TAX.FORM.DOES_BUSINESS_FILE_TAXES_IN_USA}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessFileTaxesUSA
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isBusinessFileTaxesUSA"
                      onClick={(event) =>
                        this.handleChoice("isBusinessFileTaxesUSA", true, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        !isBusinessFileTaxesUSA
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isBusinessFileTaxesUSA"
                      onClick={(event) =>
                        this.handleChoice(
                          "isBusinessFileTaxesUSA",
                          false,
                          event
                        )
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isBusinessFileTaxesUSA && businessFileTaxesUSAComponent}

              {/* Business File Taxes in Other Countries */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_TAX.FORM.DOES_BUSINESS_FILE_TAXES_IN_OTHERS}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessFileTaxesOthers
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isBusinessFileTaxesOthers"
                      onClick={(event) =>
                        this.handleChoice(
                          "isBusinessFileTaxesOthers",
                          true,
                          event
                        )
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        !isBusinessFileTaxesOthers
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isBusinessFileTaxesOthers"
                      onClick={(event) =>
                        this.handleChoice(
                          "isBusinessFileTaxesOthers",
                          false,
                          event
                        )
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isBusinessFileTaxesOthers && businessFileTaxesOthersComponent}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessTax.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessTax.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessTax;
