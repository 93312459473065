import * as actionTypes from "Redux/Actions/actionTypes";
import AppConstants from "../../../Constants/AppConstants";

const initialState = {
  vaultProductList: [],
  fundProduct: {},
};

const VaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VAULT_PRODUCT_LIST:
      return {
        ...state,
        vaultProductList:
          action.data && Array.isArray(action.data) ? action.data : [],
      };
    case actionTypes.STORE_FUND_PRODUCT:
      return {
        ...state,
        fundProduct: action.data,
      };
    case actionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default VaultReducer;
