import { call, put, takeLatest } from "redux-saga/effects";
import {
  SET_IN_SESSION_JOINT_VAULT,
  UPDATE_VAULT_MEMBER,
} from "../../../Redux/Actions/actionTypes";
import UpdateVaultMemberRequest from "../../../Communication/ApiClasses/UpdateVaultMemberRequest";
import {
  doLoadingFinish,
  doLoadingStart,
} from "../../Common/ProgressIndicator/Actions/ProgressIndicatorActions";

function* handleSaga(action) {
  yield put(doLoadingStart(`${UPDATE_VAULT_MEMBER}_SAGA`));
  const request = new UpdateVaultMemberRequest(action.data);
  try {
    const result = yield call(request.getData);
    if (action.data.inSessionJoint) {
      yield put({
        type: `${SET_IN_SESSION_JOINT_VAULT}`,
        data: action.data,
      });
    }
    yield put({
      type: `${UPDATE_VAULT_MEMBER}_SUCCESS`,
      data: action.data,
    });

    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${UPDATE_VAULT_MEMBER}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${UPDATE_VAULT_MEMBER}_SAGA`));
  }
}

// watcher
export default function* executeSaga() {
  yield takeLatest(UPDATE_VAULT_MEMBER, handleSaga);
}
