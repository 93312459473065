import * as actionTypes from "Redux/Actions/actionTypes";

export const doSignin = (userDetails, callback) => ({
  type: actionTypes.SIGNIN_REQUEST,
  data: userDetails,
  callback,
});

export const addUsername = (username) => ({
  type: actionTypes.ADD_USERNAME,
  data: username,
});

export const doForgotPassword = (userDetails, callback = () => {}) => ({
  type: actionTypes.FORGOT_PASSWORD_REQUEST,
  data: userDetails,
  callback,
});

export const doReset = () => ({ type: actionTypes.RESET });
