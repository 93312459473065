import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

import * as actionTypes from "Redux/Actions/actionTypes";
import { GET_CENTRAL, SIGNIN_RESPONSE } from "../Actions/actionTypes";
import GetCentralInfo from "../../Communication/ApiClasses/GetCentralInfo";
import {
  TOKEN_KEY,
  IS_LOGOUT,
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_APPLICANT_ID,
  S_MSISDN,
  S_EMAIL,
  S_SSO,
} from "../../Communication/Constants";

// worker
function* handleGetCentral(action) {
  yield put(doLoadingStart(`${GET_CENTRAL}_SAGA`, action.waitMessage));
  const getCentralInfo = new GetCentralInfo(action.token);
  try {
    const result = yield call(getCentralInfo.getData);
    sessionStorage.setItem(TOKEN_KEY, result.data.token);
    sessionStorage.setItem(S_MSISDN, result.data.msisdn);
    sessionStorage.setItem(S_EMAIL, result.data.email);
    sessionStorage.setItem(S_SSO, "yes");
    sessionStorage.setItem(IS_LOGOUT, false);
    sessionStorage.setItem(EXPIRES_AT, result.data.expiresAt);
    sessionStorage.setItem(REFRESH_EXPIRES_AT, result.data.refreshExpiresAt);
    if (result.data.applicantId) {
      sessionStorage.setItem(S_APPLICANT_ID, result.data.applicantId);
    }
    yield put({ type: SIGNIN_RESPONSE, success: result });
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error);
  } finally {
    yield put(doLoadingFinish(`${GET_CENTRAL}_SAGA`));
  }
}

// watcher
export default function* getCentralWatcherSaga() {
  yield takeLatest(GET_CENTRAL, handleGetCentral);
}
