import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  jsonData: [],
  success: "",
  error: "",
  formData: "",
  applicantTypes: [],
  appProperties: [],
  doximProducts: [],
  jointApplicant: "",
  applicationId: "",
  otpVerify: {
    success: "",
    error: "",
    response: "",
  },
  globalVault: {
    success: "",
    error: "",
    response: "",
  },
  createSelf: {
    success: "",
    error: "",
    response: "",
  },
  fillApplicationSlot: {
    success: "",
    error: "",
    response: "",
    reqData: "",
  },
  inSessionJointApplicant: null,
};

const JoinApplicantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_APPLICATION_SLOT_SUCCESS:
      return {
        ...state,
        success: action.success,
        error: "",
        jsonData: action.response.data,
      };
    case actionTypes.ADD_APPLICATION_SLOT_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
      };
    case actionTypes.INVITE_APPLICATION_SLOT_SUCCESS:
      return {
        ...state,
        success: action.success,
        error: "",
        jsonData: action.response.data,
      };
    case actionTypes.INVITE_APPLICATION_SLOT_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
      };
    case actionTypes.GET_APPLICANT_TYPE_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.success,
        applicantTypes: action.applicantTypes,
      };
    case actionTypes.GET_APPLICANT_TYPE_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        applicantTypes: [],
      };
    case actionTypes.GET_APPLICATION_PROPERTY_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        appProperties: action.response.slots,
        doximProducts: action.response.doximProducts,
        applicationId: action.applicationId,
      };
    case actionTypes.GET_APPLICATION_PROPERTY_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        appProperties: [],
        doximProducts: [],
      };
    case actionTypes.DELETE_APPLICATION_SLOT_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        jsonData: action.response.data ? action.response.data : "",
      };
    case actionTypes.DELETE_APPLICATION_SLOT_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        jsonData: action.response?.data ? action.response.data : "",
      };
    case actionTypes.ADD_GLOBAL_VAULT_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        jsonData: action.response.data ? action.response.data : "",
      };
    case actionTypes.ADD_GLOBAL_VAULT_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        jsonData: action.response?.data ? action.response.data : "",
      };
    case actionTypes.GET_GLOBAL_VAULT_SUCCESS:
      return {
        ...state,
        globalVault: {
          error: action.error,
          success: true,
          response: action.response,
        },
      };
    case actionTypes.GET_GLOBAL_VAULT_ERROR:
      return {
        ...state,
        globalVault: {
          error: action.error,
          success: "",
          response: "",
        },
      };
    case actionTypes.APPLICANT_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        response: action.response,
      };
    case actionTypes.APPLICANT_CREATE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        response: action.response,
      };
    case actionTypes.APPLICANT_CREATE_SELF_SUCCESS:
      return {
        ...state,
        createSelf: {
          error: "",
          success: true,
          response: action.response,
        },
      };
    case actionTypes.APPLICANT_CREATE_SELF_ERROR:
      return {
        ...state,
        createSelf: {
          error: action.error,
          success: "",
          response: action.response,
        },
      };
    case actionTypes.OTP_VERIFY_INVITE_SUCCESS:
      return {
        ...state,
        otpVerify: {
          success: true,
          error: "",
          data: action.response,
        },
      };
    case actionTypes.OTP_VERIFY_INVITE_ERROR:
      return {
        ...state,
        otpVerify: {
          success: "",
          error: action.error,
          data: action.response,
        },
      };
    case actionTypes.FILL_APPLICATION_SLOT_SUCCESS:
      return {
        ...state,
        fillApplicationSlot: {
          success: true,
          error: "",
          data: action.response,
          requestData: action.requestData,
        },
      };
    case actionTypes.FILL_APPLICATION_SLOT_ERROR:
      return {
        ...state,
        fillApplicationSlot: {
          success: "",
          error: action.error,
          data: action.response,
          requestData: action.requestData,
        },
      };
    case actionTypes.SET_IN_SESSION_JOINT_APPLICANT:
      return {
        ...state,
        inSessionJointApplicant: action.data,
        fillApplicationSlot: action.data ? state.fillApplicationSlot : null,
      };
    case actionTypes.SET_JOINT_APPLICANT_OTP:
      return {
        ...state,
        otp: action.data,
      };
    case actionTypes.SET_IN_SESSION_JOINT_VAULT:
      return {
        ...state,
        inSessionJointApplicant: {
          ...state.inSessionJointApplicant,
          vault: action.data,
        },
      };
    default:
      return state;
  }
};

export default JoinApplicantReducer;
