import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import {
  ENABLE_ONLINE_BANKING_PASSWORD_URL,
  SEARCH_MEMBER_URL,
} from "../ApiUrls";
import { METHOD } from "../Constants";

export default class SearchMemberRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = SEARCH_MEMBER_URL;

  constructor(request) {
    super();

    this.body = request;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
