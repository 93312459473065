import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import AppConstants from "Constants/AppConstants";

import EmploymentAndIncome from "./EmploymentAndIncome";
import {
  getCountryList,
  getStatusList,
  getIncomeSourcesList,
  getEmployerPositionCodes,
} from "./Actions/EmploymentAndIncomeAction";
import { findCode } from "../../Utils/CommonUtilities";
import { getOccupationCodes } from "../../Redux/Actions/ApplicationAction";
import {
  doAddressKey,
  doGetAddress,
} from "../AboutYou/Actions/AboutYouActions";
import { LENDING_PRODUCT } from "../../Communication/Constants";

class EmploymentAndIncomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      newMember: true,
      addressInstanceKey: "",
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      countryList,
      statusList,
      incomeSourcesList,
      applicantData,
      applicationCompleteList,
      inSessionJointApplicant,
      processTemplate,
      continuehandler,
      actionBack,
      handleBack,
      doDisableBack,
      EmploymentAndIncomeStatus,
      getEmployerPositionCodes,
      getOccupationCodes,
      occupationCodes,
      doAddressKey,
    } = this.props;
    if (EmploymentAndIncomeStatus.countryList.length === 0) {
      countryList();
    }
    if (EmploymentAndIncomeStatus.statusList.length === 0) {
      statusList();
    }
    if (EmploymentAndIncomeStatus.incomeSourcesList.length === 0) {
      incomeSourcesList();
    }
    if (EmploymentAndIncomeStatus.employerPositionCodes.length === 0) {
      getEmployerPositionCodes();
    }
    if (occupationCodes.length === 0) {
      getOccupationCodes();
    }

    if (inSessionJointApplicant) {
      doDisableBack(true);
      //check joint step exist in process template
      console.log(processTemplate);
      const step = processTemplate.flow.find(
        (s) => s.componentName === AppConstants.APPLICATIONSTEP.EMPLOYMENT
      );
      if (step) {
      } else {
        if (actionBack) {
          // no going back from employment page
          continuehandler(null, AppConstants.APPLICATIONSTEP.EMPLOYMENT);
        } else {
          continuehandler(null, AppConstants.APPLICATIONSTEP.EMPLOYMENT);
        }
      }
    } else {
      if (applicantData?.acquireType === "search") {
        this.setState({
          editMode: false,
        });
      }
      if (
        applicantData?.acquireType === "search" ||
        (applicationCompleteList && applicationCompleteList.length > 0) ||
        sessionStorage.getItem("TEMP_HIDE") === "true"
      ) {
        this.setState({
          newMember: false,
        });
      }
    }

    doAddressKey((response) => {
      let addressInstanceKey = "";
      if (response.data.Output && response.data.Output.length > 0) {
        addressInstanceKey = response.data.Output[0].InstanceKey;
      }
      this.setState({ addressInstanceKey });
    });
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { addressInstanceKey } = this.state;
  //   const { doAddressKey } = this.props;
  //   if(!addressInstanceKey){
  //     doAddressKey((response) => {
  //       let addressInstanceKey = "";
  //       if (response.data.Output && response.data.Output.length > 0) {
  //         addressInstanceKey = response.data.Output[0].InstanceKey;
  //       }
  //       this.setState({ addressInstanceKey });
  //     });
  //   }
  // }

  onSubmitHandler = (formDetails) => {
    const {
      continuehandler,
      occupationCodes,
      finInfo,
      products,
      getActiveProduct,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    const SalaryFormat =
      formDetails &&
      formDetails.monthlyGrossIncome.replace(
        AppConstants.EMPLOYMENTANDINCOME.SALARY_PATTERN,
        ""
      ); // Removing special characters for salary

    const despatchObject = {
      // TODO:remove it as it is work around from mobetize
      employment: {
        currentEmployer: formDetails.employerName,
        employerSalary: SalaryFormat,
        incomeType: formDetails.sourceOfIncome,
        employerPosition: formDetails.employerPosition,
      },
    };

    if (finInfo.showEmployerAddress && activeProduct.type === LENDING_PRODUCT) {
      despatchObject.employment = {
        ...despatchObject.employment,
        employerCity: formDetails.property.city,
        employerProv: formDetails.property.province,
        employerPc: formDetails.property.postalCode,
        employerLine1: formDetails.property.employerLine1,
        employerLine2: formDetails.property.employerLine2,
      };
    }

    if (
      formDetails.employerPhoneSettings.page ===
      AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      const validMobileNo =
        formDetails &&
        formDetails.employerContactNumber.replace(
          AppConstants.EMPLOYMENTANDINCOME.CONTACT_NO_PATTERN,
          ""
        ); // For Removing special characters

      const PhoneArea = validMobileNo.substring(0, 3); // To get first 10 digit for employerPhoneArea
      const PhoneNumber = validMobileNo.substring(3); // To get first last 7 digit for employerPhone
      despatchObject.employment.employerPhoneArea = PhoneArea;
      despatchObject.employment.employerPhone = PhoneNumber;
    }
    if (
      formDetails.occupationCodeSettings.page ===
      AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      const jobTitle = findCode(formDetails.occupationCode, occupationCodes);
      despatchObject.employment.occupationCode = formDetails.occupationCode;
      despatchObject.employment.jobTitle = jobTitle;
    }
    if (
      formDetails.employmentStatusSettings.page ===
      AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      despatchObject.employment.employmentStatus = formDetails.employmentStatus;
    }
    continuehandler(despatchObject, AppConstants.APPLICATIONSTEP.EMPLOYMENT);
  };

  render() {
    const { editMode, newMember, addressInstanceKey } = this.state;
    const {
      EmploymentAndIncomeStatus,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      config,
      occupationCodes,
      finInfo,
      provinceList,
      streetTypes,
      doGetAddress,
      doAddressKey,
      processFlow,
      inSessionJointApplicant,
      applicantData,
    } = this.props;
    let activeProduct = getActiveProduct(products);
    if (inSessionJointApplicant) {
      activeProduct = {
        ...activeProduct,
        prospect: inSessionJointApplicant.prospect,
      };
    }
    return (
      <EmploymentAndIncome
        editMode={editMode}
        statusList={EmploymentAndIncomeStatus.statusList}
        incomeSourcesList={EmploymentAndIncomeStatus.incomeSourcesList}
        employerPositionCodes={EmploymentAndIncomeStatus.employerPositionCodes}
        getOptions={this.getOptions}
        onSubmitHandler={this.onSubmitHandler}
        getDataFromServer={getDataFromServer}
        handleBack={() => handleBack(AppConstants.APPLICATIONSTEP.EMPLOYMENT)}
        steps={steps}
        activeStepID={activeStepID}
        newMember={newMember}
        activeProduct={activeProduct}
        config={config}
        occupationCodes={occupationCodes}
        finInfo={finInfo}
        provinceList={provinceList}
        streetTypes={streetTypes}
        doGetAddress={doGetAddress}
        addressInstanceKey={addressInstanceKey}
        processFlow={processFlow}
        applicantData={applicantData}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      countryList: getCountryList,
      statusList: getStatusList,
      incomeSourcesList: getIncomeSourcesList,
      getEmployerPositionCodes: getEmployerPositionCodes,
      getOccupationCodes: getOccupationCodes,
      doGetAddress: doGetAddress,
      doAddressKey: doAddressKey,
    },
    dispatch
  );
};
const mapStateToProps = (state) => ({
  EmploymentAndIncomeStatus: state.EmploymentAndIncomeReducer,
  applicantData: state.ApplicationReducer.response,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  products: state.VaultReducer.vaultProductList,
  config: state.MarketplaceReducer.finInfo?.config,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  processTemplate: state.ApplicationReducer.processTemplate,
  occupationCodes: state.ApplicationReducer.occupationCodes,
  finInfo: state.MarketplaceReducer.finInfo,
  provinceList: state.AboutYouReducer.provinceList,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  processFlow: state.ApplicationReducer.processTemplate.flow,
});

EmploymentAndIncomeContainer.propTypes = {
  EmploymentAndIncomeStatus: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  countryList: PropTypes.func.isRequired,
  statusList: PropTypes.func.isRequired,
  incomeSourcesList: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  occupationCodes: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};

EmploymentAndIncomeContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  occupationCodes: [],
  finInfo: {},
  applicantData: null,
};

const EmploymentAndIncomeWrapper = withApplicantHOC(
  EmploymentAndIncomeContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentAndIncomeWrapper);
