import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import SubmitQuestionnaireRequest from "../../../Communication/ApiClasses/SubmitQuestionnaireRequest";

// worker
function* sumitQuestionnaire(action) {
  yield put(doLoadingStart(`${actionTypes.SUBMIT_QUESTIONNAIRE}_SAGA`));
  const submitQuestionnaireRequest = new SubmitQuestionnaireRequest(
    action.data
  );
  try {
    const result = yield call(submitQuestionnaireRequest.getData);
    yield put({
      type: `${actionTypes.SUBMIT_QUESTIONNAIRE}_SUCCESS`,
      error: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${actionTypes.SUBMIT_QUESTIONNAIRE}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${actionTypes.SUBMIT_QUESTIONNAIRE}_SAGA`));
  }
}

// watcher
function* handleSaga() {
  yield takeLatest(actionTypes.SUBMIT_QUESTIONNAIRE, sumitQuestionnaire);
}

export default handleSaga;
