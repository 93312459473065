import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "../../Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import KycInviteRequest from "../../Communication/ApiClasses/KycInviteRequest";

// worker
function* handleAction(action) {
  yield put(doLoadingStart(actionTypes.KYC_INVITE));

  const kycInviteRequest = new KycInviteRequest(action.data);
  try {
    const result = yield call(kycInviteRequest.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.KYC_INVITE}_ERROR`,
      error,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.KYC_INVITE));
  }
}

// watcher
export default function* handleSaga() {
  yield takeLatest(actionTypes.KYC_INVITE, handleAction);
}
