import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";
import PopupModal from "../Common/PopupModal/PopupModal";

class BusinessEligibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCannabisOrMoneyIndustry: "",
      isFinancialInstitution: "",
      showDeleteConfirmation: false,
      errors: {
        isCannabisOrMoneyIndustryErrorList: [],
        isFinancialInstitutionErrorList: [],
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_ELIGIBILITY,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        isCannabisOrMoneyIndustry: activeProduct?.isCannabisOrMoneyIndustry,
        isFinancialInstitution: activeProduct?.isFinancialInstitution,
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { isCannabisOrMoneyIndustry, isFinancialInstitution } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({ isCannabisOrMoneyIndustry, isFinancialInstitution });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_ELIGIBILITY);
  };

  verifyData = () => {
    let formIsValid = true;

    const {
      isCannabisOrMoneyIndustry,
      isFinancialInstitution,
      errors,
    } = this.state;

    const isCannabisOrMoneyIndustryRes = checkErrors(
      isCannabisOrMoneyIndustry,
      VALIDATIONS.BUSINESS_ELIGIBILITY.IS_CANNABIS_OR_MONEY
    );
    const isFinancialInstitutionRes = checkErrors(
      isFinancialInstitution,
      VALIDATIONS.BUSINESS_ELIGIBILITY.IS_FINANCIAL_INSTITUTION
    );

    this.setState({
      errors: {
        ...errors,
        isCannabisOrMoneyIndustryErrorList: isCannabisOrMoneyIndustryRes,
        isFinancialInstitutionErrorList: isFinancialInstitutionRes,
      },
    });

    if (
      isCannabisOrMoneyIndustryRes.length > 0 ||
      isFinancialInstitutionRes.length > 0
    ) {
      formIsValid = false;
    }

    return formIsValid;
  };

  handleCannabisBtn = (flag) => {
    this.setState({
      isCannabisOrMoneyIndustry: flag,
    });
  };

  handleFinBtn = (flag) => {
    this.setState({
      isFinancialInstitution: flag,
    });
  };

  removeApplication = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  cancelRemoveApplication = () => {
    this.setState({ showDeleteConfirmation: false });
  };

  continueRemoveApplication = () => {
    const { removeApplication } = this.props;
    removeApplication();
  };

  render() {
    const { steps, activeStepID, activeProduct } = this.props;
    const {
      isCannabisOrMoneyIndustry,
      isFinancialInstitution,
      showDeleteConfirmation,
    } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ business-eligibility-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.BUSINESS_ELIGIBILITY.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_ELIGIBILITY.FORM.IS_CANNABIS}
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isCannabisOrMoneyIndustry === "Yes"
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isAddApplicant"
                  onClick={(event) => this.handleCannabisBtn("Yes", event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isCannabisOrMoneyIndustry === "No"
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isAddApplicant"
                  onClick={(event) => this.handleCannabisBtn("No", event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            {isCannabisOrMoneyIndustry === "Yes" && (
              <div className="[ drawer-content-container alert alert-warning ] ">
                <div className="[ row ]">
                  <div className="[ col-12 col-lg-12 ] [ mb-3 ]">
                    <span className="[ info-title ]">
                      <i className="[ fas fa-exclamation-circle ]" />
                      {STRINGS.BUSINESS_ELIGIBILITY.FORM.YES_TO_CANNABIS_TITLE}
                    </span>
                    <p>
                      {STRINGS.BUSINESS_ELIGIBILITY.FORM.YES_TO_CANNABIS_BODY.split(
                        "_PRODUCT_"
                      ).join(activeProduct.productName)}
                    </p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          STRINGS.BUSINESS_ELIGIBILITY.FORM
                            .YES_TO_CANNABIS_BODY1,
                      }}
                    />
                  </div>
                  <div className="[ col-12 ]">
                    <button
                      type="button"
                      className="[ cta__primary-action-call btn info-btn ]"
                      onClick={this.removeApplication}
                    >
                      {
                        STRINGS.BUSINESS_ELIGIBILITY.FORM
                          .REMOVE_FROM_VAULT_AND_GO_BACK
                      }
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_ELIGIBILITY.FORM.IS_FINANCIAL_INSTITUTION}
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isFinancialInstitution === "Yes"
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isAddApplicant"
                  onClick={(event) => this.handleFinBtn("Yes", event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isFinancialInstitution === "No"
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isAddApplicant"
                  onClick={(event) => this.handleFinBtn("No", event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            {isFinancialInstitution === "Yes" && (
              <div className="[ drawer-content-container alert alert-warning ] ">
                <div className="[ row ]">
                  <div className="[ col-12 col-lg-12 ] [ mb-3 ]">
                    <span className="[ info-title ]">
                      <i className="[ fas fa-exclamation-circle ]" />
                      {
                        STRINGS.BUSINESS_ELIGIBILITY.FORM
                          .YES_TO_FININCIAL_INSTITUTION_TITLE
                      }
                    </span>
                    <p>
                      {STRINGS.BUSINESS_ELIGIBILITY.FORM.YES_TO_FININCIAL_INSTITUTION_BODY.split(
                        "_PRODUCT_"
                      ).join(activeProduct.productName)}
                    </p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          STRINGS.BUSINESS_ELIGIBILITY.FORM
                            .YES_TO_FININCIAL_INSTITUTION_BODY1,
                      }}
                    />
                  </div>
                  <div className="[ col-12 ]">
                    <button
                      type="button"
                      className="[ cta__primary-action-call btn info-btn ]"
                      onClick={this.removeApplication}
                    >
                      {
                        STRINGS.BUSINESS_ELIGIBILITY.FORM
                          .REMOVE_FROM_VAULT_AND_GO_BACK
                      }
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                disabled={
                  isCannabisOrMoneyIndustry !== "No" ||
                  isFinancialInstitution !== "No"
                }
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
        {showDeleteConfirmation && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={
              "You are about to discard your " +
              activeProduct.productName +
              " application and will be redirected to your dashboard. Are you sure you want to delete this product?"
            }
            showModal={showDeleteConfirmation}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            closeBtnClick={this.cancelRemoveApplication}
            popupBtnClick={this.continueRemoveApplication}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessEligibility.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessEligibility.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessEligibility;
