import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import * as ROUTES from "Constants/Routes";
import PopupModal from "../Common/PopupModal/PopupModal";

class BusinessConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_CONFIRM,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  setFormData = () => {};

  toggleModal = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;

    handleContinue({ property });
  };
  handleContinue = () => {
    const { applicationCompleteList, handleContinue } = this.props;
    if (applicationCompleteList?.length > 0) {
      handleContinue();
    } else {
      let valid = true;

      if (valid) {
        this.setState({ showModal: true });
      }
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_CONFIRM);
  };

  render() {
    const {
      steps,
      activeStepID,
      handleEditPage,
      finInfo,
      activeProduct,
      processFlow,
    } = this.props;
    const { showModal } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_CONFIRM.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_REVIEW.REVIEW_DETAILS_MSG1}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_REVIEW.REVIEW_DETAILS_MSG2}
          </div>

          {processFlow?.find(
            (x) =>
              x.componentName ===
              AppConstants.APPLICATIONSTEP.BUSINESS_OVERDRAFT
          ) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.BUSINESS_REVIEW.INTENDED_PURPOSE_ACCOUNT}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.BUSINESS_COMPLIANCE)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.BUSINESS_REVIEW.INTENDED_PURPOSE_ACCOUNT_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {
                      finInfo?.config?.businessPurpose.find(
                        (item) =>
                          item.code === activeProduct.businessIntendedPurpose
                      )?.description
                    }
                  </div>
                </div>
              </div>
            </div>
          )}

          {processFlow?.find(
            (x) =>
              x.componentName ===
              AppConstants.APPLICATIONSTEP.BUSINESS_OVERDRAFT
          ) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.BUSINESS_REVIEW.OVERDRAFT}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.BUSINESS_OVERDRAFT)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.BUSINESS_REVIEW.OVERDRAFT_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.businessOverdraft ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
          )}

          {processFlow?.find(
            (x) =>
              x.componentName === AppConstants.APPLICATIONSTEP.BUSINESS_CHEQUE
          ) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.BUSINESS_REVIEW.PRINTED_CHEQUES}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.BUSINESS_CHEQUE)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.BUSINESS_REVIEW.PRINTED_CHEQUES_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.businessCheque ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* BUSINESS DEBIT */}
          {processFlow?.find(
            (x) =>
              x.componentName === AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT
          ) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.BUSINESS_REVIEW.DEBIT_CARD}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.BUSINESS_DEBIT)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.BUSINESS_REVIEW.DEBIT_CARD_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.businessDebit ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* BUSINESS ONLINE */}
          {/*<div className="[ details-section ] [ mb-3 ]">*/}
          {/*  <div className="[ row ] [ details-section__header">*/}
          {/*    <div className="[ col-12 ]">*/}
          {/*      <h3 className="[ d-flex justify-content-between align-items-center ]">*/}
          {/*        {STRINGS.BUSINESS_REVIEW.ONLINE_BANKING}*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          className="[ btn edit-btn ]"*/}
          {/*          onClick={() => handleEditPage(ROUTES.BUSINESS_ONLINE)}*/}
          {/*          aria-label="edit"*/}
          {/*        >*/}
          {/*          <span className="[ edit-icon ]" />*/}
          {/*          <span className="[ edit-btn__label ]">*/}
          {/*            {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}*/}
          {/*          </span>*/}
          {/*        </button>*/}
          {/*      </h3>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div className="[ row ] [ details-section__item ]">*/}
          {/*    <div className="[ col-sm-6 ]">*/}
          {/*      <div className="[ details-section__item-label ]">*/}
          {/*        {STRINGS.BUSINESS_REVIEW.ONLINE_BANKING_LABEL}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="[ col-sm-6 ]">*/}
          {/*      <div className="[ details-section__item-description ]">*/}
          {/*        {businessOnlineBanking}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title={STRINGS.BUSINESS_CONFIRM.SUCCESS_POP_UP_TITLE}
            description={STRINGS.BUSINESS_CONFIRM.SUCCESS_POP_UP_DESCRIPTION}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessConfirm.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  activeProduct: PropTypes.any,
  finInfo: PropTypes.any,
};

BusinessConfirm.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  steps: [],
  activeStepID: -1,
  activeProduct: {},
  finInfo: {},
};

export default BusinessConfirm;
