import * as actionTypes from "Redux/Actions/actionTypes";

export const searchEsc = (payLoad, callback = () => {}) => {
  return {
    type: actionTypes.ESC_SEARCH,
    data: payLoad,
    callback,
  };
};

export const requestEsc = (payLoad, callback = () => {}) => {
  return {
    type: actionTypes.ESC_REQUEST,
    data: payLoad,
    callback,
  };
};

export const profileEsc = (payLoad, callback = () => {}) => {
  return {
    type: actionTypes.ESC_PROFILE,
    data: payLoad,
    callback,
  };
};

export const searchMember = (request, callback = () => {}) => ({
  type: actionTypes.SEARCH_MEMBER,
  data: request,
  callback,
});

export const kycInvite = (request, callback = () => {}) => ({
  type: actionTypes.KYC_INVITE,
  data: request,
  callback,
});
