import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import SecurityProperty from "./SecurityProperty";
import {
  getLoanLoanSecurity,
  updateLoanSecurity,
} from "../LoanDetails/Actions/LoanDetailsAction";
import {
  addLoanRealEstate,
  getLoanRealEstate,
  updateLoanRealEstate,
} from "../Asset/Actions/AssetActions";
import {
  doGetAddress,
  doGetProvinceList,
} from "../AboutYou/Actions/AboutYouActions";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";

class SecurityPropertyContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      property: {},
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      actionBack,
      handleBack,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (
      activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.AUTO ||
      (activeProduct.category === AppConstants.LOAN_CATEGORY.MORTGAGE &&
        activeProduct.subCategory ===
          AppConstants.LOAN_SUB_CATEGORY.MORTGAGE_PRE_QUAL)
    ) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
      } else {
        console.log("continue to next step");
        continuehandler(null, AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
      }
    } else {
      this.initialize(activeProduct);
    }
  }

  initialize(activeProduct) {
    const { doGetLoanRealEstate } = this.props;
    if (activeProduct.property?.securityId) {
      doGetLoanRealEstate(
        activeProduct.applicationId,
        activeProduct.property.securityId,
        (res) => {
          console.log(res);
          const property = {
            city: res.data.city,
            postalCode: res.data.postalCode,
            province: res.data.province,
            securityId: res.data.securityId,
            street: res.data.street,
            streetNumber: res.data.streetNumber,
            streetType: res.data.streetType,
            unitNumber: res.data.unitNumber,
            tenure: res.data.tenure,
          };
          this.setState({
            property,
          });
        }
      );
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      products,
      getActiveProduct,
      continuehandler,
      doAddLoanRealEstate,
      doUpdateLoanRealEstate,
      doUpdateProductToVault,
      doUpdateLoanSecurity,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (data.securityId) {
      doUpdateLoanRealEstate(
        activeProduct.applicationId,
        data.securityId,
        { ...data, propQualValue: activeProduct.loanDetails.loanAmount },
        (res) => {
          console.log(res);
          products[0]["property"] = data;
          doUpdateProductToVault(products, () => {
            continuehandler(
              null,
              AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS
            );
          });
        }
      );
    } else {
      doAddLoanRealEstate(
        activeProduct.applicationId,
        {
          ...data,
          applicationId: activeProduct.applicationId,
          propQualValue: activeProduct.loanDetails.loanAmount,
        },
        (res) => {
          console.log(res);
          doUpdateLoanSecurity(
            activeProduct.applicationId,
            activeProduct.doximProductId,
            {
              securityId: res.data.securityId,
              link: true,
              marginPercent: finInfo.marginPercent,
              balanceOfPriors: 0,
              usingSecuredAmountAsPercentage: true,
              securedPercent: finInfo.securedPercent,
              securedAmount: 0,
            },
            (updateSecurityRes) => {
              console.log(updateSecurityRes);
              products[0]["property"] = {
                ...data,
                securityId: res.data.securityId,
              };
              doUpdateProductToVault(products, () => {
                continuehandler(
                  null,
                  AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS
                );
              });
            }
          );
        }
      );
    }
  };

  render() {
    const { property } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      provinceList,
      streetTypes,
      doGetAddress,
      finInfo,
    } = this.props;

    return (
      <>
        <SecurityProperty
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          provinceList={provinceList}
          property={property}
          streetTypes={streetTypes}
          doGetAddress={doGetAddress}
          finInfo={finInfo}
        />
      </>
    );
  }
}

SecurityPropertyContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SecurityPropertyContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  finInfo: state.MarketplaceReducer.finInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLoanLoanSecurity: (applicationId, callback) =>
      dispatch(getLoanLoanSecurity(applicationId, callback)),
    doAddLoanRealEstate: (applicationId, payLoad, callback) =>
      dispatch(addLoanRealEstate(applicationId, payLoad, callback)),
    doGetLoanRealEstate: (applicationId, securityId, callback) =>
      dispatch(getLoanRealEstate(applicationId, securityId, callback)),
    doUpdateLoanRealEstate: (applicationId, securityId, payLoad, callback) =>
      dispatch(
        updateLoanRealEstate(applicationId, securityId, payLoad, callback)
      ),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doUpdateLoanSecurity: (
      applicationId,
      lendingProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLoanSecurity(applicationId, lendingProductId, payLoad, callback)
      ),
    doGetAddress: (params, callback) =>
      dispatch(doGetAddress(params, callback)),
  };
};

const SecurityPropertyWrapper = withApplicantHOC(SecurityPropertyContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityPropertyWrapper);
