import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import ConfirmApplicantCreateRequest from "Communication/ApiClasses/ConfirmApplicantCreateRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleApplicantCreateConfirm(action) {
  console.log(action);
  yield put(
    doLoadingStart(
      STRINGS.JOIN_APPLICANT.API_KEYS.APPLICANT_CREATE_CONFIRM,
      action.data.waitMessage
    )
  );
  const request = action.data;
  delete request.waitMessage;
  const confirmApplicantCreateRequest = new ConfirmApplicantCreateRequest(
    request
  );
  try {
    const result = yield call(confirmApplicantCreateRequest.getData);
    yield put({
      type: actionTypes.APPLICANT_CREATE_CONFIRM_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.APPLICANT_CREATE_CONFIRM_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.APPLICANT_CREATE_CONFIRM)
    );
  }
}

// watcher
function* applicantCreateConfirmWatcherSaga() {
  yield takeLatest(
    actionTypes.APPLICANT_CREATE_CONFIRM,
    handleApplicantCreateConfirm
  );
}

export default applicantCreateConfirmWatcherSaga;
