import BaseApiRequest from "./BaseApiRequest";
import {
  GET_APPLICANT_DETAILS,
  GET_COMPLETE_APPLICANT_DETAILS,
} from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetCompleteApplicantRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  url = GET_COMPLETE_APPLICANT_DETAILS;

  constructor(id) {
    super();
    this.url = this.url.concat(id);
    this.url = `${this.url}?t=${new Date().getTime()}`;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
