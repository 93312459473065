import React, { Component } from "react";
import PropTypes from "prop-types";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import checkErrors from "Utils/InputValidator";
import { Modal } from "react-bootstrap";
import {
  InstntImageProcessor,
  InstntSelfieProcessor,
} from "@instnt/instnt-react-js";

class Selfie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanError: "",
      capture: null,
      capturedImage: null,
      showHelp: false,
    };
  }

  componentDidMount() {
    // this.setState({ capture: window.IDMetricsCaptureFramework });
  }

  scanSelfie = () => {
    const { doScan } = this.props;
    doScan(true);
    // const { capture } = this.state;
    // const captureResult = new window.IDMetricsCaptureFramework.CaptureResult();
    // const docSettings = new window.IDMetricsCaptureFramework.DocumentSettings();
    // docSettings.captureMode = STRINGS.ABOUTYOU.COMMONPART.CAPTUREMODE;
    // capture.scanSelfie(docSettings, captureResult);
    // captureResult.setOnFinish(() => this.scanResult(captureResult));
    // captureResult.setOnEvent(() => {
    //   captureResult.continue();
    // });
  };

  scanResult = (captureResult) => {
    this.setState({ capturedImage: captureResult.result, scanError: "" });
  };

  submitData = (imgSrc) => {
    if (this.verifyData(imgSrc)) {
      const { selfieHandler } = this.props;
      selfieHandler(process.env.REACT_APP_TEST ? "FAKE_IMG" : imgSrc);
    }
  };

  verifyData = (imgSrc) => {
    const imageSource = imgSrc || "";
    const idErrorsList = checkErrors(imageSource, VALIDATIONS.ABOUTYOU.SELFIE);
    if (
      idErrorsList &&
      idErrorsList.length > 0 &&
      !process.env.REACT_APP_TEST
    ) {
      this.setState({
        scanError: idErrorsList,
      });
      return false;
    }
    return true;
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = () => {
    this.setState({
      showHelp: true,
    });
  };

  render() {
    const { scanError, showHelp } = this.state;
    const { selfie, idType, doCapture, capturedImage } = this.props;
    let scanErrorDOM = null;
    if (scanError) {
      scanErrorDOM = (
        <div className="[ scan-error__card ]">
          {scanError.map((error) => (
            <div className="[ error-msg ]" key="error-msg">
              {error}
            </div>
          ))}
          <div className="[ row ] [ info-row ]" />
          <div className="[ row ] [ info-row ] " />
        </div>
      );
    }

    let imageDiv = (
      <div
        className="[ scan-id__card ]"
        onClick={this.scanSelfie}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <div className="[ flex-row justify-content-center text-center ]">
          <i className="[ fas fa-portrait ]" />
        </div>
        <div className="[ scan-id__text] ">{STRINGS.ABOUTYOU.CLICKTOBEGIN}</div>
      </div>
    );
    let imgSrc = null;
    if (selfie) {
      imgSrc = selfie;
    }
    if (capturedImage) {
      imgSrc = capturedImage;
    }
    if (imgSrc) {
      imageDiv = (
        <div className="[ scan-id__card ]">
          <img className="[ scan-id__card__img ]" src={imgSrc} alt="selfie" />
        </div>
      );
    }

    const selfieSettings = {
      enableFarSelfie: true,
      selfieCaptureAttempt: 4,
      captureMode: "Auto",
      compressionType: "JPEG",
      compressionQuality: "50",
      useBackCamera: false,
      overlayText: "Align Face and Tap button</br> to Capture.",
      overlayTextAuto: "Align Face and Hold",
      overlayColor: "#808080",
      orientationErrorText:
        "Landscape orientation is not supported. Kindly rotate your device to Portrait orientation.",
      enableFaceDetection: true,
      setManualTimeout: 8,
      enableLocationDetection: false,
    };

    return (
      <>
        <div className="[ form-container ]">
          {doCapture && (
            <InstntSelfieProcessor
              documentType={idType}
              selfieSettings={selfieSettings}
            />
          )}
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTFOUR.SUBTITILE}
          </div>
          <div className="[ form-description ]">
            <p>
              <strong>{STRINGS.ABOUTYOU.PARTFOUR.ABOUTYOUINFO}</strong>
            </p>
            <p>
              <a
                className="[ link ]"
                href="#"
                role="button"
                onClick={() => this.handleOpenHelp()}
              >
                {STRINGS.ABOUTYOU.PARTTWO.ABOUTYOUINFO1}
              </a>
            </p>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ] [ d-flex ] [ justify-content-center ]">
              <div className="[ col-12 ]">{imageDiv}</div>
              <div className="[ col-12 ]">{scanErrorDOM}</div>
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <button
                  type="button"
                  className="[ btn ] [ retake ]"
                  onClick={this.scanSelfie}
                  disabled={capturedImage === null}
                >
                  {STRINGS.ABOUTYOU.RETAKE}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={() => this.submitData(imgSrc)}
              >
                <span className="[ d-md-none d-block ]">
                  {STRINGS.COMMON.CONTINUEBTN}
                </span>
                <span className="[ d-none d-md-block ]">
                  {STRINGS.ABOUTYOU.UPLOADANDCONTINUE}
                </span>
              </button>
            </div>
          </div>
        </div>
        <Modal show={showHelp} onHide={this.handleCloseHelp}>
          <Modal.Header
            className="[ popup-modal__header ]"
            closeButton={this.handleCloseHelp}
          >
            <Modal.Title>{STRINGS.ABOUTYOU.PARTTHREE.POPUPTITLE}</Modal.Title>
          </Modal.Header>

          <Modal.Body className="[ overflow-auto ] [ popup-modal__body ]">
            <div className="[ row ]">
              <div className="[ col-12 ] [ face-scan-window-bg ]">
                <div className="[ face-container ]">
                  <div className=" [ face-scan-window ]"></div>
                  <div className=" [ face-box ]"></div>
                  <span className="[ fas fa-user-alt ]"></span>
                </div>
              </div>
              <div className="[ col-12 ]">
                <ul className="[ id-scan-tips ]">
                  <li>{STRINGS.ABOUTYOU.PARTTHREE.POPUPINFO}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTHREE.POPUPINFO1}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTHREE.POPUPINFO2}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTHREE.POPUPINFO3}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTHREE.POPUPINFO4}</li>
                </ul>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

Selfie.propTypes = {
  selfieHandler: PropTypes.func,
  selfie: PropTypes.string,
};

Selfie.defaultProps = {
  selfieHandler: () => {},
  selfie: null,
};

export default Selfie;
