import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";
import AccountInformation from "./AccountInformation";
import getIntendedUseList, {
  getApplicantBankingProductList,
} from "./Actions/AccountInformationAction";
import { findByDescription } from "../../Utils/CommonUtilities";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";

class AccountInformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productIntendedUseList: [],
      activeProduct: {},
      bankingProductList: [],
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { doGetIntendedUseList, intendedUseList, finInfo } = this.props;
    if (intendedUseList.length === 0) {
      doGetIntendedUseList();
    } else {
      this.getProductIntendedUseList();
    }
    if (finInfo.existingContractSearch) {
      this.getBankingProductList();
    }
  }

  componentDidUpdate(prevProps) {
    const { intendedUseList, finInfo } = this.props;
    if (intendedUseList !== prevProps.intendedUseList) {
      this.getProductIntendedUseList();
    }
    if (prevProps.finInfo !== finInfo && finInfo.existingContractSearch) {
      this.getBankingProductList();
    }
  }

  getBankingProductList = () => {
    const {
      vaultProductList,
      getActiveProduct,
      doGetApplicantBankingProductList,
      doUpdateProductToVault,
    } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);
    doGetApplicantBankingProductList(activeProduct.applicantId, (response) => {
      console.log("banking", response);
      this.setState({
        bankingProductList: response.products ? response.products : [],
      });
      const rtmtContract = response.products?.find(
        (item) =>
          item.productDescription.startsWith("RTMT") &&
          activeProduct.productCategory.indexOf(item.productCode) > -1
      );
      if (rtmtContract) {
        vaultProductList[0].hasExistingContract = true;
        doUpdateProductToVault(vaultProductList);
      }
    });
  };

  getProductIntendedUseList() {
    const { intendedUseList, vaultProductList, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);
    // search first if there is custom list based on productId
    let productIntendedUseList = intendedUseList.filter(
      (item) =>
        item.productType === activeProduct.type &&
        item.productId &&
        item.productId === activeProduct.productId
    );
    if (productIntendedUseList.length === 0) {
      productIntendedUseList = intendedUseList.filter(
        (item) => item.productType === activeProduct.type && !item.productId
      );
    }
    this.setState({
      productIntendedUseList,
      activeProduct,
    });
  }

  /**
   * Send the form data to server through API call
   */
  handleAccountInformation = (formdata) => {
    const {
      continuehandler,
      vaultProductList,
      getActiveProduct,
      finInfo,
    } = this.props;
    const { productIntendedUseList } = this.state;
    console.log("handleAccountInformation", formdata);
    const activeProduct = getActiveProduct(vaultProductList);
    // Create request body
    const requestBody = { udfList: { ...formdata.origUdfList } };

    if (activeProduct.defaultFundSource) {
      if (requestBody.udfList) {
        requestBody.udfList.udf2 = activeProduct.defaultFundSource;
      } else {
        requestBody.udfList = {
          udf2: activeProduct.defaultFundSource,
        };
      }
    }
    if (activeProduct.intendedUse) {
      requestBody.udfList[activeProduct.intendedUse] = formdata.intendedUse;
    } else {
      requestBody.intendedUse = formdata.intendedUse;
    }

    if (finInfo.intendedUseOtherFlag) {
      if (
        findByDescription(formdata.intendedUse, productIntendedUseList)
          ?.code === "Other"
      ) {
        requestBody.udfList[finInfo.intendedUseOtherFlag] = formdata.otherInfo;
      } else {
        requestBody.udfList[finInfo.intendedUseOtherFlag] = "";
      }
    }

    if (activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT) {
      requestBody.demandProductUDFDetails = {
        ...requestBody.udfList,
      };
    }

    if (formdata.contractNumber) {
      requestBody.bankingContractNo = formdata.contractNumber;
    }

    // Call common function from ApplicantHOC to send the data to server
    continuehandler(
      requestBody,
      AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION
    );
  };

  render() {
    const {
      steps,
      activeStepID,
      getDataFromServer,
      handleBack,
      finInfo,
    } = this.props;
    const {
      productIntendedUseList,
      activeProduct,
      bankingProductList,
    } = this.state;
    return (
      <AccountInformation
        steps={steps}
        handleBack={() =>
          handleBack(AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION)
        }
        activeStepID={activeStepID}
        getDataFromServer={getDataFromServer}
        intendedUseList={productIntendedUseList}
        handleAccountInformation={this.handleAccountInformation}
        activeProduct={activeProduct}
        bankingProductList={bankingProductList}
        finInfo={finInfo}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doGetIntendedUseList: () => dispatch(getIntendedUseList()),
    doGetApplicantBankingProductList: (applicantId, callback) =>
      dispatch(getApplicantBankingProductList(applicantId, callback)),
    doUpdateProductToVault: (vaultList, callback) =>
      dispatch(updateProductToVault(vaultList, callback)),
  };
};

const mapStateToProps = (state) => ({
  intendedUseList: state.AccountInformationReducer.intendedUseList,
  vaultProductList: state.VaultReducer.vaultProductList,
  finInfo: state.MarketplaceReducer.finInfo,
});

AccountInformationContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doGetIntendedUseList: PropTypes.func.isRequired,
  intendedUseList: PropTypes.arrayOf(PropTypes.object),
  vaultProductList: PropTypes.arrayOf(PropTypes.object).isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  continuehandler: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  doGetApplicantBankingProductList: PropTypes.func.isRequired,
  finInfo: PropTypes.objectOf(PropTypes.any),
};

AccountInformationContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  intendedUseList: [],
  finInfo: {},
};

const AccountInformationWrapper = withApplicantHOC(AccountInformationContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformationWrapper);
