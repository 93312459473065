import FIInformation from "Constants/FIInformation";

export const Title = `${FIInformation.FI_APPLICATION_NAME} - ${FIInformation.FI_NAME}`;
export const SEOConfig = [
  {
    name: "description",
    content:
      "Mainstreet Credit Union is a financial cooperative offering personal, business, and ag banking, mortgages, loans, and investing services in southwestern ON.",
  },
  {
    name: "robots",
    content: "index, follow",
  },
  {
    name: "viewport",
    content: "width=device-width, initial-scale=1",
  },
];
