import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import DeleteApplicationSlot from "Communication/ApiClasses/DeleteApplicationSlot";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handlDeleteApplicationSlot(action) {
  console.log(action);
  yield put(
    doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.DELETE_APPLICATION_SLOT)
  );
  const deleteApplicationSlot = new DeleteApplicationSlot(action.data);
  try {
    const result = yield call(deleteApplicationSlot.getData);
    yield put({
      type: actionTypes.DELETE_APPLICATION_SLOT_SUCCESS,
      response: result,
      formData: { ...action.requestData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_APPLICATION_SLOT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.DELETE_APPLICATION_SLOT)
    );
  }
}

// watcher
function* deleteApplicationSlotWatcherSaga() {
  yield takeEvery(
    actionTypes.DELETE_APPLICATION_SLOT,
    handlDeleteApplicationSlot
  );
}

export default deleteApplicationSlotWatcherSaga;
