import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { ESC_SEARCH_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class SearchEscRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {};

  url = ESC_SEARCH_URL;

  constructor(data) {
    super();
    this.body = data;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
