import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import {
  getProductFromVault,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { getApplicationList } from "Redux/Actions/ApplicationAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import {
  createEsignDoc,
  sendEsignPackage,
  getEsign,
} from "./Actions/SignatureAction";
import Signature from "./Signature";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";
import { S_BANKING_TYPE_SWITCH } from "../../Communication/Constants";

class SignatureContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showPage1: false,
      showPage2: false,
      showPage3: false,
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      signatureCompleted: false,
      activeProduct: {},
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      history,
      doGetApplicationList,
      doCreateEsignDoc,
      doGetEsign,
      doGetApplicationProperty,
      inReview,
      uploadedAt,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    this.setState({
      activeProduct,
    });

    if (!activeProduct) {
      history.push(ROUTES.VAULT_DASHBOARD);
    } else if (inReview || uploadedAt) {
      continuehandler("", AppConstants.APPLICATIONSTEP.SIGNATURE);
    } else {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (currentApplication) => {
          console.log("current", currentApplication);
          const slotsApplied = currentApplication.slots.filter(
            (slot) => slot.appliedAt
          );
          if (currentApplication.inReview || currentApplication.uploadedAt) {
            continuehandler("", AppConstants.APPLICATIONSTEP.SIGNATURE);
          } else if (
            !currentApplication.appliedAt &&
            currentApplication.slots.length != slotsApplied.length
          ) {
            history.push(ROUTES.CONFIRM_ACCOUNT_CREATE);
          } else if (currentApplication.esignPackageSentAt) {
            this.setState({
              showPage1: false,
              showPage2: true,
              showPage3: false,
            });
            activeProduct.contract.forEach((contract) => {
              console.log("contract", contract);
              const signer = contract.signers.find(
                (contractSigner) =>
                  contractSigner.applicantId === activeProduct.applicantId
              );
              if (signer) {
                console.log("opening tab", signer.url);
                setTimeout(() => {
                  const a = document.createElement("a");
                  a.setAttribute("href", signer.url);
                  a.setAttribute("target", "_blank");
                  a.click();
                }, 5000);
              }
            });
          } else if (currentApplication.esignDocStartAt) {
            this.setState({
              showPage1: true,
              showPage2: false,
              showPage3: false,
            });
          } else {
            doCreateEsignDoc(activeProduct.applicationId, (createResponse) => {
              console.log(createResponse);
              this.setState({
                showPage1: true,
                showPage2: false,
                showPage3: false,
              });
            });
          }
        }
      );
    }
  }

  toggleModal = () => {};

  showModal = () => {
    this.setState({});
  };

  handlePopupModalBtnClick = () => {
    const { signatureCompleted } = this.state;
    const { continuehandler } = this.props;
    if (signatureCompleted) {
      continuehandler({}, AppConstants.APPLICATIONSTEP.SIGNATURE);
    }
  };

  handleContinue = (choice) => {
    const {
      continuehandler,
      doCreateEsignDoc,
      products,
      getActiveProduct,
      doSendEsignPackage,
      doGetEsign,
      history,
      doUpdateProductToVault,
      applicantData,
      doGetApplicationProperty,
    } = this.props;
    const { showPage1, showPage2, showPage3 } = this.state;
    const dispatchObject = {
      agree: choice,
    };
    const activeProduct = getActiveProduct(products);
    const bankingTypeSwitch = sessionStorage.getItem(S_BANKING_TYPE_SWITCH);

    doGetApplicationProperty(
      { applicationId: activeProduct.applicationId },
      (currentApplication) => {
        console.log("current", currentApplication);
        if (currentApplication.inReview || currentApplication.uploadedAt) {
          continuehandler(
            { showInReview: true },
            AppConstants.APPLICATIONSTEP.SIGNATURE
          );
        } else if (showPage1) {
          doSendEsignPackage(activeProduct.applicationId, (res) => {
            console.log("esign package", res);
            if (res.data.inReview || res.data.uploadedAt) {
              continuehandler("", AppConstants.APPLICATIONSTEP.SIGNATURE);
            } else {
              products[0].contract = res.data;
              doUpdateProductToVault(products);
              this.setState({
                showPage1: false,
                showPage2: true,
                showPage3: false,
              });
              res.data.forEach((contract) => {
                console.log("contract", contract);
                const signer = contract.signers.find(
                  (contractSigner) =>
                    contractSigner.applicantId === activeProduct.applicantId
                );
                if (signer) {
                  console.log("opening tab", signer.url);
                  setTimeout(() => {
                    const a = document.createElement("a");
                    a.setAttribute("href", signer.url);
                    a.setAttribute("target", "_blank");
                    a.click();
                  }, 5000);
                }
              });
            }
          });
        } else if (showPage2) {
          // We have sent the package
          doGetEsign(activeProduct.applicationId, (eSignInfo) => {
            console.log(eSignInfo);
            if (eSignInfo.data.length > 0) {
              const incompleteDoc = eSignInfo.data.find(
                (doc) => !doc.completedAt
              );
              if (incompleteDoc) {
                this.setState({
                  showPage1: false,
                  showPage2: false,
                  showPage3: true,
                });
              } else {
                this.setState({
                  signatureCompleted: true,
                });
                if (activeProduct.productType === "Loan") {
                  this.setState({
                    showModal: true,
                    modalType: AppConstants.MODALTYPE.SUCCESS,
                    modal: {
                      title: MODALMESSAGES.SIGNATURE.SUCCESS_TITLE.split(
                        AppConstants.TEMPLATE.NAME
                      ).join(applicantData.member.name),
                      description: MODALMESSAGES.SIGNATURE.LOS_CONGRATS_MESSAGE,
                      description2: bankingTypeSwitch
                        ? MODALMESSAGES.SIGNATURE.BUSINESS_MESSAGE
                        : null,
                    },
                  });
                } else {
                  this.setState({
                    showModal: true,
                    modalType: AppConstants.MODALTYPE.SUCCESS,
                    modal: {
                      title: MODALMESSAGES.SIGNATURE.SUCCESS_TITLE.split(
                        AppConstants.TEMPLATE.NAME
                      ).join(applicantData.member.name),
                      description: MODALMESSAGES.SIGNATURE.AOS_CONGRATS_MESSAGE,
                      description2: bankingTypeSwitch
                        ? MODALMESSAGES.SIGNATURE.BUSINESS_MESSAGE
                        : null,
                    },
                  });
                }
                sessionStorage.removeItem(S_BANKING_TYPE_SWITCH);
              }
            } else {
              this.setState({
                showPage1: false,
                showPage2: false,
                showPage3: true,
              });
            }
          });
        } else if (showPage3) {
          doGetEsign(activeProduct.applicationId, (eSignInfo) => {
            console.log(eSignInfo);
            if (eSignInfo.data.length > 0) {
              const incompleteDoc = eSignInfo.data.find(
                (doc) => !doc.completedAt
              );
              if (incompleteDoc) {
                this.setState({
                  showPage1: false,
                  showPage2: false,
                  showPage3: true,
                });
              } else {
                this.setState({
                  signatureCompleted: true,
                });
                if (activeProduct.productType === "Loan") {
                  this.setState({
                    showModal: true,
                    modalType: AppConstants.MODALTYPE.SUCCESS,
                    modal: {
                      title: MODALMESSAGES.DISBURSEMENT.CONGRATS_TITLE.split(
                        AppConstants.TEMPLATE.NAME
                      ).join(applicantData.member.name),
                      description: MODALMESSAGES.DISBURSEMENT.CONGRATS_MESSAGE.split(
                        AppConstants.TEMPLATE.NAME
                      ).join(applicantData.member.name),
                      description2: bankingTypeSwitch
                        ? MODALMESSAGES.SIGNATURE.BUSINESS_MESSAGE
                        : null,
                    },
                  });
                } else {
                  this.setState({
                    showModal: true,
                    modalType: AppConstants.MODALTYPE.SUCCESS,
                    modal: {
                      title: MODALMESSAGES.SIGNATURE.SUCCESS_TITLE.split(
                        AppConstants.TEMPLATE.NAME
                      ).join(applicantData.member.name),
                      description: MODALMESSAGES.SIGNATURE.SUCCESS_MSG.split(
                        AppConstants.TEMPLATE.NAME
                      ).join(applicantData.member.name),
                      description2: bankingTypeSwitch
                        ? MODALMESSAGES.SIGNATURE.BUSINESS_MESSAGE
                        : null,
                    },
                  });
                }
                sessionStorage.removeItem(S_BANKING_TYPE_SWITCH);
              }
            } else {
              this.setState({
                showPage1: false,
                showPage2: false,
                showPage3: true,
              });
            }
          });
        }
      }
    );
  };

  render() {
    const {
      modalType,
      modal,
      showModal,
      showPage1,
      showPage2,
      showPage3,
      choice,
      activeProduct,
    } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <Signature
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          choice={choice}
          steps={steps}
          activeStepID={activeStepID}
          showPage1={showPage1}
          showPage2={showPage2}
          showPage3={showPage3}
          activeProduct={activeProduct}
        />
      </>
    );
  }
}

SignatureContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  getActiveProduct: PropTypes.func.isRequired,
  applicationList: PropTypes.arrayOf(PropTypes.any).isRequired,
  doGetApplicationList: PropTypes.func.isRequired,
  doCreateEsignDoc: PropTypes.func.isRequired,
  doGetEsign: PropTypes.func.isRequired,
  doUpdateProductToVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
};

SignatureContainer.defaultProps = {
  history: null,
  products: [],
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicationList: state.ApplicationReducer.applicationList,
  inReview: state.ApplicationReducer.inReview,
  uploadedAt: state.ApplicationReducer.uploadedAt,
  applicantData: state.ApplicationReducer.response,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doCreateEsignDoc: (applicationId, callback) =>
      dispatch(createEsignDoc(applicationId, callback)),
    doSendEsignPackage: (applicationId, callback) =>
      dispatch(sendEsignPackage(applicationId, callback)),
    doGetEsign: (applicationId, callback) =>
      dispatch(getEsign(applicationId, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
  };
};

const SignatureWrapper = withApplicantHOC(SignatureContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SignatureWrapper);
