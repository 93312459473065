import { call, put, takeLatest } from "redux-saga/effects";
import EnableOnlineBanking from "Communication/ApiClasses/EnableOnlineBankingRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import { GET_DEBIT_CARD_NUMBER } from "../../../Redux/Actions/actionTypes";
import GetDebitCardNumberRequest from "../../../Communication/ApiClasses/GetDebitCardNumberRequest";

// worker
function* handleGetDebitCardNumberSaga(action) {
  yield put(doLoadingStart(`${GET_DEBIT_CARD_NUMBER}_SAGA`, 10000));
  const getDebitCardNumberRequest = new GetDebitCardNumberRequest(
    action.data.applicantId,
    action.data.applicationId,
    action.data.debitCardProductId,
    action.data.password
  );
  try {
    const result = yield call(getDebitCardNumberRequest.getData);
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${GET_DEBIT_CARD_NUMBER}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${GET_DEBIT_CARD_NUMBER}_SAGA`));
  }
}

// watcher
function* getDebitCardNumberSaga() {
  yield takeLatest(GET_DEBIT_CARD_NUMBER, handleGetDebitCardNumberSaga);
}

export default getDebitCardNumberSaga;
