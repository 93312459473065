import BaseApiRequest from "./BaseApiRequest";
import { METHOD } from "../Constants";
import { SIGNIN_URL } from "../ApiUrls";

export default class SigninRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  constructor(userDetail) {
    super();
    this.body = userDetail;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return SIGNIN_URL;
  };

  getBody = () => {
    return this.body;
  };
}
