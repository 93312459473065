import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessInfo from "./BusinessInfo";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { fetchJsonData } from "../TermsAndConditions/Actions/TermsActions";

class BusinessInfoContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      jsonData: null,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { fetchJsonDatas } = this.props;
    fetchJsonDatas((res) => {
      console.log(res);
      this.setState({
        jsonData: res.data,
      });
    });
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const { continuehandler } = this.props;
    continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_INFO);
  };

  render() {
    const { linkTermsArray, checkboxArray, choice, jsonData } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      finInfo,
      activeStepID,
      products,
      getActiveProduct,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessInfo
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          choice={choice}
          jsonData={jsonData}
          linkTermsArray={linkTermsArray}
          checkboxArray={checkboxArray}
          activeStepID={activeStepID}
          finInfo={finInfo}
          activeProduct={activeProduct}
        />
      </>
    );
  }
}

BusinessInfoContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessInfoContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    fetchJsonDatas: (callback) => dispatch(fetchJsonData(callback)),
  };
};

const BusinessInfoWrapper = withBusinessHOC(BusinessInfoContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessInfoWrapper);
