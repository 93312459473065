import BaseApiRequest from "./BaseApiRequest";
import { CONFIRM_APPLY_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class ConfirmAccountApplyRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = { applicationId: "" };

  constructor(userDetail) {
    super();
    this.body.applicationId = userDetail.applicationId;
    if (userDetail.applicantId) {
      this.body.applicantId = userDetail.applicantId;
    }
    this.body.isThirdPartyAccount = userDetail.isThirdPartyAccount;
    this.body.thirdPartyName = userDetail.thirdPartyName;
    this.body.thirdPartyRelationship = userDetail.thirdPartyRelationship;
    this.body.isDesktop = userDetail.isDesktop;
    this.body.doKyc = userDetail.doKyc;
    this.body.pepAdded = userDetail.pepAdded;
    this.body.pepDeleted = userDetail.pepDeleted;
    this.body.pepUpdated = userDetail.pepUpdated;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return CONFIRM_APPLY_URL;
  };

  getBody = () => {
    return this.body;
  };
}
