import * as actionTypes from "../../../Redux/Actions/actionTypes";

export const doGetPasswordCriteria = () => {
  return {
    type: actionTypes.GET_PASSWORD_CRITERIA_REQUEST,
    data: null,
  };
};

export const enableOnlineBanking = (
  applicantId,
  applicationId,
  debitCardProductId,
  password,
  callback = () => {}
) => ({
  type: actionTypes.ENABLE_ONLINE_BANKING,
  data: {
    applicantId,
    applicationId,
    debitCardProductId,
    password,
  },
  callback,
});

export const getDebitCardNumber = (
  applicantId,
  applicationId,
  debitCardProductId,
  callback = () => {}
) => ({
  type: actionTypes.GET_DEBIT_CARD_NUMBER,
  data: {
    applicantId,
    applicationId,
    debitCardProductId,
  },
  callback,
});

export const setOnlineBanking = (
  applicationId,
  debitCardProductId,
  templateId,
  callback = () => {}
) => ({
  type: actionTypes.SET_ONLINE_BANKING,
  data: {
    applicationId,
    debitCardProductId,
    templateId,
  },
  callback,
});

export const uploadOnlineBanking = (
  applicantId,
  applicationId,
  onlineBankingProductId,
  password,
  callback = () => {}
) => ({
  type: actionTypes.UPLOAD_ONLINE_BANKING,
  data: {
    applicantId,
    applicationId,
    onlineBankingProductId,
    password,
  },
  callback,
});
