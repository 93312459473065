import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import NumberFormat from "react-number-format";
import Alert from "react-bootstrap/Alert";
// eslint-disable-next-line spaced-comment
/*import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../Constants/ValidationList";*/
import { QuestionCircle } from "react-bootstrap-icons";
import STRINGS from "../../Constants/Strings";
import AppConstants from "../../Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import PopupModal from "../Common/PopupModal/PopupModal";
import Stepper from "../Common/Stepper/Stepper";

class JointApplicant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicants: [],
      isAddApplicant: false,
      isApplicantPresent: false,
      appCnt: 3,
      applicationId: "",
      applicantId: "",
      jointAppVaultProducts: [],
      emailError: [],
      emailConfirmError: [],
      mobileError: [],
      typeError: [],
      formIsValid: true,
      ownershipError: [],
      survivorError: [],
      closeAlert: false,
      showHelp: false,
      membershipError: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.JOINT_APPLICANT,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps) {
    const {
      products,
      jointAccounts,
      jointInSession,
      getActiveProduct,
      addJointMembers,
      jointMembershipSelected,
    } = this.props;
    if (prevProps.jointAccounts !== jointAccounts) {
      this.updateFormData(jointAccounts, jointInSession);
    }

    if (
      addJointMembers &&
      jointMembershipSelected &&
      !prevProps.addJointMembers
    ) {
      this.handleAddApplicantBtn(true);
    }

    if (prevProps.products !== products) {
      if (products.length > 0) {
        const activeProduct = getActiveProduct(products);
        this.state.applicationId = activeProduct.applicationId;
        this.state.applicantId = activeProduct.applicantId;

        this.state.jointAppVaultProducts = [
          {
            ...activeProduct,
            applicantId: null,
            applicationId: null,
            applicationStep: {
              index: null,
              step: "",
            },
            productId: activeProduct.productId,
            productIndex: activeProduct.productIndex,
            productName: activeProduct.productName,
            templateId: activeProduct.templateId,
            type: activeProduct.type,
            isJointProduct: true,
          },
        ];
      }
    }
  }

  setFormData = () => {};

  handleAddApplicantBtn = (choice) => {
    const { applicants, isApplicantPresent } = this.state;
    const {
      handleChange,
      finInfo,
      ownershipList,
      toggleJointSelection,
      addJointMembers,
    } = this.props;
    if (finInfo.jointMembership && !addJointMembers) {
      toggleJointSelection(choice);
      return;
    }
    console.log("adding joint");
    this.setState({ isAddApplicant: choice });
    if (choice) {
      if (applicants.length === 0) {
        this.setState({
          applicants: [
            {
              emailAddress: "",
              emailConfirm: "",
              mobilePhone: "",
              applicantType: "",
              signer: !finInfo.showJointSignatory,
            },
          ],
          isApplicantPresent,
          emailError: [],
          emailConfirmError: [],
          mobileError: [],
          typeError: [],
          ownershipError: [],
          survivorError: [],
          formIsValid: true,
        });
        handleChange({
          currentTarget: {
            name: "survivor",
            value: "",
          },
        });
        if (ownershipList.length > 0) {
          handleChange({
            currentTarget: {
              name: "ownership",
              value: "",
            },
          });
        }
      }
    } else {
      this.setState({ applicants: [], isApplicantPresent: false });
    }
  };

  handleApplicantPresentBtn = (choice) => {
    this.setState({ isApplicantPresent: choice });
  };

  validateForm = (applicantsOnly) => {
    let valid = true;
    const { applicants } = this.state;
    console.log("validateForm", applicants);
    const {
      ownership,
      survivor,
      ownershipList,
      survivorList,
      account,
    } = this.props;
    this.setState({
      emailError: [],
      emailConfirmError: [],
      mobileError: [],
      typeError: [],
      formIsValid: true,
      ownershipError: [],
      survivorError: [],
    });

    if (!applicantsOnly) {
      this.setState({ closeAlert: false });
    }

    const emailError = [];
    const emailConfirmError = [];
    const mobileError = [];
    const typeError = [];
    applicants.forEach((raw, idx) => {
      let error = checkErrors(
        raw.emailAddress,
        VALIDATIONS.JOINT_APPLICANT.EMAIL
      );
      if (error.length) {
        valid = false;
        console.log(error);
        emailError[idx] = error[0];
        this.setState({ emailError });
      } else {
        const emails = applicants.filter(
          (joint) => joint.emailAddress === raw.emailAddress
        );
        if (emails.length > 1) {
          valid = false;
          emailError[idx] = STRINGS.JOINT_APPLICANT.ERROR.EMAIL_DUPLICATE;
          this.setState({
            emailError,
          });
        } else if (account.email === raw.emailAddress) {
          valid = false;
          emailError[idx] = STRINGS.JOINT_APPLICANT.ERROR.INVALID_EMAIL;
          this.setState({
            emailError,
          });
        } else {
          error = checkErrors(
            raw.emailConfirm,
            VALIDATIONS.JOINT_APPLICANT.EMAIL
          );
          if (error.length) {
            valid = false;
            emailConfirmError[idx] = error[0];
            this.setState({ emailConfirmError });
          } else if (raw.emailAddress !== raw.emailConfirm) {
            valid = false;
            emailConfirmError[idx] =
              STRINGS.JOINT_APPLICANT.ERROR.EMAIL_DO_NOT_MATCH;
            this.setState({ emailConfirmError });
          }
        }
      }

      const phones = applicants.filter(
        (joint) => joint.mobilePhone === raw.mobilePhone
      );
      if (phones.length > 1) {
        valid = false;
        mobileError[idx] = STRINGS.JOINT_APPLICANT.ERROR.PHONE_DUPLICATE;
        this.setState({
          mobileError,
        });
      } else {
        error = checkErrors(
          raw.mobilePhone,
          VALIDATIONS.JOINT_APPLICANT.MOBILE
        );
        if (error.length) {
          valid = false;
          mobileError[idx] = error[0];
          this.setState({ mobileError });
        } else if (
          account.msisdn.includes(
            raw.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            )
          )
        ) {
          valid = false;
          mobileError[idx] = STRINGS.JOINT_APPLICANT.ERROR.INVALID_PHONE;
          this.setState({ mobileError });
        }
      }

      error = checkErrors(raw.applicantType, VALIDATIONS.JOINT_APPLICANT.TYPE);
      if (error.length) {
        valid = false;
        typeError[idx] = error[0];
        this.setState({ typeError });
      }

      if (!applicantsOnly) {
        if (ownershipList && ownershipList.length > 0) {
          error = checkErrors(ownership, VALIDATIONS.JOINT_APPLICANT.OWNERSHIP);
          if (error.length) {
            valid = false;
            this.setState({ ownershipError: error });
          }
        }

        if (survivorList && survivorList.length > 0) {
          error = checkErrors(
            survivor,
            VALIDATIONS.JOINT_APPLICANT.SURVIVORSHIP
          );
          if (error.length) {
            valid = false;
            this.setState({ survivorError: error });
          }
        }
      }
    });

    this.setState({
      formIsValid: valid,
    });

    return valid;
  };

  handleAddApplicant = () => {
    const { handleChange, finInfo, ownershipList } = this.props;
    if (this.validateForm(true)) {
      const app = {
        emailAddress: "",
        emailConfirm: "",
        mobilePhone: "",
        applicantType: "",
        signer: !finInfo.showJointSignatory,
      };
      const action = {
        type: "add",
        applicant: app,
      };
      this.updateApplicant(action);
      if (ownershipList.length > 0) {
        handleChange({
          currentTarget: {
            name: "ownership",
            value: "",
          },
        });
      }
    }
  };

  handleDeleteApplicant = (idx) => {
    const { handleChange, ownershipList } = this.props;
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateApplicant(action);
    const { applicants } = this.state;
    if (applicants.length === 0) {
      this.setState({
        isAddApplicant: false,
      });
    }
    if (ownershipList.length > 0) {
      handleChange({
        currentTarget: {
          name: "ownership",
          value: "",
        },
      });
    }
    this.validateForm();
  };

  clearChoice = () => {
    this.setState({ isAddApplicant: "", isApplicantPresent: "" });
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  handleChangeSignatory = (index, prop) => (event) => {
    console.log("handleChangeSignatory", index, prop, event);
    const { applicants } = this.state;
    console.log("applicants", applicants);
    applicants[index][prop] = event.target.checked;
    console.log("applicants", applicants);
    this.setState({ applicants });
  };

  handleChange = (index, prop) => (event) => {
    console.log("handleChange", index, prop, event.currentTarget.value);
    const {
      applicants,
      emailError,
      emailConfirmError,
      mobileError,
      typeError,
    } = this.state;
    console.log("applicants", applicants);
    applicants[index][prop] = event.currentTarget.value;
    if (prop === "emailAddress") {
      applicants[index][prop] = event.currentTarget.value.toLowerCase();
      emailError[index] = "";
      emailConfirmError[index] = "";
      this.setState({ emailError, emailConfirmError });
    }
    if (prop === "emailConfirm") {
      applicants[index][prop] = event.currentTarget.value.toLowerCase();
      emailConfirmError[index] = "";
      emailError[index] = "";
      this.setState({ emailConfirmError, emailError });
    }
    if (prop === "mobilePhone") {
      mobileError[index] = "";
      this.setState({ mobileError });
    }
    if (prop === "applicantType") {
      typeError[index] = "";
      this.setState({ typeError });
    }
    console.log("applicants", applicants);
    this.setState({ applicants });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const {} = this.state;

    if (this.validateForm()) {
      handleContinue(this.state);
    }
  };

  closeAlert = () => {
    this.setState({ closeAlert: true });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  updateFormData(jointAccounts, jointInSession) {
    console.log("updateFormData", jointAccounts, jointInSession);
    this.setState({
      isApplicantPresent: jointInSession,
    });
    if (jointAccounts.length > 0) {
      this.setState({
        isAddApplicant: true,
      });
    }
    const joints = [];
    jointAccounts.forEach((joint) => {
      joints.push({
        emailAddress: joint.extra.email,
        emailConfirm: joint.extra.email,
        mobilePhone: joint.extra.msisdn.substring(1),
        applicantType: joint.joinInfo.joinType,
        id: joint.id,
        inviteDetail: joint.inviteDetail,
        signer: joint.joinInfo.signer,
      });
    });
    this.setState({
      applicants: joints,
    });
  }

  updateApplicant(action) {
    const { applicants, appCnt } = this.state;
    if (action.type === "add") {
      if (applicants.length < appCnt) {
        applicants.push(action.applicant);
      }
    } else if (action.type === "remove") {
      if (action.index > -1) {
        applicants.splice(action.index, 1);
      }
    }
    this.setState({ applicants });
  }

  selectMembership = (event) => {
    const { selectMembership } = this.props;
    this.setState({ membershipError: [] });
    selectMembership(event);
  };

  render() {
    const {
      isAddApplicant,
      applicants,
      isApplicantPresent,
      emailError,
      emailConfirmError,
      mobileError,
      typeError,
      appCnt,
      ownershipError,
      survivorError,
      closeAlert,
      showHelp,
      helpText,
      membershipError,
    } = this.state;
    const {
      steps,
      activeStepID,
      applicantTypes,
      ownershipList,
      ownership,
      survivorList,
      survivor,
      handleChange,
      finInfo,
      ssoLogin,
      jointMembershipSelected,
      addJointMembers,
      enterJointMembers,
      membershipsData,
      dataIssue,
      membership,
      jointAccounts,
      products,
      getActiveProduct,
      contKycFlag,
    } = this.props;
    console.log(
      "applicants",
      jointMembershipSelected,
      addJointMembers,
      isAddApplicant,
      membership,
      enterJointMembers
    );

    const activeProduct = getActiveProduct(products);

    const membershipList = membershipsData?.map((mem) => (
      <option
        key={mem.Member}
        value={mem.Member}
        className="[ dropdown__option ]"
      >
        {mem.FirstName}--{mem.Branch}--{mem.Member}
      </option>
    ));

    const membershipComponent = (
      <div className="[ mb-3 ]">
        <div className="[ form-label ]">
          {STRINGS.MEMBERSHIP.FORM.SELECT_MEMBERSHIP}
        </div>
        <select
          className="[ form-control select__dropdown]"
          id="membership"
          name="membership"
          value={membership}
          onChange={(event) => {
            this.selectMembership(event);
          }}
        >
          <option value="" className="[ default ] [ dropdown__option ]">
            {STRINGS.MEMBERSHIP.FORM.SELECT_DEFAULT}
          </option>
          {membershipList}
          <option value="NEW" className="[ dropdown__option ]">
            {STRINGS.MEMBERSHIP.FORM.CREATE_NEW}
          </option>
        </select>
        <div className="[ error-msg ]">
          {membershipError && <>{membershipError}</>}
          {dataIssue && <>{dataIssue}</>}
        </div>
      </div>
    );

    const productTypesOptComp =
      applicantTypes &&
      applicantTypes.length > 0 &&
      applicantTypes.map((applicantType) => (
        <option
          key={applicantType.code}
          value={applicantType.code}
          className="[ dropdown__option ]"
        >
          {applicantType.description}
        </option>
      ));

    const addApplicantComp = applicants.map((applicant, index) => (
      <div key={index} className="[ drawer-content-container ] [ mb-3 ]">
        <div className="[ info-title ]">
          {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_NO} {index + 1}
          <button
            disabled={
              finInfo.jointMembership &&
              activeProduct?.applicationId &&
              index === 0
            }
            type="button"
            className={
              finInfo.jointMembership &&
              activeProduct?.applicationId &&
              index === 0
                ? "[ fas fa-trash disabled ]"
                : "[ fas fa-trash ]"
            }
            name="isAddApplicant"
            onClick={() => this.handleDeleteApplicant(index)}
          >
            <span className="[ sr-only ]">
              {STRINGS.JOINT_APPLICANT.FORM.REMOVE_COAPPLICANT}
            </span>
          </button>
        </div>

        <div className="[ row ]">
          <div className="[ col-12 col-lg-6 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.EMAILLABEL}
            </div>
            <input
              type="email"
              name="emailAddress"
              className="[ form-control ]"
              placeholder={STRINGS.JOINT_APPLICANT.FORM.EMAILPLACEHOLDER}
              onChange={this.handleChange(index, "emailAddress")}
              value={applicant.emailAddress ? applicant.emailAddress : ""}
              autoComplete="off"
            />
            <div className="[ error-msg ]">{emailError[index]}</div>
          </div>
          <div className="[ col-12 col-lg-6 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_EMAILLABEL}
            </div>
            <input
              type="email"
              name="emailConfirm"
              className="[ form-control ]"
              placeholder={
                STRINGS.JOINT_APPLICANT.FORM.EMAILPLACEHOLDER_CONFIRM
              }
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              autoComplete="off"
              onChange={this.handleChange(index, "emailConfirm")}
              value={applicant.emailConfirm}
            />
            <div className="[ error-msg ]">{emailConfirmError[index]}</div>
          </div>
          <div className="[ col-12 col-lg-6 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.MOBILENOLABEL}
            </div>
            <NumberFormat
              format={AppConstants.JOINT_APPLICANT.NUMBERFORMAT}
              className="[ form-control ]"
              type="tel"
              name="mobilePhone"
              value={applicant.mobilePhone ? applicant.mobilePhone : ""}
              placeholder={STRINGS.JOINT_APPLICANT.FORM.MOBILENOPLACEHOLDER}
              onChange={this.handleChange(index, "mobilePhone")}
            />
            <div className="[ error-msg ]">{mobileError[index]}</div>
          </div>

          <div className="[ col-12 col-lg-6 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_TYPE}
            </div>
            <select
              value={applicant.applicantType ? applicant.applicantType : ""}
              name="applicantType"
              className="[ form-control ] [ select__dropdown ]"
              onChange={this.handleChange(index, "applicantType")}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_TYPE_DEFAULT_SELECT}
              </option>
              {productTypesOptComp}
            </select>
            <div className="[ error-msg ]">{typeError[index]}</div>
          </div>

          {finInfo.showJointSignatory && (
            <div
              className="[ col-12 ] [ d-flex align-items-center ] [ checkbox ]"
              onChange={this.handleChangeSignatory(index, "signer")}
            >
              <input
                type="checkbox"
                id="signatoryCheckbox"
                name="signer"
                onChange={this.handleChangeSignatory(index, "signer")}
                defaultChecked={applicant.signer}
              />
              <label
                htmlFor={"signatoryCheckbox"}
                className="[ checkbox-text ]"
              >
                {STRINGS.JOINT_APPLICANT.FORM.SIGNATORY}
              </label>
            </div>
          )}
          {applicant.id && !applicant.inviteDetail && !closeAlert && (
            <div className="[ col-12 ]">
              <Alert variant="danger" dismissible onClose={this.closeAlert}>
                <Alert.Heading>
                  {STRINGS.JOINT_APPLICANT.ERROR.PARTIAL_MATCH_TITLE}
                </Alert.Heading>
                <p>{STRINGS.JOINT_APPLICANT.ERROR.PARTIAL_MATCH_BODY1}</p>
                <p>{STRINGS.JOINT_APPLICANT.ERROR.PARTIAL_MATCH_BODY2}</p>
              </Alert>
            </div>
          )}
        </div>
      </div>
    ));

    const jointApplicantComponent = (
      <div className="[ joint-application-container ]">
        <Stepper
          steps={steps}
          active={activeStepID}
          title={
            finInfo.jointMembership
              ? (jointMembershipSelected && addJointMembers) ||
                jointAccounts.length > 0
                ? "JOINT MEMBERSHIP"
                : "JOINT APPLICANT(S)"
              : null
          }
        />

        <div className="[ form-title ]">{STRINGS.JOINT_APPLICANT.TITLE}</div>

        <div className="[ form-container ]">
          {finInfo.jointMembership && addJointMembers && !membershipsData && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                Add your co applicants to your application.
              </div>
            </div>
          )}
          {finInfo.jointMembership && addJointMembers && !membershipsData && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                The product requires membership.
              </div>
            </div>
          )}

          {(!finInfo.jointMembership ||
            (finInfo.jointMembership &&
              !addJointMembers &&
              jointAccounts.length === 0)) && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_JOINT_APPLICANT}
              </div>
            </div>
          )}

          <div className="[ mb-3 ]">
            {(!finInfo.jointMembership ||
              (finInfo.jointMembership &&
                !addJointMembers &&
                jointAccounts.length === 0)) && (
              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      (
                        finInfo.jointMembership
                          ? jointMembershipSelected
                          : isAddApplicant !== "" && isAddApplicant
                      )
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isAddApplicant"
                    onClick={(event) => this.handleAddApplicantBtn(true, event)}
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      (
                        finInfo.jointMembership
                          ? !jointMembershipSelected
                          : isAddApplicant !== "" && !isAddApplicant
                      )
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isAddApplicant"
                    onClick={(event) =>
                      this.handleAddApplicantBtn(false, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
                <div className="[ error-msg ]" />
              </div>
            )}

            {finInfo.jointMembership &&
            isAddApplicant &&
            membershipsData?.length > 0 &&
            !activeProduct.applicationId
              ? membershipComponent
              : null}

            {(isAddApplicant !== "" &&
              isAddApplicant &&
              !finInfo.jointMembership) ||
            (isAddApplicant &&
              membershipsData?.length > 0 &&
              membership === "NEW") ||
            jointAccounts.length > 0 ||
            enterJointMembers
              ? addApplicantComp
              : ""}

            {((isAddApplicant !== "" &&
              isAddApplicant &&
              !finInfo.jointMembership) ||
              (finInfo.jointMembership &&
                isAddApplicant &&
                membershipsData?.length > 0 &&
                (membership === "NEW" || activeProduct.applicationId))) && (
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-9 ]">
                    <small className="[ footnotes form-text text-muted ]">
                      {STRINGS.JOINT_APPLICANT.FORM.NOTE}
                    </small>
                  </div>
                  <div className="[ col-lg-3 ] [ offset-lg-9 ] [ mb-3 ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                      disabled={appCnt === applicants.length}
                      onClick={(event) => this.handleAddApplicant(event)}
                    >
                      <span className="[ fas fa-plus ]" />
                      {STRINGS.JOINT_APPLICANT.FORM.ADD_BTN}
                    </button>
                  </div>
                </div>
                <div className="[ divider ]" />
              </div>
            )}
          </div>

          {isAddApplicant && survivorList.length > 0 && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.JOINT_APPLICANT.FORM.SURVIVORSHIP}
                {!contKycFlag ? (
                  <QuestionCircle
                    className="question-icon"
                    size={20}
                    onClick={() =>
                      this.handleOpenHelp(
                        STRINGS.JOINT_APPLICANT.FORM.SURVIVORSHIP_MORE_INFO
                      )
                    }
                  />
                ) : (
                  <QuestionCircle
                    className="question-icon"
                    size={20}
                    onClick={() =>
                      this.handleOpenHelp(
                        STRINGS.JOINT_APPLICANT.FORM.SURVIVORSHIP_MORE_INFO_KYC
                      )
                    }
                  />
                )}
              </div>
              <select
                className="[ form-control select__dropdown]"
                id="survivor"
                name="survivor"
                value={survivor}
                placeholder={
                  STRINGS.JOINT_APPLICANT.FORM.PLACEHOLDER.SURVIVORSHIP
                }
                onChange={(event) => {
                  this.setState({ survivorError: [] });
                  handleChange(event);
                }}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.JOINT_APPLICANT.FORM.PLACEHOLDER.SURVIVORSHIP}
                </option>
                {survivorList
                  ? survivorList.map((ref) => (
                      <option
                        key={ref.code}
                        value={ref.code}
                        className="[ dropdown__option ]"
                      >
                        {ref.description}
                      </option>
                    ))
                  : ""}
              </select>
              <div className="[ survivorship_dislaimer ]">
                <small className="[ footnotes form-text text-muted ]">
                  {STRINGS.JOINT_APPLICANT.FORM.SURVIVORSHIP_DISCLAIMER}
                </small>
              </div>
              <div className="[ error-msg ]">
                {survivorError && <>{survivorError}</>}
              </div>
            </div>
          )}
          {isAddApplicant && ownershipList.length > 0 && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.JOINT_APPLICANT.FORM.OWNERSHIP}
              </div>
              <select
                className="[ form-control select__dropdown]"
                id="ownership"
                name="ownership"
                value={ownership}
                placeholder={STRINGS.JOINT_APPLICANT.FORM.PLACEHOLDER.OWNERSHIP}
                onChange={(event) => {
                  this.setState({ ownershipError: [] });
                  handleChange(event);
                }}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.JOINT_APPLICANT.FORM.PLACEHOLDER.OWNERSHIP}
                </option>
                {ownershipList
                  ? ownershipList.map((owner) => (
                      <option
                        key={owner.code}
                        value={owner.code}
                        className="[ dropdown__option ]"
                      >
                        {owner.description}
                      </option>
                    ))
                  : ""}
              </select>
              <div className="[ error-msg ]">
                {ownershipError && <>{ownershipError}</>}
              </div>
              <div className="[ divider ]" />
            </div>
          )}

          {isAddApplicant && !finInfo.disableJointInSession && !ssoLogin && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.JOINT_APPLICANT.FORM.JOINT_PRESENT_LABEL}
              </div>

              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      isApplicantPresent !== "" && isApplicantPresent
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isApplicantPresent"
                    onClick={(event) =>
                      this.handleApplicantPresentBtn(true, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      isApplicantPresent !== "" && !isApplicantPresent
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isApplicantPresent"
                    onClick={(event) =>
                      this.handleApplicantPresentBtn(false, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
              </div>
            </div>
          )}
          {isAddApplicant && (
            <div className="[ mb-3 ] [ alert alert-warning ]" role="alert">
              <div className="[ row ] [ mb-3 ]">
                <div className="[ col-12 ]">
                  <i className="[ fas fa-exclamation-circle ]" />
                  <span>
                    <strong>
                      {STRINGS.JOINT_APPLICANT.FORM.PRIVACY_DISCLAIMER_TITLE}
                    </strong>
                  </span>
                  <p>{STRINGS.JOINT_APPLICANT.FORM.PRIVACY_DISCLAIMER}</p>
                  <p>{STRINGS.JOINT_APPLICANT.FORM.PRIVACY_DISCLAIMER2}</p>
                  {ssoLogin && (
                    <p>{STRINGS.JOINT_APPLICANT.FORM.SSO_JOINT_MESSAGE}</p>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                <button
                  type="submit"
                  className="[ submit-btn btn ] [ w-100 ]"
                  onClick={this.handleContinue}
                >
                  {STRINGS.COMMON.CONTINUEBTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <AuthBody
          actionComponent={jointApplicantComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </div>
    );
  }
}

JointApplicant.propTypes = {
  getDataFromServer: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.object),
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  getActiveProduct: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  jointAccounts: PropTypes.arrayOf(PropTypes.object),
  jointInSession: PropTypes.bool,
  ownershipList: PropTypes.arrayOf(PropTypes.object),
  ownership: PropTypes.string,
  survivorList: PropTypes.arrayOf(PropTypes.object),
  survivor: PropTypes.string,
  handleChange: PropTypes.func,
  account: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
};

JointApplicant.defaultProps = {
  getDataFromServer: () => {},
  handleChange: () => {},
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  jointAccounts: [],
  jointInSession: false,
  ownershipList: [],
  ownership: "",
  survivorList: [],
  survivor: "",
  account: {},
  finInfo: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(JointApplicant);
