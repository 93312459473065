import AppConstants from "Constants/AppConstants";
import STRINGS from "../Constants/Strings";
import { S_APPLICANT_ID, S_PERSONAL } from "../Communication/Constants";
import { format } from "date-fns";

export const togglePassword = (toggleFlag) => {
  return toggleFlag ? "text" : "password";
};

export const usernameCredentials = (username) => {
  if (!Number.isNaN(username)) {
    const pattern = AppConstants.SIGNIN.USERNAME_PATTERN;

    const checkpattern = new RegExp(pattern).test(username);
    let credentialNumber;

    if (checkpattern) {
      credentialNumber = AppConstants.SIGNIN.NUMBERPREFIX + username;
    } else {
      credentialNumber = username;
    }
    return credentialNumber;
  }
  return username;
};

export const removeLinkTag = () => {
  if (window.IDMetricsCaptureFramework === undefined) {
    setTimeout(removeLinkTag, 50); // wait 50 millisecnds then recheck
    return;
  }
  const instntDiv = document.getElementById("instnt-form-generator");
  for (let i = 0; i < instntDiv.children.length; i += 1) {
    const linkNode =
      instntDiv.children[i].nodeName === AppConstants.NODE_NAME
        ? instntDiv.children[i]
        : null;
    if (linkNode) {
      instntDiv.removeChild(linkNode);
    }
  }
};
/**
 *
 * @param {array} searchInArray represents array
 * to be passed for searching
 * @param {string} searchForCode represents code
 * using which we can search for it's respective
 * description value
 */
export const getDescriptionFromArray = (searchInArray, searchForCode) => {
  const result = searchInArray?.find((search) => search.code === searchForCode);
  return result?.description;
};

/**
 * Name: isDeviceMobile
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["Android","webOS","iPhone","iPad","iPod","BlackBerry","Windows Phone"]
 */
export const isDeviceMobile = () => {
  if (
    window.navigator.userAgent.match(/Android/i) ||
    window.navigator.userAgent.match(/webOS/i) ||
    window.navigator.userAgent.match(/iPhone/i) ||
    window.navigator.userAgent.match(/iPod/i) ||
    window.navigator.userAgent.match(/BlackBerry/i) ||
    window.navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

/**
 * Name: isDeviceTablet
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["iPad"]
 */
export const isDeviceTablet = () => {
  if (window.navigator.userAgent.match(/iPad/i)) {
    return true;
  }
  return false;
};

/**
 * Name: isDeviceMobile
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["Android","webOS","iPhone","iPad","iPod","BlackBerry","Windows Phone"]
 */
export const isScanningAllowed = () => {
  if (isDeviceMobile() || isDeviceTablet()) {
    return true;
  }
  return false;
};

/**
 * Name: convertBase64ImageToString
 * @param {*} dataUri
 * Description: Removes prefix from the base 64 scanned images.
 */
export const convertBase64ImageToString = (dataUri) => {
  let byteString;
  if (dataUri.split(",")[0].indexOf("base64") <= 0)
    byteString = atob(dataUri.split(",")[1]);
  else byteString = unescape(dataUri.split(",")[1]);

  return byteString;
};

export const getProductCategoryList = (products) => {
  return products.map((item, index) => {
    return {
      code: index + 1,
      description: item.id,
      checked: true,
      type: item.type,
      show: item.show,
    };
  });
};

export const getVaultCardProductList = (productList, vaultProductList) => {
  const vaultCardProductList = [];
  let list = [];

  productList.forEach((element) => {
    const { products } = { ...element };
    if (list.length !== 0) {
      list = list.concat(products);
    } else {
      list = products;
    }
  });

  vaultProductList.forEach((product) => {
    let card = {};
    const index = list.findIndex(
      (item) => item.productId === product.productId
    );
    if (index !== -1) {
      card = { ...product, ...list[index] };
      card.productIndex = product.productIndex;
      card.applicationId = product.applicationId;
      card.bankingType =
        product.bankingType || AppConstants.BANKING_TYPE.PERSONAL;
      const existAlready = vaultCardProductList.find(
        (item) => item.productIndex === card.productIndex
      );
      if (!existAlready) {
        vaultCardProductList.push(card);
      }
    }
  });
  return vaultCardProductList;
};

export const getProduct = (productList, vaultProductList) => {
  let list = [];
  let product = { productName: "" };
  if (vaultProductList.length === 0) return product;
  const productobject = vaultProductList[0];
  productList.forEach((element) => {
    const { products } = { ...element };
    if (list.length !== 0) {
      list = list.concat(products);
    } else {
      list = products;
    }
  });
  const index = list.findIndex(
    (item) => item.productId === productobject.productId
  );
  if (index !== -1) {
    product = { ...list[index] };
    return product;
  }
  return product;
};

/**
 * Function to dynamically replace placeholders in an API URL with actual values.
 * @param {String} url with palceholders prefixed with '%'. Eg. %1
 * @param {Array} paramList
 * returns String URL with dynamic params
 */
export const formURL = (url, paramList) => {
  let newUrl = url.replace(/%(\d+)/g, (_, n) => paramList[+n - 1]);
  if (newUrl.includes("&")) {
    newUrl = `${newUrl}&t=${new Date().getTime()}`;
  } else {
    newUrl = `${newUrl}?t=${new Date().getTime()}`;
  }
  return newUrl;
};
/**
 * Sort vaultProductList in ascending order on field property.
 * @param {*} listToSort
 * @param {*} field
 */
export const sortList = (listToSort, field) => {
  return listToSort.sort(
    (productOne, productTwo) => productOne[field] - productTwo[field]
  );
};

export const getGettingStarted = (data, callback) => {
  const gettingStartedData = {
    branch: "",
    accountStatementType: "",
    reasonCode: "",
    promoCode: "",
    referralCode: "",
  };
  gettingStartedData.branch = data.member.branch;
  gettingStartedData.reasonCode = data.member.reasonOpened;

  if (data.regulatory.eStmt) {
    gettingStartedData.accountStatementType = AppConstants.GETTINGSTARTED.EMAIL;
  } else {
    gettingStartedData.accountStatementType = AppConstants.GETTINGSTARTED.PAPER;
  }
  if (data.regulatory.regulatoryFields) {
    gettingStartedData.promoCode = data.regulatory?.regulatoryFields?.find(
      (item) => item.Code === 506
    )?.Value;
    gettingStartedData.referralCode = data.regulatory?.regulatoryFields?.find(
      (item) => item.Code === 507
    )?.Value;
  }
  callback(gettingStartedData);
};

export const commaFormatted = (amount) => {
  if (amount) {
    if (typeof amount === "number") {
      return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
};

export const commaFormattedNoDecimal = (amount) => {
  if (amount) {
    if (typeof amount === "number") {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
};

export const capitalize = (s) => {
  if (typeof s !== "string") return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const findCode = (code, lookup) => {
  let result = "";
  if (code && lookup) {
    const x = lookup.find((item) => item.code === code);
    if (x) {
      result = x.description;
    }
  }
  return result;
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
};

export const getApplicantStatus = (applicant) => {
  let status = "";
  if (applicant.appliedAt) {
    status = "Completed";
  } else {
    if (applicant.inviteDetail) {
      status = "Pending";
    } else {
      status = "Pending";
    }
  }
  return status;
};

export const getApplicantType = (applicantTypes, type) => {
  let description = "";
  applicantTypes.forEach((item) => {
    if (item.code === type) {
      description = item.description;
    }
  });
  return description;
};

export const getProductInfo = (productid, productList) => {
  let product = {};
  console.log("getProductInfo", productid, productList);
  productList.forEach((item) => {
    item.products.forEach((x) => {
      if (x.productId === productid || x.productId.includes(productid)) {
        product = x;
      }
    });
  });
  console.log(product);
  return product;
};

export const searchCategoryInCompleteList = (
  category,
  productList,
  applicationCompleteList
) => {
  let product = null;
  console.log(
    "searchCategoryInCompleteList",
    category,
    productList,
    applicationCompleteList
  );
  applicationCompleteList.forEach((item) => {
    item.doximProducts.forEach((x) => {
      productList.forEach((y) => {
        y.products.forEach((z) => {
          console.log("z", z);
          if (
            // productId can have multiple products delimited by |
            z.productCategory === category &&
            z.productId.includes(x.bundleProductId)
          ) {
            product = z;
          }
        });
      });
    });
  });
  console.log("result", product);
  return product;
};

export const findByDescription = (desc, lookup) => {
  console.log("findByDescription", desc, lookup);
  return lookup?.find((item) => desc === item.description);
};

/**
 * Returns active product if not found then returns first object from the array
 * @param {*} products
 */
export const getActiveProduct = (products) => {
  let activeProduct = products?.find((product) => product?.active);
  if (!activeProduct) {
    if (!activeProduct && products?.length) {
      activeProduct = products[0];
    }
    if (!activeProduct?.applicantId) {
      const jointSlotId = sessionStorage.getItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
      );
      if (jointSlotId) {
        const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
        if (applicantId) {
          activeProduct.applicantId = applicantId;
        }
      }
    }
  }
  return activeProduct;
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand("copy", true, text);
}

/**
 * returns the step object's index from the template flow which matches the param step
 * @param {*} template
 * @param {*} step
 */
export const getCurrentStepIndexFromTemplateFlowByStep = (template, step) => {
  return template?.flow?.findIndex(
    (applicantStep) => applicantStep.componentName === step
  );
};

export const getContinousKycRegulatorUpdates = (
  regulatoryRequest,
  vaultMember,
  applicantData,
  finInfo
) => {
  let newRegulatoryRequest = regulatoryRequest;

  if (!newRegulatoryRequest) {
    newRegulatoryRequest = {};
  }

  const newOECD = vaultMember?.regulatoryFields?.find(
    (item) => item.Code === 1
  );
  const currentOECD = applicantData.regulatory.regulatoryFields?.find(
    (item) => item.Code === 1
  );

  const updateRegulatoryFieldsInputModel = [];
  if (
    vaultMember?.fatcaStatus === finInfo.noFatcaCrs &&
    applicantData.regulatory.fatcaStatus !== finInfo.noFatcaCrs &&
    currentOECD.Value === finInfo.oecdCrs &&
    newOECD.Value === finInfo.noFatcaCrs
  ) {
    // removal of tax info will only reflect in vault
  } else if (
    vaultMember?.regulatoryFields &&
    Array.isArray(vaultMember?.regulatoryFields)
  ) {
    for (const item of vaultMember?.regulatoryFields) {
      if (!finInfo.kycComplianceRegulatoryUpdate) {
        updateRegulatoryFieldsInputModel.push({
          ...item,
          Text: item.Text.split("-").join(" "),
        });
      } else if (item.Code !== 12) {
        updateRegulatoryFieldsInputModel.push({
          ...item,
          Text: item.Text.split("-").join(" "),
        });
      }
    }
  }
  newRegulatoryRequest.updateRegulatoryFieldsInputModel = updateRegulatoryFieldsInputModel;

  if (finInfo.kycComplianceRegulatoryUpdate) {
    newRegulatoryRequest.updateRegulatoryFieldsInputModel.push({
      ApplicantID: applicantData.id,
      Code: 12,
      Value: format(new Date(), "yyyy-MM-dd"),
      Text: "Compliance Updated Date",
      Type: "date",
    });
  }

  console.log("newRegulatoryRequest", newRegulatoryRequest);
  return newRegulatoryRequest;
};

export const pepExist = (applicantData) => {
  let result = false;

  if (applicantData) {
    if (
      applicantData.regulatory.memberFundsSource ||
      applicantData.regulatory.memberHasThirdParty ||
      applicantData.regulatory.memberIsHio ||
      applicantData.regulatory.memberIsPedp ||
      applicantData.regulatory.memberIsPefp ||
      applicantData.regulatory.memberJurisdiction ||
      applicantData.regulatory.memberOfficeHeld ||
      (Array.isArray(applicantData.peps) && applicantData.peps.length > 0) ||
      (Array.isArray(applicantData.thirdParties) &&
        applicantData.thirdParties.length > 0)
    ) {
      result = true;
    }
  }

  return result;
};

export const getCountryCode = (finInfo, idName, issuerCode) => {
  let result = issuerCode;

  if (finInfo.cardIssuerCode && Array.isArray(finInfo.cardIssuerCode)) {
    const cardIssuer = finInfo.cardIssuerCode.find((x) => x.type === idName);
    if (cardIssuer) {
      const countryCode = cardIssuer.find((card) => card.code === issuerCode);
      if (countryCode) {
        result = countryCode.newCode;
      }
    }
  }

  return result;
};

export const getLicenseCountryCode = (finInfo, idName, issuerCode) => {
  let result = "";

  if (finInfo.cardIssuerCode && Array.isArray(finInfo.cardIssuerCode)) {
    const cardIssuer = finInfo.cardIssuerCode.find((x) => x.type === idName);
    if (cardIssuer) {
      const countryCode = cardIssuer.countryIssuerCode.find(
        (card) => card.code === issuerCode
      );
      if (countryCode) {
        result = countryCode.newCode;
      }
    }
  }

  if (!result) {
    result = finInfo.idCountry1;
  }

  return result;
};

export const getLicenseProvinceCode = (finInfo, idName, issuerCode) => {
  let result = issuerCode;

  if (finInfo.cardIssuerCode && Array.isArray(finInfo.cardIssuerCode)) {
    const cardIssuer = finInfo.cardIssuerCode.find((x) => x.type === idName);
    if (cardIssuer) {
      result = "";
    }
  }

  return result;
};

export const getSingularMemberships = (membershipsData) => {
  let result = [];

  if (membershipsData) {
    const singularMemberships = membershipsData.filter(
      (x) => x.Category !== "jnt"
    );
    if (singularMemberships.length > 0) {
      result.push(singularMemberships);
    }
  }

  return result;
};

export const getJointMemberships = (membershipsData) => {
  let result = [];

  if (membershipsData) {
    const jointMemberships = membershipsData.filter(
      (x) => x.Category === "jnt"
    );
    if (jointMemberships.length > 0) {
      result.push(jointMemberships);
    }
  }

  return result;
};

export const findNaicsCode = (array, code, parent, parentCode) => {
  let result = [];

  if (array) {
    if (parent) {
      array.map((x) => {
        if (x[parent] === parentCode) {
          const findItem = result.find((curr) => curr === x[code]);
          if (!findItem) {
            result.push(x[code]);
          }
        }
      });
    } else {
      array.map((x) => {
        const findItem = result.find((curr) => curr === x[code]);
        if (!findItem) {
          result.push(x[code]);
        }
      });
    }
  }

  return result;
};

export const getPersonalId = () => {
  let result = null;

  const personalId = sessionStorage.getItem(S_PERSONAL);

  if (personalId && personalId !== "null") {
    result = personalId;
  }

  return result;
};

export const getBusinessAddress = (addresses) => {
  let result = null;
  if (addresses != null && Array.isArray(addresses)) {
    result = {
      currentAddressLine1: addresses.find((x) => x.type === "Registered Office")
        ?.streetName,
      currentAddressLine2: addresses.find((x) => x.type === "Registered Office")
        ?.unitNumber,
      currentAddressUnitNumber: addresses.find(
        (x) => x.type === "Registered Office"
      )?.unitNumber,
      currentAddressLine3: addresses.find((x) => x.type === "Registered Office")
        ?.city,
      currentAddressCity: addresses.find((x) => x.type === "Registered Office")
        ?.city,
      currentAddressProvince: addresses.find(
        (x) => x.type === "Registered Office"
      )?.province,
      currentAddressPostalCode: addresses.find(
        (x) => x.type === "Registered Office"
      )?.postalCode,
      // currentAddressCountry: addresses.find(
      //     (x) => x.type === "Registered Office"
      // )?.country,
      mailingAddressLine1: addresses.find((x) => x.type === "Mailing Address")
        ?.streetName,
      mailingAddressLine2: addresses.find((x) => x.type === "Mailing Address")
        ?.unitNumber,
      mailingUnitNumber: addresses.find((x) => x.type === "Mailing Address")
        ?.unitNumber,
      mailingAddressLine3: addresses.find((x) => x.type === "Mailing Address")
        ?.city,
      mailingCity: addresses.find((x) => x.type === "Mailing Address")?.city,
      mailingProvince: addresses.find((x) => x.type === "Mailing Address")
        ?.province,
      mailingPostalCode: addresses.find((x) => x.type === "Mailing Address")
        ?.postalCode,
      // mailingCountry: addresses.find(
      //     (x) => x.type === "Mailing Address"
      // )?.country,
    };
  }
  return result;
};

export const cleanPhone = (raw) => {
  let result = raw;

  result = result.split("+").join("");
  result = result.split(" ").join("");
  result = result.split("(").join("");
  result = result.split(")").join("");
  result = result.split("-").join("");

  return result;
};
