import BaseApiRequest from "./BaseApiRequest";
import { OTP_VERIFY_INVITE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class OtpVerifyInvite extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  url = OTP_VERIFY_INVITE_URL;

  constructor(reqData) {
    super();

    this.body = reqData;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
