/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import PropTypes from "prop-types";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import AppConstants from "Constants/AppConstants";
import Stepper from "Components/Common/Stepper/Stepper";
import STRINGS from "Constants/Strings";
import { findByDescription } from "../../Utils/CommonUtilities";

class AccountInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intendedUse: "",
      intendedUseError: [],
      contractNumberError: [],
      origUdfList: {},
      accountInfo: {},
      contractList: [],
      contractNumber: null,
      otherInfo: "",
      otherInfoError: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    window.scrollTo(0, 0);
    getDataFromServer(
      AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION,
      (response) => {
        console.log("componentDidMount", response);
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct, bankingProductList } = this.props;
    if (prevProps.bankingProductList !== bankingProductList) {
      console.log("bankingProductList", bankingProductList);
      const contractList = [];
      if (bankingProductList) {
        bankingProductList.forEach((item) => {
          if (
            item.productDescription.startsWith("RTMT") &&
            activeProduct.productCategory.indexOf(item.productCode) > -1
          ) {
            contractList.push(item.basicDetails.acctNo);
          }
        });
        if (contractList) {
          console.log("contractList", contractList);
          this.setState({ contractList });
        }
      }
    }
  }

  setFormData = (response) => {
    const { activeProduct, bankingProductList, finInfo } = this.props;

    if (activeProduct.intendedUse) {
      this.setState({
        origUdfList: response.udfList,
        intendedUse: response.udfList[activeProduct.intendedUse],
        accountInfo: response,
        contractNumber: response?.bankingContractNo,
        otherInfo: finInfo.intendedUseOtherFlag
          ? response.udfList[finInfo.intendedUseOtherFlag]
          : "",
      });
    } else {
      const { intendedUse } = response;
      this.setState({
        origUdfList: response.udfList,
        intendedUse,
        accountInfo: response,
        contractNumber: response?.bankingContractNo,
        otherInfo: finInfo.intendedUseOtherFlag
          ? response.udfList[finInfo.intendedUseOtherFlag]
          : "",
      });
    }
  };

  /**
   * Function to handle change event of 'Intended Use' dropdown. Set the error to empty.
   */
  handleChange = (event) => {
    this.setState({
      intendedUse: event.target.value,
      intendedUseError: [],
    });
  };

  handleChangeOtherInfo = (event) => {
    this.setState({
      otherInfo: event.target.value,
      otherInfoError: [],
    });
  };

  handleChangeContract = (event) => {
    this.setState({
      contractNumber: event.target.value,
      contractNumberError: [],
    });
  };

  /**
   * On click of Continue, validate the form and call the API to update the product information on server
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { handleAccountInformation } = this.props;
    const formIsValid = this.verifyData();
    if (formIsValid) {
      const formData = this.state;
      handleAccountInformation(formData);
    }
  };

  /**
   * Validate the form data
   */
  verifyData = () => {
    let valid = true;
    const { intendedUse, otherInfo, contractNumber, contractList } = this.state;
    const error = checkErrors(
      intendedUse,
      VALIDATIONS.ACCOUNT_INFORMATION.INTENDEDUSE
    );

    if (error.length) {
      valid = false;
      this.setState({ intendedUseError: error });
    }

    console.log("verifyData", intendedUse, otherInfo);

    if (
      this.getIntendedUseCode(intendedUse)?.code === "Other" &&
      (!otherInfo || otherInfo?.trim().length === 0)
    ) {
      valid = false;
      this.setState({
        otherInfoError: [STRINGS.ACCOUNT_INFORMATION.ERROR.INTENDED_USE_OTHER],
      });
    }

    if (contractList?.length > 0 && !contractNumber) {
      valid = false;
      this.setState({
        contractNumberError: [STRINGS.ACCOUNT_INFORMATION.ERROR.CONTRACT_ERROR],
      });
    }

    return valid;
  };

  getIntendedUseCode = (desc) => {
    const { intendedUseList } = this.props;
    console.log("getIntendedUseCode", desc, intendedUseList);
    return intendedUseList?.find((item) => desc === item.description);
  };

  render() {
    const {
      intendedUseError,
      intendedUse,
      contractNumber,
      contractList,
      otherInfo,
      otherInfoError,
      contractNumberError,
    } = this.state;
    const {
      steps,
      activeStepID,
      handleBack,
      intendedUseList,
      activeProduct,
    } = this.props;
    const actionComponent = (
      <div className="[ account-information-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.ACCOUNT_INFORMATION.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ACCOUNT_INFORMATION.FORM.ACCOUNT_PURPOSE_LABEL}
            </div>

            <select
              className={
                intendedUseError && intendedUseError.length > 0
                  ? "[ form-control ] [ select__dropdown ] [ invalid-input ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              id="intendedUse"
              name="intendedUse"
              placeholder={
                STRINGS.ACCOUNT_INFORMATION.FORM.ACCOUNT_PURPOSE_PLACEHOLDER
              }
              value={intendedUse}
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.ACCOUNT_INFORMATION.FORM.ACCOUNT_PURPOSE_PLACEHOLDER}
              </option>
              {intendedUseList?.map((obj) => {
                return (
                  <option
                    key={obj.code}
                    value={obj.description}
                    className="[ dropdown__option ]"
                  >
                    {obj.description}
                  </option>
                );
              })}
            </select>
            <div className="[ error-msg ]">
              {intendedUseError ? intendedUseError[0] : ""}
            </div>
          </div>

          {findByDescription(intendedUse, intendedUseList)?.code ===
            "Other" && (
            <div className="[ mb-3 ]">
              <div className="[ row ]">
                <div className="[ col-12 ]">
                  <div className="[ form-label ] ">Intended Use Details</div>
                  <textarea
                    className="form-control"
                    value={otherInfo}
                    onChange={this.handleChangeOtherInfo}
                  />
                </div>
              </div>
              <div className="[ error-msg ]">
                {otherInfoError ? otherInfoError[0] : ""}
              </div>
            </div>
          )}

          {contractList?.length > 0 && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ACCOUNT_INFORMATION.FORM.PICK_CONTRACT}
              </div>

              <select
                className={
                  contractNumberError && contractNumberError.length > 0
                    ? "[ form-control ] [ select__dropdown ] [ invalid-input ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                id="contractNumber"
                name="contractNumber"
                placeholder={
                  STRINGS.ACCOUNT_INFORMATION.FORM.PICK_CONTRACT_PLACEHOLDER
                }
                value={contractNumber}
                onChange={this.handleChangeContract}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.ACCOUNT_INFORMATION.FORM.PICK_CONTRACT_PLACEHOLDER}
                </option>
                {contractList?.map((obj) => {
                  return (
                    <option
                      key={obj}
                      value={obj}
                      className="[ dropdown__option ]"
                    >
                      {obj}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">
                {contractNumberError ? contractNumberError[0] : ""}
              </div>
            </div>
          )}

          {activeProduct.legalDisclaimer && (
            <div
              dangerouslySetInnerHTML={{
                __html: activeProduct.legalDisclaimer,
              }}
            />
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <AuthBody
        handleBack={handleBack}
        actionComponent={actionComponent}
        memberCreation
      />
    );
  }
}

AccountInformation.propTypes = {
  getDataFromServer: PropTypes.func,
  intendedUseList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAccountInformation: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  activeProduct: PropTypes.arrayOf(PropTypes.object),
  bankingProductList: PropTypes.arrayOf(PropTypes.object),
};
AccountInformation.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  activeProduct: {},
  bankingProductList: [],
};
export default AccountInformation;
