import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class Expense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      expenseTypeError: [],
      descriptionError: [],
      monthlyAmountError: [],
      valueError: [],
      expenseList: [
        {
          expenseType: "",
          description: "",
          monthlyAmount: "",
          adjustment: "",
        },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.EXPENSE, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    const { products, getActiveProduct, expenseList } = this.props;
    if (expenseList !== prevProps.expenseList) {
      console.log(expenseList);
      this.setState({ expenseList: expenseList });
    }
  }

  setFormData = () => {};

  validateForm = () => {
    let valid = true;
    const { expenseList } = this.state;
    const { choice } = this.props;
    this.setState({
      expenseTypeError: [],
      descriptionError: [],
      monthlyAmountError: [],
      formIsValid: true,
    });
    console.log("expense length", expenseList.length, choice);
    if (expenseList.length > 0 && choice) {
      // Check error for asset Type
      let error = checkErrors(
        expenseList[expenseList.length - 1].expenseType,
        VALIDATIONS.LOANS.EXPENSE.EXPENSE_TYPE
      );
      if (error.length) {
        valid = false;
        this.setState({ expenseTypeError: error });
      }

      error = checkErrors(
        expenseList[expenseList.length - 1].description,
        VALIDATIONS.LOANS.EXPENSE.DESCRIPTION
      );
      if (error.length) {
        valid = false;
        this.setState({ descriptionError: error });
      }

      error = checkErrors(
        expenseList[expenseList.length - 1].monthlyAmount,
        VALIDATIONS.LOANS.EXPENSE.MONTHLY_AMOUNT
      );
      if (error.length) {
        valid = false;
        this.setState({ monthlyAmountError: error });
      }

      this.setState({
        formIsValid: valid,
      });
    }

    return valid;
  };

  handleExpenseChange = (raw, prop) => (event) => {
    const { expenseList } = this.state;
    const expense = raw;
    expense[prop] = event.currentTarget.value;
    this.setState({ expenseList, [`${prop}Error`]: [] });
  };

  handleChange = (raw, event) => {
    const { handleChange } = this.props;
    handleChange(raw);
  };

  handleAddExpense = () => {
    console.log("handleAddExpense");
    if (this.validateForm()) {
      const expense = {
        expenseType: "",
        description: "",
        monthlyAmount: "",
        adjustment: "",
      };
      const action = {
        type: "add",
        expense: expense,
      };
      this.updateExpenseList(action);
    }
  };

  handleDeleteExpense = (idx, expense) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateExpenseList(action);
  };

  updateExpenseList(action) {
    const { expenseList } = this.state;
    if (action.type === "add") {
      console.log(action);
      expenseList.push(action.expense);
    } else if (action.type === "remove") {
      if (action.index === expenseList.length - 1) {
        this.setState({
          expenseTypeError: [],
          descriptionError: [],
          monthlyAmountError: [],
          valueError: [],
        });
      }
      if (action.index > -1) {
        console.log(action);
        expenseList.splice(action.index, 1);
      }
    }
    this.setState({ expenseList });
    if (expenseList.length === 0) {
      const { handleChange } = this.props;
      handleChange(false);
    }
  }

  handleContinue = () => {
    const { handleContinue } = this.props;
    if (this.validateForm()) {
      handleContinue(this.state.expenseList);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.EXPENSE);
  };

  handleButtonIsPrimaryResidenceChange = (index, val) => {
    console.log(index, val);
    const { expenseList } = this.state;
    expenseList[index].isPrimaryResidence = val;

    this.setState({ expenseList });
  };

  render() {
    const {
      expenseList,
      expenseTypeError,
      descriptionError,
      monthlyAmountError,
    } = this.state;
    const { steps, activeStepID, choice, expenseTypes } = this.props;
    const adjustmentTypeList = ["Con", "Exc", "New"];
    const expenseTypeComponent = expenseTypes.map((expenseType, index) => (
      <option value={expenseType.code} className="[ dropdown__option ]">
        {expenseType.description}
      </option>
    ));
    const adjustmentTypeComponent = adjustmentTypeList.map(
      (adjustmentType, index) => (
        <option value={adjustmentType} className="[ dropdown__option ]">
          {adjustmentType}
        </option>
      )
    );

    const addExpenseListComponent = expenseList.map((expense, index) => (
      <div className="[ drawer-content-item ]">
        <div className="[ info-title ]">
          {/* CHRIS NOTE: For some reason, index + 1 doesn't want to work here. {index + 1} Please include */}
          {STRINGS.EXPENSE.FORM.EXPENSE_TYPE_NO} {index + 1}
          <button
            type="button"
            className="[ fas fa-trash ]"
            onClick={() => this.handleDeleteExpense(index, expense)}
            aria-label="remove"
          >
            <span className="[ sr-only ]">
              {STRINGS.EXPENSE.FORM.REMOVE_EXPENSE}
            </span>
          </button>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            {/* EXPENSE TYPE */}
            <div className="[ col-12 col-lg-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EXPENSE.FORM.EXPENSE_TYPE}
              </div>
              <select
                value={expense.expenseType ? expense.expenseType : ""}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.handleExpenseChange(expense, "expenseType")}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.EXPENSE.FORM.EXPENSE_TYPE_DEFAULT_SELECT}
                </option>
                {expenseTypeComponent}
              </select>
              <div className="[ error-msg ]">
                {expenseTypeError
                  ? index + 1 === expenseList.length
                    ? expenseTypeError[0]
                    : ""
                  : ""}
              </div>
            </div>

            {/* EXPENSE DESCRIPTION */}
            <div className="[ col-12 col-lg-6 ]">
              <div className="[ form-label ] ">
                {STRINGS.EXPENSE.FORM.DESCRIPTION}
              </div>
              <input
                type="text"
                name="description"
                className="[ form-control ]"
                placeholder={STRINGS.EXPENSE.FORM.DESCRIPTION}
                onChange={this.handleExpenseChange(expense, "description")}
                value={expense.description ? expense.description : ""}
              />
              <div className="[ error-msg ]">
                {descriptionError
                  ? index + 1 === expenseList.length
                    ? descriptionError[0]
                    : ""
                  : ""}
              </div>
            </div>

            {/* MONTHLY AMOUNT */}
            <div className="[ col-12 col-lg-3 ]">
              <div className="[ form-label ] ">
                {STRINGS.EXPENSE.FORM.MONTHLY_AMOUNT}
              </div>
              <NumberFormat
                maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                type="text"
                thousandSeparator
                allowNegative={false}
                prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                name="monthlyAmount"
                className="[ form-control ]"
                placeholder={STRINGS.EXPENSE.FORM.MONTHLY_AMOUNT_PLACEHOLDER}
                onChange={this.handleExpenseChange(expense, "monthlyAmount")}
                value={expense.monthlyAmount ? expense.monthlyAmount : ""}
              />
              <div className="[ error-msg ]">
                {monthlyAmountError
                  ? index + 1 === expenseList.length
                    ? monthlyAmountError[0]
                    : ""
                  : ""}
              </div>
            </div>
          </div>

          {(expense.expenseType === "Rent" ||
            expense.expenseType === "Heat" ||
            expense.expenseType === "Condo" ||
            expense.expenseType === "Taxes" ||
            expense.expenseType === "Paid Rental") && (
            <div className="[ row ] [ expense ]">
              {/* Primary Residence */}
              <div className="[ col-12 col-lg-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.EXPENSE.FORM.PRIMARY_RESIDENCE}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="button"
                      className={
                        expense.isPrimaryResidence === true
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="primaryResidence"
                      onClick={(event) =>
                        this.handleButtonIsPrimaryResidenceChange(index, true)
                      }
                    >
                      {STRINGS.EXPENSE.FORM.YES}
                    </button>
                  </div>
                  <div className="[ col-6 ]">
                    <button
                      type="button"
                      className={
                        !expense.isPrimaryResidence
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="primaryResidence"
                      onClick={(event) =>
                        this.handleButtonIsPrimaryResidenceChange(index, false)
                      }
                    >
                      {STRINGS.LIABILITIES.FORM.NO}
                    </button>
                  </div>
                  <div className="[ error-msg ]"></div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="[ divider ] [ mb-3 ]" />
      </div>
    ));
    const actionComponent = (
      <div className="[ expense-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.EXPENSE.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.EXPENSE.PAGE_DESCRIPTION1}{" "}
          </div>
          <div className="[ form-description ]">
            {STRINGS.EXPENSE.PAGE_DESCRIPTION2}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EXPENSE.FORM.EXPENSE_RADIO_LABEL}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && !choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(false, event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]"></div>
            </div>
          </div>

          {choice && (
            <div className="[ drawer-content-container mb-3 ]">
              {choice && addExpenseListComponent}
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                      onClick={(event) => this.handleAddExpense(event)}
                    >
                      <span className="[ fas fa-plus ]" />
                      {STRINGS.EXPENSE.FORM.ADD_BTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

Expense.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  expenseList: PropTypes.arrayOf(PropTypes.object),
};

Expense.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default Expense;
