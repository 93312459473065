import { call, put, takeLatest } from "redux-saga/effects";
import GetApplicationRequest from "Communication/ApiClasses/GetApplicationRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import { CALL_COMPLETE_APPLICANT_API } from "../Actions/actionTypes";
import GetCompleteApplicantRequest from "../../Communication/ApiClasses/GetCompleteApplicantRequest";

// worker
function* handleApplicant(action) {
  yield put(doLoadingStart(actionTypes.CALL_COMPLETE_APPLICANT_API));
  const getCompleteApplicant = new GetCompleteApplicantRequest(action.id);
  try {
    const result = yield call(getCompleteApplicant.getData);
    yield put({
      type: `${actionTypes.CALL_COMPLETE_APPLICANT_API}_SUCCESS`,
      response: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.CALL_COMPLETE_APPLICANT_API}_ERROR`,
      error,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.CALL_COMPLETE_APPLICANT_API));
  }
}

// watcher
export default function* applicantWatcherSaga() {
  yield takeLatest(actionTypes.CALL_COMPLETE_APPLICANT_API, handleApplicant);
}
