import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonOpenAccount: "",
      relationshipBusiness: "",
      accountStatementType: "",
      businessBranch: "",
      errors: {
        reasonOpenAccountErrorList: [],
        relationshipBusinessErrorList: [],
        accountStatementTypeErrorList: [],
        businessBranchErrorList: [],
      },
      formIsValid: true,
      testOptions: [
        { code: "yes", description: "Yes" },
        { code: "no", description: "No" },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_START,
      (response) => {
        console.log("GET DATA FROM SERVER", response);
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        reasonOpenAccount: activeProduct?.reasonOpenAccount ?? "",
        relationshipBusiness: activeProduct?.relationshipBusiness ?? "",
        accountStatementType: activeProduct?.accountStatementType ?? "",
        businessBranch: activeProduct?.businessBranch ?? "",
      });
    }
  }

  setFormData = (response) => {
    console.log("response", response);
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    const {
      reasonOpenAccount,
      relationshipBusiness,
      accountStatementType,
      businessBranch,
    } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({
        applicant: {
          contact: {},
          member: {
            branch: businessBranch,
          },
        },
        vault: {
          reasonOpenAccount,
          relationshipBusiness,
          accountStatementType,
          businessBranch,
        },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_START);
  };
  handleChoice = (value) => {
    const { errors } = this.state;
    this.setState({
      accountStatementType: value,
      errors: {
        ...errors,
        accountStatementTypeErrorList: "",
      },
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target.name, event?.target.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    const {
      reasonOpenAccount,
      relationshipBusiness,
      accountStatementType,
      businessBranch,
      errors,
    } = this.state;

    let formIsValid = true;
    const reasonOpenAccountRes = checkErrors(
      reasonOpenAccount,
      VALIDATIONS.BUSINESS_START.REASON_OPEN_ACCOUNT
    );
    const relationshipBusinessRes = checkErrors(
      relationshipBusiness,
      VALIDATIONS.BUSINESS_START.RELATIONSHIP_BUSINESS
    );
    const businessBranchRes = checkErrors(
      businessBranch,
      VALIDATIONS.BUSINESS_START.BUSINESS_BRANCH
    );
    const accountStatementTypeRes = checkErrors(
      accountStatementType,
      VALIDATIONS.BUSINESS_START.ACCOUNT_STATEMENT_TYPE
    );

    this.setState({
      errors: {
        ...errors,
        reasonOpenAccountErrorList: reasonOpenAccountRes,
        relationshipBusinessErrorList: relationshipBusinessRes,
        businessBranchErrorList: businessBranchRes,
        accountStatementTypeErrorList: accountStatementTypeRes,
      },
    });
    if (
      reasonOpenAccountRes.length > 0 ||
      relationshipBusinessRes.length > 0 ||
      businessBranchRes.length > 0 ||
      accountStatementTypeRes.length > 0
    ) {
      formIsValid = false;
    }

    return formIsValid;
  };

  handleOverdraftBtn = (flag) => {
    this.setState({
      isOverdraftProtected: flag,
    });
  };

  render() {
    const { steps, activeStepID, finInfo } = this.props;
    const {
      reasonOpenAccount,
      accountStatementType,
      relationshipBusiness,
      businessBranch,
      testOptions,
      errors,
    } = this.state;

    const reasonList = finInfo?.config?.businessReason?.map((data, idx) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code + "-" + idx + "-" + data.description}
      >
        {data.description}
      </option>
    ));

    const relationList = finInfo?.config?.businessRelation?.map((data, idx) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code + "-" + idx + "-" + data.description}
      >
        {data.description}
      </option>
    ));

    const branchList = finInfo?.config?.businessBranch?.map((data, idx) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code + "-" + idx + "-" + data.description}
      >
        {data.description}
      </option>
    ));

    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ getting-started-container ] [ business ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_START.TITLE}</div>

        <div className="[ form-container ]">
          {/* Reason To Open An Account */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_START.FORM.DECISION_TO_OPEN_AN_ACCOUNT}
            </div>
            <select
              name="reasonOpenAccount"
              value={reasonOpenAccount}
              className={
                errors?.reasonOpenAccountErrorList &&
                errors?.reasonOpenAccountErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {reasonList}
            </select>
            <div className="[ error-msg ]">
              {errors?.reasonOpenAccountErrorList &&
              errors?.reasonOpenAccountErrorList.length > 0
                ? errors?.reasonOpenAccountErrorList[0]
                : []}
            </div>
          </div>

          {/* Relationship Business */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_START.FORM.RELATIONSHIP_BUSINESS}
            </div>
            <select
              name="relationshipBusiness"
              value={relationshipBusiness}
              className={
                errors?.relationshipBusinessErrorList &&
                errors?.relationshipBusinessErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {relationList}
            </select>
            <div className="[ error-msg ]">
              {errors?.relationshipBusinessErrorList[0]}
            </div>
          </div>

          {/* Branch */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_START.FORM.BRANCH}
            </div>
            <select
              name="businessBranch"
              value={businessBranch}
              className={
                errors?.businessBranchErrorList &&
                errors?.businessBranchErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {branchList}
            </select>
            <div className="[ error-msg ]">
              {errors?.businessBranchErrorList[0]}
            </div>
          </div>

          {/* Receive Account Statement */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_START.FORM.RECEIVE_ACCOUNT_STATEMENTS}
            </div>
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    accountStatementType === "paper"
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="accountStatementType"
                  onClick={(event) => this.handleChoice("paper", event)}
                >
                  {STRINGS.BUSINESS_START.PAPER}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    accountStatementType === "email"
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  name="accountStatementType"
                  onClick={(event) => this.handleChoice("email", event)}
                >
                  {STRINGS.BUSINESS_START.EMAIL}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">
              {errors?.accountStatementTypeErrorList[0]}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessStart.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessStart.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessStart;
