import * as actionTypes from "Redux/Actions/actionTypes";

export const doLoadingStart = (loading = "99999", message) => ({
  type: actionTypes.LOADING_START,
  loading,
  message,
});

export const doLoadingFinish = (loading = "99999") => ({
  type: actionTypes.LOADING_FINISH,
  loading,
});
