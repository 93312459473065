import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import UpdateContract from "../../Communication/ApiClasses/UpdateContract";

// worker
function* handleUpdateContract(action) {
  yield put(
    doLoadingStart(
      `${actionTypes.UPDATE_CONTRACT}_SAGA`,
      action.payLoad.waitMessage
    )
  );
  delete action.payLoad.waitMessage;
  const updateContract = new UpdateContract(
    action.applicationId,
    action.applicationType,
    action.productId,
    action.payLoad
  );
  try {
    const result = yield call(updateContract.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.UPDATE_CONTRACT}_ERROR`,
      error: error.response?.data.description,
    });
  } finally {
    yield put(doLoadingFinish(`${actionTypes.UPDATE_CONTRACT}_SAGA`));
  }
}

// watcher
export default function* handleUpdateContractSaga() {
  yield takeLatest(actionTypes.UPDATE_CONTRACT, handleUpdateContract);
}
