import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";

import STRINGS from "Constants/Strings";

import {
  storeFilterProduct,
  storeFilterBankingType,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { isScanningAllowed } from "Utils/CommonUtilities";
import AppConstants from "../../../Constants/AppConstants";

class FilterBar extends Component {
  constructor(props) {
    super(props);
    const isMobile = isScanningAllowed();
    this.state = {
      bankDropdownArray: [
        {
          code: AppConstants.BANKING_TYPE.PERSONAL,
          description: STRINGS.MARKETPLACE.BODY.PERSONAL_BANKING,
          className: "[ default ] [ dropdown__option ]",
        },
        {
          code: AppConstants.BANKING_TYPE.BUSINESS,
          description: STRINGS.MARKETPLACE.BODY.BUSINESS_BANKING,
          className: "[ default ] [ dropdown__option ]",
        },
      ],
      filterByOpen: true,
      categoryOpen: !isMobile,
      selectedCategory: [],
      chkCategoryList: [],
      allCheck: true,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { categoryList, selectedBankingType } = this.props;
    if (
      prevProps.categoryList !== categoryList ||
      prevProps.selectedBankingType !== selectedBankingType
    ) {
      this.setState({ selectedBankingType }, () => {
        this.updateCategoryList();
      });
    }
  }

  checkBoxHandler = (event) => {
    console.log("checkBoxHandler", event.target?.value, event.target?.checked);
    const {
      categoryList,
      productList,
      doStoreFilterProduct,
      setAllCheck,
      selectedBankingType,
    } = this.props;
    const { selectedCategory, allCheck } = this.state;
    console.log("selectedCategory", selectedCategory);

    const index = selectedCategory.indexOf(event.target.value);
    if (event.target.checked) {
      if (index === -1) {
        selectedCategory.push(event.target.value);
      }
    } else {
      if (index !== -1) {
        selectedCategory.splice(index, 1);
      }
    }

    let resultAllCheck = true;
    if (event.target.value === STRINGS.MARKETPLACE.BODY.ALL) {
      if (event.target.checked) {
        this.setState({ allCheck: true });
        this.updateCategoryList();
        return;
      } else {
        this.setState({ allCheck: false });
        selectedCategory.splice(0, selectedCategory.length);
      }
    } else if (event.target.checked === false && allCheck) {
      this.setState({ allCheck: false });
    }

    this.setState({ selectedCategory });

    console.log("new selectedCategory", selectedCategory);

    const resultcategoryList = categoryList.map((item) => {
      const temp = { ...item };
      if (selectedCategory.includes(temp.description)) {
        temp.checked = true;
      } else {
        temp.checked = false;
      }
      if (!temp.checked) resultAllCheck = false;
      return temp;
    });
    console.log("resultcategoryList", resultcategoryList);

    const resultProductList = productList.map((item) => {
      const productListItem = { ...item };
      if (selectedCategory.includes(productListItem.id)) {
        productListItem.show = true;
      } else {
        productListItem.show = false;
      }
      return productListItem;
    });
    console.log("resultProductList", resultProductList);

    doStoreFilterProduct(resultProductList, resultcategoryList);
    setAllCheck(resultAllCheck);
  };

  showCollapse = () => {
    const { categoryOpen } = this.state;
    this.setState({ categoryOpen: !categoryOpen });
  };

  showFilterByCollapse = () => {
    const { filterByOpen } = this.state;
    this.setState({ filterByOpen: !filterByOpen });
  };

  updateCategoryList() {
    const { categoryList, productList, doStoreFilterProduct } = this.props;
    const { selectedBankingType } = this.state;
    console.log("updateCategoryList", selectedBankingType);
    const selectedCategory = [];
    const chkCategoryList = categoryList.filter(
      (item) =>
        item.show &&
        (item.type === selectedBankingType ||
          (selectedBankingType === AppConstants.BANKING_TYPE.PERSONAL &&
            !item.type))
    );
    chkCategoryList.forEach((item) => {
      selectedCategory.push(item.description);
    });
    console.log(chkCategoryList);
    console.log("new selectedCategory", selectedCategory);
    this.setState({
      chkCategoryList,
      selectedCategory,
    });
    const resultProductList = productList.map((item) => {
      const productListItem = { ...item };
      if (selectedCategory.includes(productListItem.id)) {
        productListItem.show = true;
      } else {
        productListItem.show = false;
      }
      return productListItem;
    });
    doStoreFilterProduct(resultProductList, chkCategoryList);
  }

  setBankingType = (event) => {
    const { setBankingType, doStoreFilterBankingType } = this.props;
    setBankingType(event.target.value);
    doStoreFilterBankingType(event.target.value);
  };

  render() {
    const { selectedBankingType } = this.props;
    const {
      allCheck,
      selectedCategory,
      bankDropdownArray,
      filterByOpen,
      categoryOpen,
      chkCategoryList,
    } = this.state;
    console.log("chkCategoryList", chkCategoryList);
    const checkboxList = chkCategoryList.map((elem) => (
      <div
        className="[ col-12 ] [ d-flex align-items-center ] [ checkbox ]"
        key={elem.code}
      >
        <input
          type="checkbox"
          value={elem.description}
          onChange={(event) => this.checkBoxHandler(event)}
          checked={selectedCategory.includes(elem.description)}
          id={elem.description}
        />
        <label className="[ checkbox-text ]" htmlFor={elem.description}>
          {elem.description}
        </label>
      </div>
    ));
    const categoryComponent = (
      <div className="[ category-component ]">
        <div
          className="[ row ] [ mx-0 ] [ justify-content-between ]"
          tabIndex={0}
          role="button"
          onKeyDown={this.showCollapse}
          onClick={this.showCollapse}
          aria-controls="category-collapse-text"
          aria-expanded={categoryOpen}
        >
          <div className="[ form-subtitle ]">
            {STRINGS.MARKETPLACE.BODY.CATEGORY}
          </div>
          <div className="">
            <span
              className={
                categoryOpen
                  ? "[ icon ] [ collapse-icon-minus ]"
                  : "[ icon ] [ collapse-icon-plus ]"
              }
            />
          </div>
        </div>
        <Collapse in={categoryOpen} ref={this.collapseWrapper}>
          <div className="[ row ]" id="[ category-collapse-text ]">
            <div className="[ col-12 ] [ d-flex align-items-center ] [ checkbox ] ">
              <input
                type="checkbox"
                value={STRINGS.MARKETPLACE.BODY.ALL}
                onChange={(event) => this.checkBoxHandler(event)}
                checked={allCheck}
                id={STRINGS.MARKETPLACE.BODY.ALL}
              />
              <label
                className="[ checkbox-text ]"
                htmlFor={STRINGS.MARKETPLACE.BODY.ALL}
              >
                {STRINGS.MARKETPLACE.BODY.ALL}
              </label>
            </div>
            {checkboxList}
          </div>
        </Collapse>
      </div>
    );

    return (
      <aside className="[ col-12 col-xl-2 ]">
        <div className="[ product-filters ]">
          <div className="[ vault-header__container ]">
            <h4 className="[ form-subtitle ]">
              {/* <i className="[ fas fa-filter ]" /> */}
              {STRINGS.MARKETPLACE.BODY.FILTERS}
            </h4>
          </div>
          <div className="[ row ] [ banking-type ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.MARKETPLACE.BODY.SEE_PRODUCTS}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id="bankingTypeDropdownList"
                  value={selectedBankingType}
                  onChange={this.setBankingType}
                >
                  {bankDropdownArray.map((e) => (
                    <option key={e.code} className={e.className} value={e.code}>
                      {e.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="[ row ] [ product-category ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.MARKETPLACE.BODY.FILTER_BY}
                </div>
                {categoryComponent}
              </div>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

FilterBar.propTypes = {
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doStoreFilterProduct: PropTypes.func.isRequired,
  doStoreFilterBankingType: PropTypes.func.isRequired,
  allCheck: PropTypes.bool.isRequired,
  setAllCheck: PropTypes.func.isRequired,
  selectedBankingType: PropTypes.string,
  bankingType: PropTypes.string,
};

FilterBar.defaultProps = {
  categoryList: [],
  productList: [],
  selectedBankingType: AppConstants.BANKING_TYPE.PERSONAL,
  bankingType: AppConstants.BANKING_TYPE.PERSONAL,
};

const mapStateToProps = (state) => ({
  categoryList: state.MarketplaceReducer.categoryList,
  productList: state.MarketplaceReducer.productList,
  bankingType: state.MarketplaceReducer.bankingType,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreFilterProduct: storeFilterProduct,
      doStoreFilterBankingType: storeFilterBankingType,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
