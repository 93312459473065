import React from "react";
import PropTypes from "prop-types";
import * as ROUTES from "Constants/Routes";
// custom components
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import resetStore from "Redux/Actions/ResetStoreAction";
import doLogout from "Redux/Actions/LogoutAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import "Components/Common/PopupModal/PopupModal.scss";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import {
  ERROR_CODE,
  HTTP_STATUS,
  S_APPLICANT_ID,
  TOKEN_KEY,
} from "Communication/Constants";
import RefreshTokenRequest from "Communication/ApiClasses/RefreshTokenRequest";
// Axios
import Axios from "Communication/Axios";

import { setApiImplementation } from "Communication/BaseApi";
import {
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
} from "../../../Communication/Constants";

/**
 * this is communication hoc
 * providex axios to component
 * use this HOC at higher level because it dosent pass recieved props to child
 * except version
 */
export default function withCommunication(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        modalType: AppConstants.MODALTYPE.FAILURE,
        modal: {
          title: "",
          description: "",
        },
        errorCode: "",
        validationErrorAttempts: 0,
        tokenRefreshAttempts: 0,
      };
      /**
       * rest api
       */
      this.axiosInstance = new Axios(
        this.showErrorModal,
        this.generateAccessToken,
        this.goToLoginPage
      );

      setApiImplementation(this.axiosInstance);
    }

    componentWillUnmount() {
      this.axiosInstance.destructor();
    }

    toggleModal = () => {
      this.setState((prevState) => {
        return { showModal: !prevState.showModal };
      });
    };

    showErrorModal = (code = 0, message) => {
      const { tokenRefreshAttempts } = this.state;
      if (tokenRefreshAttempts === 1) {
        this.setState({
          showModal: true,
          modal: {
            title: STRINGS.COMMON.USER_SESSION_EXPIRED_ERROR,
            description: "",
          },
          errorCode: code,
        });
      } else {
        this.setState({
          showModal: true,
          modal: {
            title: message,
            description: "",
          },
          errorCode: code,
        });
      }
    };

    fetchAccessToken = () => {
      const RefreshTokenApi = new RefreshTokenRequest(
        sessionStorage.getItem(TOKEN_KEY)
      );

      RefreshTokenApi.getData().then((res) => {
        sessionStorage.setItem(TOKEN_KEY, res.data.token);
      });
    };

    generateAccessToken = () => {
      const { doLogoutAction, history, resetStoreAction } = this.props;
      const { tokenRefreshAttempts } = this.state;

      if (tokenRefreshAttempts === 1) {
        doLogoutAction((x) => {
          resetStoreAction((y) => {
            history.push(ROUTES.MARKETPLACE);
          });
        });
      } else {
        setTimeout(() => {
          this.fetchAccessToken();
        }, 60000);
      }
    };

    goToLoginPage = (isLogout = false) => {
      const { doLogoutAction, resetStoreAction, history } = this.props;
      if (isLogout) {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(EXPIRES_AT);
        sessionStorage.removeItem(REFRESH_EXPIRES_AT);
        sessionStorage.removeItem(S_APPLICANT_ID);
        resetStoreAction((res) => {
          history.push(ROUTES.MARKETPLACE);
        });
      } else {
        doLogoutAction((res) => {
          if (res.status === HTTP_STATUS.OK) {
            history.push(ROUTES.MARKETPLACE);
          }
        });
      }
    };

    validationErrorAttemptsCallback = () => {
      const { validationErrorAttempts } = this.state;
      if (validationErrorAttempts === 1) {
        this.goToLoginPage(true);
      } else {
        const accessToken = sessionStorage.getItem(TOKEN_KEY);
        if (accessToken) this.fetchAccessToken();
      }
    };

    tokenRefreshAttemptsCallback = () => {
      const { tokenRefreshAttempts } = this.state;
      if (tokenRefreshAttempts === 1) {
        this.goToLoginPage(true);
      } else {
        this.fetchAccessToken();
      }
    };

    handlePopupClick = (errorCode) => {
      const { history } = this.props;
      const token = sessionStorage.getItem(TOKEN_KEY);
      if (token) {
        console.log("handlePopupClick", errorCode);
        if (
          errorCode === ERROR_CODE.USER_DUPLICATE_LOGIN ||
          errorCode === ERROR_CODE.ACCESS_DENIED ||
          errorCode === ERROR_CODE.NO_ACTIVITY
        ) {
          this.goToLoginPage(true);
        } else if (errorCode === ERROR_CODE.VALIDATION_ERROR) {
          const { validationErrorAttempts } = this.state;
          this.setState(
            {
              validationErrorAttempts: validationErrorAttempts + 1,
            },
            this.validationErrorAttemptsCallback
          );
        } else if (errorCode === ERROR_CODE.TOO_EARLY_REFRESH) {
          const { tokenRefreshAttempts } = this.state;
          this.setState(
            {
              tokenRefreshAttempts: tokenRefreshAttempts + 1,
            },
            this.tokenRefreshAttemptsCallback
          );
        } else if (errorCode === ERROR_CODE.EXISTING_MEMBER) {
          history.push(ROUTES.EXISTING_MEMBER);
        } else if (errorCode === ERROR_CODE.USER_INACTIVE) {
          this.goToLoginPage(true);
        }
      }
    };

    render() {
      const { version } = this.props;
      const { modalType, modal, showModal, errorCode } = this.state;
      return (
        <div>
          <WrappedComponent
            {...this.props}
            axios={this.axios}
            version={version}
          />
          {showModal && (
            <PopupModal
              type={modalType}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showModal}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
              popupBtnClick={() => this.handlePopupClick(errorCode)}
              closeBtnClick={() => this.handlePopupClick(errorCode)}
            />
          )}
        </div>
      );
    }
  }

  HOC.propTypes = {
    accessToken: PropTypes.objectOf(PropTypes.any),
    refreshToken: PropTypes.string,
    setAccessToken: PropTypes.func,
    history: PropTypes.objectOf(PropTypes.any),
    version: PropTypes.string,
    resetStoreAction: PropTypes.func,
    doLogoutAction: PropTypes.func,
  };

  HOC.defaultProps = {
    accessToken: {},
    refreshToken: "",
    setAccessToken: PropTypes.func,
    history: {},
    version: undefined,
    doLogoutAction: PropTypes.func,
    resetStoreAction: PropTypes.func,
  };

  const mapStateToProps = (state) => ({
    accessToken: state.VerificationCodeReducer,
  });

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        doLogoutAction: doLogout,
        resetStoreAction: resetStore,
      },
      dispatch
    );
  };

  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
}
