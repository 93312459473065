import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";
import { HTTP_STATUS } from "../../Communication/Constants";
import PopupModal from "../Common/PopupModal/PopupModal";
import Messages from "../../Constants/Messages";

class BusinessLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessLookup: "",
      legalBusinessName: "",
      businessNumber: "",
      selectedBusiness: "",
      registeredProvince: "",
      showMultiple: false,
      businessLookupErrorList: [],
      legalBusinessNameErrorList: [],
      businessNumberErrorList: [],
      selectedBusinessErrorList: [],
      registeredProvincerErrorList: [],
      enableContinue: false,
      businessList: [],
      selectedSearchResult: null,
      selectedEntity: null,
      businessNameList: [],
      jurisdiction: null,
      jurisdictionErrorList: [],
      formIsValid: true,
      testOptions: [
        { code: "ontario", description: "Ontario" },
        { code: "vancouver", description: "Vancouver" },
      ],
      searchCtr: 0,
      searchResult: {},
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_LOOKUP,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        businessLookup: activeProduct?.businessLookup ?? "",
        legalBusinessName: activeProduct?.legalBusinessName ?? "",
        businessNumber: activeProduct?.businessNumber ?? "",
        selectedBusiness: activeProduct?.selectedBusiness ?? "",
        registeredProvince: activeProduct?.registeredProvince ?? "",
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { selectedSearchResult, selectedEntity } = this.state;
    if (selectedEntity) {
      this.continueWithSelectedEntity();
    } else {
      const valid = this.verifyData();

      if (valid) {
        this.getEntity(selectedSearchResult);
      }
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_LOOKUP);
  };

  handleChoice = (value) => {
    this.setState({
      businessLookup: value,
      businessLookupErrorList: "",
      showMultiple: false,
      selectedEntity: null,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target.name, event?.target.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    if (eventName === "selectedBusiness") {
      if (eventValue) {
        this.setState({ enableContinue: true, selectedEntity: null });
      }
    }

    this.setState(
      {
        [eventName]: eventValue,
        [errorObject]: "",
        selectedSearchResult: eventValue,
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    const {
      businessLookup,
      legalBusinessName,
      jurisdiction,
      businessNumber,
      selectedBusiness,
      registeredProvince,
      businessLookupErrorList,
      legalBusinessNameErrorList,
      businessNumberErrorList,
    } = this.state;
    let formIsValid = true;
    const businessLookupRes = checkErrors(
      businessLookup,
      VALIDATIONS.BUSINESS_LOOKUP.BUSINESS_LOOKUP
    );
    let legalBusinessNameRes = [];
    let businessNumberRes = [];
    let jurisdictionRes = [];
    let selectedBusinessRes = [];
    if (businessLookup === "legal_business_name") {
      legalBusinessNameRes = checkErrors(
        legalBusinessName,
        VALIDATIONS.BUSINESS_LOOKUP.LEGAL_BUSINESS_NAME
      );
    } else {
      jurisdictionRes = checkErrors(
        jurisdiction,
        VALIDATIONS.BUSINESS_LOOKUP.BUSINESS_JURISDICTION
      );
    }

    // TODO uncomment
    // if (businessLookup === "business_number") {
    //   businessNumberRes = checkErrors(
    //     businessNumber,
    //     VALIDATIONS.BUSINESS_LOOKUP.BUSINESS_NUMBER
    //   );
    // }

    // const selectedBusinessRes = checkErrors(
    //   selectedBusiness,
    //   VALIDATIONS.BUSINESS_LOOKUP.ACCOUNT_STATEMENT_TYPE
    // );
    // const registeredProvinceRes = checkErrors(
    //   registeredProvince,
    //   VALIDATIONS.BUSINESS_LOOKUP.ACCOUNT_STATEMENT_TYPE
    // );

    this.setState({
      businessLookupErrorList: businessLookupRes,
      legalBusinessNameErrorList: legalBusinessNameRes,
      businessNumberErrorList: businessNumberRes,
      jurisdictionErrorList: jurisdictionRes,
      // selectedBusinessErrorList: selectedBusinessRes,
      // registeredProvincerErrorList: registeredProvinceRes,
    });
    if (
      businessLookupRes.length > 0 ||
      legalBusinessNameRes.length > 0 ||
      businessNumberRes.length > 0 ||
      jurisdictionRes.length > 0
    ) {
      formIsValid = false;
    }
    return formIsValid;
  };

  handleOverdraftBtn = (flag) => {
    this.setState({
      isOverdraftProtected: flag,
    });
  };

  searchName = () => {
    const { doSearchEsc, finInfo } = this.props;
    const { legalBusinessName, searchCtr } = this.state;

    console.log("searchName", legalBusinessName);
    if (!this.verifyData()) {
      return;
    }
    this.setState({ selectedEntity: null }, () => {
      doSearchEsc({ entityName: legalBusinessName }, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          if (res.data?.results?.length === 1) {
            this.setState(
              {
                searchResult: res.data.results,
              },
              () => {
                this.getEntity(res.data.results[0].resultId);
              }
            );
          } else if (res.data?.results?.length > 1) {
            const businessList = [];
            res.data.results.forEach((x) =>
              businessList.push({ code: x.resultId, description: x.entityName })
            );
            this.setState({
              showMultiple: true,
              businessList,
              searchResult: res.data.results,
            });
          } else {
            if (finInfo.searchLimit && searchCtr + 1 === finInfo.searchLimit) {
              this.setState({
                showModal: true,
              });
            } else {
              this.setState({
                legalBusinessNameErrorList: [
                  STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_NAME_NOT_FOUND,
                ],
                searchCtr: searchCtr + 1,
              });
            }
          }
        }
      });
    });
  };

  getEntity = (resultId) => {
    console.log("getEntity", resultId);
    const { doRequestEsc, doProfileEsc } = this.props;
    const {
      businessLookup,
      legalBusinessName,
      businessNumber,
      selectedBusiness,
      registeredProvince,
    } = this.state;
    doRequestEsc({ resultId }, (requestRes) => {
      if (requestRes.status === HTTP_STATUS.OK) {
        doProfileEsc(
          { orderId: requestRes.data.orderId, retry: 2 },
          (profileRes) => {
            console.log(profileRes);
            if (profileRes.status === HTTP_STATUS.OK) {
              if (profileRes.data.status === "Complete") {
                this.setState({
                  selectedEntity: profileRes.data,
                  enableContinue: true,
                });
              } else {
                const { searchResult } = this.state;
                console.log(searchResult, resultId);
                const selected = searchResult.find(
                  (x) => x.resultId === parseInt(resultId)
                );
                this.setState({
                  orderId: requestRes.data.orderId,
                  selectedEntity: {
                    entity: {
                      name: selected.entityName,
                      number: selected.entityNumber,
                      jurisdiction: selected.jurisdiction,
                      formationDate: selected.formationDate,
                      addresses: [],
                    },
                  },
                  enableContinue: true,
                });
              }
            }
          }
        );
      }
    });
  };
  searchNumber = () => {
    const { doSearchEsc, doRequestEsc, handleContinue, finInfo } = this.props;
    const {
      businessLookup,
      legalBusinessName,
      businessNumber,
      selectedBusiness,
      registeredProvince,
      jurisdiction,
      searchCtr,
    } = this.state;

    console.log("searchNumber", businessNumber);

    if (!this.verifyData()) {
      return;
    }
    this.setState({ selectedEntity: null }, () => {
      doSearchEsc({ entityNumber: businessNumber, jurisdiction }, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          if (res.data?.results?.length === 1) {
            this.setState(
              {
                searchResult: res.data.results,
              },
              () => {
                this.getEntity(res.data.results[0].resultId);
              }
            );
          } else if (res.data?.results?.length > 1) {
            const businessList = [];
            res.data.results.forEach((x) =>
              businessList.push({ code: x.resultId, description: x.entityName })
            );
            this.setState({ showMultiple: true, businessList });
          } else {
            if (finInfo.searchLimit && searchCtr + 1 === finInfo.searchLimit) {
              this.setState({
                showModal: true,
              });
            } else {
              this.setState({
                businessNumberErrorList: [
                  STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_NUMBER_NOT_FOUND,
                ],
                searchCtr: searchCtr + 1,
              });
            }
          }
        }
      });
    });
  };

  continueWithSelectedEntity = () => {
    const { handleContinue } = this.props;
    const {
      businessLookup,
      legalBusinessName,
      businessNumber,
      selectedBusiness,
      registeredProvince,
      selectedEntity,
      orderId,
    } = this.state;
    handleContinue({
      orderId,
      businessLookup,
      legalBusinessName,
      selectedBusiness,
      registeredProvince,
      entity: selectedEntity.entity,
      parties: selectedEntity.parties,
      businessNumber: selectedEntity.entity.businessNumber,
      incorporationNumber: "",
      jurisdictionIncorporation: selectedEntity.entity.jurisdiction,
      businessRegistrationDate: selectedEntity.entity.registryFormationDate,
      businessStreetName:
        selectedEntity.entity.addresses.length > 0
          ? selectedEntity.entity.addresses[0].streetName
          : "",
      businessSuiteNo:
        selectedEntity.entity.addresses.length > 0
          ? selectedEntity.entity.addresses[0].unitNumber
          : "",
      businessStreetNumber:
        selectedEntity.entity.addresses.length > 0
          ? selectedEntity.entity.addresses[0].civicNumber
          : "",
      businessCity:
        selectedEntity.entity.addresses.length > 0
          ? selectedEntity.entity.addresses[0].city
          : "",
      businessProvince:
        selectedEntity.entity.addresses.length > 0
          ? selectedEntity.entity.addresses[0].province
          : "",
      businessPostalCode:
        selectedEntity.entity.addresses.length > 0
          ? selectedEntity.entity.addresses[0].postalCode
          : "",
    });
  };

  render() {
    const { steps, activeStepID, finInfo, noResultContinue } = this.props;
    const {
      legalBusinessName,
      legalBusinessNameErrorList,
      businessLookup,
      businessLookupErrorList,
      businessNumber,
      businessNumberErrorList,
      selectedBusiness,
      selectedBusinessErrorList,
      registeredProvince,
      registeredProvincerErrorList,
      testOptions,
      showMultiple,
      enableContinue,
      businessList,
      selectedEntity,
      jurisdiction,
      jurisdictionErrorList,
      showModal,
    } = this.state;

    const optionList = businessList.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const searchResultComponent = (
      <>
        <div className="[ mb-3 ]">
          <div className="[ alert alert-success ]">
            <div className="[ info-title ]">
              {STRINGS.BUSINESS_LOOKUP.FORM.FOUND_ONE_BUSINESS_HEADER}
            </div>
            <p className="[ info-message ]">
              {selectedEntity?.entity?.name}
              <br />
              {selectedEntity?.entity?.addresses?.length > 0 && (
                <>
                  {selectedEntity?.entity?.addresses[0].city},
                  {selectedEntity?.entity?.addresses[0].province}
                </>
              )}
            </p>
            <p className="[ info-message ]">
              {STRINGS.BUSINESS_LOOKUP.FORM.FOUND_ONE_BUSINESS_DESCRIPTION}
            </p>
            <p className="[ info-message ]">
              {businessLookup === "legal_business_name" && (
                <>
                  {STRINGS.BUSINESS_LOOKUP.FORM.FOUND_ONE_BUSINESS_DESCRIPTION1}
                </>
              )}
            </p>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                <button
                  type="button"
                  disabled={!enableContinue}
                  className="[ btn  submit-btn ] [ w-100 ]"
                  onClick={this.handleContinue}
                >
                  {STRINGS.COMMON.CONTINUEBTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const legalBusinessNameComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_LOOKUP.FORM.LEGAL_BUSINESS_NAME_TITLE}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_LOOKUP.FORM.LEGAL_BUSINESS_NAME_LABEL}
            </div>
            <div className="[ row ]">
              <div className="[ col-12 col-md-9 ] [ mb-2 mb-md-0 ]">
                <input
                  type="text"
                  id="legalBusinessName"
                  name="legalBusinessName"
                  className={
                    legalBusinessNameErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={
                    STRINGS.BUSINESS_LOOKUP.FORM.LEGAL_BUSINESS_NAME_PLACEHOLDER
                  }
                  value={legalBusinessName}
                  onChange={this.handleChange}
                />
                <div className="[ error-msg ]">
                  {legalBusinessNameErrorList[0]}
                </div>
              </div>
              <div className="[ col-12 col-md-3 ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ]"
                  onClick={this.searchName}
                >
                  {STRINGS.COMMON.SEARCHBTN}
                </button>
              </div>
            </div>
          </div>
          {showMultiple && (
            <div className="[ mb-3 ] [ alert alert-warning ]">
              <div className="[ info-title ]">
                {STRINGS.BUSINESS_LOOKUP.FORM.FOUND_MANY_BUSINESS_HEADER}
              </div>

              {/* Registered Province */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_LOOKUP.FORM.SELECT_BUSINESS}
                </div>
                <div className="[ row ] [ mb-3 ]">
                  <div className="[ col-12 ]">
                    <select
                      name={"selectedBusiness"}
                      className={
                        selectedBusinessErrorList &&
                        selectedBusinessErrorList.length > 0
                          ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                          : "[ form-control ] [ select__dropdown ]"
                      }
                      onChange={this.handleChange}
                      value={selectedBusiness}
                      id={STRINGS.BUSINESS_LOOKUP.FORM.SELECT_BUSINESS_ID}
                    >
                      <option
                        value=""
                        className="[ default ] [ dropdown__option ]"
                      >
                        {STRINGS.DROPDOWN_INITIAL_TEXT}
                      </option>
                      {optionList}
                    </select>
                  </div>
                </div>
                <div className="[ error-msg ]">
                  {legalBusinessNameErrorList[0]}
                </div>
                {!selectedEntity && (
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                        <button
                          type="button"
                          disabled={!enableContinue}
                          className="[ btn  submit-btn ] [ w-100 ]"
                          onClick={this.handleContinue}
                        >
                          {STRINGS.COMMON.CONTINUEBTN}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedEntity && searchResultComponent}
        </div>
      </div>
    );

    const businessNumberComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_LOOKUP.FORM.BUSINESS_NUMBER_TITLE}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_LOOKUP.FORM.BUSINESS_NUMBER_LABEL}
            </div>
            <input
              type="text"
              id="businessNumber"
              name="businessNumber"
              className={
                businessNumberErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              placeholder={
                STRINGS.BUSINESS_LOOKUP.FORM.BUSINESS_NUMBER_PLACEHOLDER
              }
              value={businessNumber}
              maxLength={AppConstants.BUSINESS_LOOKUP.BUSINESS_NUMBER_LENGTH}
              onChange={this.handleChange}
            />
            <div>
              <small>
                {STRINGS.BUSINESS_LOOKUP.FORM.BUSINESS_NUMBER_DESCRIPTION}
              </small>
            </div>
            <div className="[ error-msg ]">{businessNumberErrorList[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_LOOKUP.FORM.SELECT_BUSINESS_JURISDICTION}
            </div>
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <select
                  value={jurisdiction}
                  name={"jurisdiction"}
                  className={
                    jurisdictionErrorList && jurisdictionErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                      : "[ form-control ] [ select__dropdown ]"
                  }
                  onChange={this.handleChange}
                  id={"jurisdiction"}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.DROPDOWN_INITIAL_TEXT}
                  </option>
                  {finInfo?.config?.businessJurisdiction?.map((data) => (
                    <option
                      className="[ dropdown__option ]"
                      value={data.code}
                      key={data.code}
                    >
                      {data.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="[ error-msg ]">{jurisdictionErrorList[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ]"
                  onClick={this.searchNumber}
                >
                  {STRINGS.COMMON.SEARCHBTN}
                </button>
              </div>
            </div>
          </div>

          {selectedEntity && searchResultComponent}
        </div>
        {/* <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                disabled={!enableContinue}
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div> */}
      </div>
    );

    const actionComponent = (
      <div className="[ business-look-up-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_LOOKUP.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ] [ alert alert-info ]" role="alert">
            {/* <div className="[ row ]">
              <div className="[ col-12 ]">
                <h3 className="[ alert-heading ]">
                  {STRINGS.BUSINESS_LOOKUP.BUSINESS_REGISTRY}
                </h3>
              </div>
            </div> */}
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <p className="[ info-title ]">
                  {STRINGS.BUSINESS_LOOKUP.BUSINESS_REGISTRY_DESCRIPTION}
                </p>
                <p className="[ info-message ]">
                  {STRINGS.BUSINESS_LOOKUP.BUSINESS_REGISTRY_DESCRIPTION1}
                </p>
              </div>
            </div>
          </div>

          <div className="[ row ]">
            <div className="[ col-12 ]">
              {/* Receive Account Statement */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_LOOKUP.FORM.BUSINESS_NAME_NUMBER_LABEL}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        businessLookup === "legal_business_name"
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="businessLookup"
                      onClick={(event) =>
                        this.handleChoice("legal_business_name", event)
                      }
                    >
                      {STRINGS.BUSINESS_LOOKUP.LEGAL_BUSINESS_NAME}
                    </button>
                  </div>
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        businessLookup === "business_number"
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="businessLookup"
                      onClick={(event) =>
                        this.handleChoice("business_number", event)
                      }
                    >
                      {STRINGS.BUSINESS_LOOKUP.BUSINESS_NUMBER}
                    </button>
                  </div>
                </div>
              </div>
              {/* Legal Business name Component */}
              {businessLookup === "legal_business_name" &&
                legalBusinessNameComponent}

              {/* Business Number Component */}
              {businessLookup === "business_number" && businessNumberComponent}
            </div>
          </div>
          <div className="[ error-msg ]">{businessLookupErrorList[0]}</div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title={STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_NOT_FOUND_TITLE}
            description={
              STRINGS.BUSINESS_LOOKUP.ERROR.BUSINESS_NOT_FOUND_DESCRIPTION
            }
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={noResultContinue}
            closeBtnClick={noResultContinue}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessLookup.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessLookup.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessLookup;
