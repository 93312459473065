import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { QuestionCircle } from "react-bootstrap-icons";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";
import CardComponent from "../Common/CardComponent/CardComponent";
import LinkTermComponent from "../TermsAndConditions/LinkTermComponent";
import CheckBoxComponent from "../TermsAndConditions/CheckBoxComponent";
import { COMMON_BUCKET_URL } from "../../Communication/ApiUrls";
import FIInformation from "../../Constants/FIInformation";
import MODALMESSAGES from "../../Constants/Messages";

class BusinessInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      showHelp: false,
      choice: "",
      termsErrorText: "",
      selectedBusinessType: "",
      checkboxArray: [],
      linkTermsArray: [],
      modal: {
        title: null,
        description: MODALMESSAGES.TERMS_AND_CONDITIONS.INFO1.replace(
          AppConstants.TEMPLATE.PRODUCT,
          props.activeProduct.productName
        ),
        description2: MODALMESSAGES.TERMS_AND_CONDITIONS.INFO2,
      },
      modalType: AppConstants.MODALTYPE.INFORMATION,
      rendered: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_INFO,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { jsonData, activeProduct } = this.props;
    const { rendered } = this.state;
    let documentArray = [];
    const checkboxArray = [];
    const tempArray = [];
    if (jsonData && !rendered) {
      jsonData.map((data) => {
        // Check if object has any documents
        let addTerms = false;
        console.log("terms data", data);
        if (activeProduct.bankingType === AppConstants.BANKING_TYPE.BUSINESS) {
          if (data.bankingType === AppConstants.BANKING_TYPE.BUSINESS) {
            addTerms = true;
          }
        } else {
          addTerms = true;
        }
        if (data.document && addTerms) {
          documentArray = data.document;
          documentArray.map((document) => {
            // push the elements to temperory array
            tempArray.push({
              description: document.description,
              filename: document.filename,
            });
            return null;
          });
        }

        // Only accept terms
        if (addTerms) {
          checkboxArray.push({
            mandatory: data.mandatory,
            description: data.description,
            code: data.code,
            consent: data.consent,
            checked: false,
          });
        }
        return null;
      });

      // set the state variables
      this.setState({
        linkTermsArray: tempArray,
        checkboxArray,
        rendered: true,
      });
    }

    if (prevProps !== this.props) {
      this.setState({
        selectedBusinessType: activeProduct?.selectedBusinessType ?? "",
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    if (valid) {
      handleContinue(property);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_INFO);
  };

  verifyData = () => {
    const { checkboxArray, termsAccepted } = this.state;
    let valid = true;

    // Get all mandatory checkboxes
    const checkboxes = checkboxArray;
    checkboxes.map((data, idx) => {
      console.log(data, idx);
      if (data.mandatory && !data.checked) {
        valid = false;
        checkboxes[idx].errorMessage = STRINGS.BUSINESS_INFO.AGREE_TERMS_ERROR;
      } else {
        checkboxes[idx].errorMessage = null;
      }
    });

    if (valid) {
      this.handleContinue();
    } else {
      this.setState({ checkboxes });
    }
  };

  onChangeCheckBoxHandler = (code, object, checked) => {
    const { checkboxArray } = this.state;
    console.log("onChangeCheckBoxHandler", code, object, checked);
    const index = checkboxArray.map((o) => o.code).indexOf(code);
    let termsAccepted;
    if (checked) {
      checkboxArray[index].checked = true;
    } else {
      checkboxArray[index].checked = false;
    }
    this.setState({ checkboxArray });
  };

  linkClickHandler = (fileName, name) => {
    // if filename is valid
    if (fileName) {
      // First reinitialize modal state
      this.setState({ showModal: false }, () => {
        // Initialize variables
        const modal = {};

        // Set data into modal variable
        modal.title = "";
        modal.description = (
          <object
            type="text/html"
            aria-label="Default"
            data={`${COMMON_BUCKET_URL}${fileName}`}
          />
        );

        // set values to state variables
        this.setState({
          showModal: true,
          modal,
          modalType: name,
        });
      });
    }
  };
  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const {
      choice,
      checkboxArray,
      linkTermsArray,
      evaluate,
      showModal,
      showHelp,
      helpText,
      modalType,
      modal,
      termsErrorText,
      selectedBusinessType,
    } = this.state;
    const { steps, activeStepID, jsonData, finInfo } = this.props;
    console.log(steps, activeStepID, jsonData);

    const cardComponentHeader = (title, classNameIcon) => (
      <div className="[ info-tile__header]">
        <div className="[ info-tile__icon ]">
          <i className={classNameIcon}></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor={title}>{title}</div>
        </div>
      </div>
    );
    const cardComponentFirst = (
      <div className="[ info-tile__header]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-birthday-cake ]"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="age">
            {STRINGS.BUSINESS_INFO.AGE_TEXT}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.BUSINESS_INFO.FIRST_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    const cardComponentTwo = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="fab fa-canadian-maple-leaf"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="sin">
            {STRINGS.BUSINESS_INFO.SIN_TEXT}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.BUSINESS_INFO.SECOND_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    const cardComponentThree = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-address-card ]"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="govtID">
            {STRINGS.BUSINESS_INFO.GOVT_ID}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.BUSINESS_INFO.THIRD_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    const cardComponentFour = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-chart-pie ]"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="membershare">
            {STRINGS.BUSINESS_INFO.MEMBERSHARE_TEXT}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.BUSINESS_INFO.FOURTH_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="[ terms-and-conditions-container ] [ business ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_INFO.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ row info-items ]">
              <div className="[ col-12 ]">
                <div className="[ form-subtitle ]">
                  {STRINGS.BUSINESS_INFO.TITLE}
                </div>
              </div>
              <CardComponent
                firstComponent={cardComponentTwo}
                secondComponent={STRINGS.BUSINESS_INFO.SECOND_CARD_TEXT}
              />
              <CardComponent
                firstComponent={cardComponentThree}
                secondComponent={STRINGS.BUSINESS_INFO.THIRD_CARD_TEXT}
              />
              <CardComponent
                firstComponent={cardComponentFour}
                secondComponent={STRINGS.BUSINESS_INFO.FOURTH_CARD_TEXT}
              />
            </div>
            <div className="[ divider ]" />
          </div>
          {finInfo?.config?.businessTypes?.find(
            (item) => item.code === selectedBusinessType
          )?.documents?.length > 0 ? (
            <>
              <div className="[ mb-3 ]">
                <div className="[ row info-items]">
                  <div className="[ col-12 ]">
                    <div className="[ form-subtitle ]">
                      {STRINGS.BUSINESS_INFO.ADDITIONAL_DOCUMENTS_TITLE}
                    </div>
                  </div>
                  {finInfo?.config?.businessTypes
                    ?.find((item) => item.code === selectedBusinessType)
                    ?.documents?.map((item, idx) => (
                      <CardComponent
                        firstComponent={cardComponentHeader(
                          item.title,
                          item.className,
                          item.id
                        )}
                        secondComponent={item.description}
                        key={idx} // Add a unique key for each iteration
                      />
                    ))}
                  <div className="[ form-description col-12 ]">
                    {STRINGS.BUSINESS_INFO.ADDITIONAL_DOCUMENTS_BODY}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="[ divider ]" />
          <div className="[ terms-and-conditions__links-container ]">
            <div className="[ alert alert-info ]" role="alert">
              <h4 className="[ alert-heading ]">
                {STRINGS.BUSINESS_INFO.AGREE_TERMS}
              </h4>
              <hr />
              {linkTermsArray.length > 0 && (
                <LinkTermComponent
                  linkTermsArray={linkTermsArray}
                  linkClickHandler={this.linkClickHandler}
                />
              )}
            </div>
          </div>
          <div className="[ divider ]" />
          <div>
            {checkboxArray.map((data) => (
              <CheckBoxComponent
                key={data.code}
                data={data}
                onChangeCheckBoxHandler={this.onChangeCheckBoxHandler}
                evaluate={evaluate}
                error={data.errorMessage}
              />
            ))}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            description2={modal.description2}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

BusinessInfo.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessInfo.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessInfo;
