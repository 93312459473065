import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import AboutYou from "./AboutYou";
import {
  doVerifyInstntDocument,
  doSubmitVerify,
  getVaultMember,
} from "./Actions/AboutYouActions";
import {
  updateProductToVault,
  updateVaultProductList,
} from "../MarketPlace/Action/MarketPlaceAction";
import AppConstants from "../../Constants/AppConstants";

class AboutYouContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returningMember: false,
    };
  }

  componentDidMount() {
    const {
      applicantData,
      applicationCompleteList,
      inSessionJointApplicant,
      doDisableBack,
      actionBack,
      doGetVaultMember,
      finInfo,
    } = this.props;
    const tempHide = sessionStorage.getItem("TEMP_HIDE");
    const memberInfoWelcomeShown = sessionStorage.getItem(
      AppConstants.SESSION.MEMBER_INFO_WELCOME_SHOWN
    );
    console.log(
      "componentDidMount",
      applicantData?.acquireType,
      applicationCompleteList.length,
      tempHide,
      inSessionJointApplicant,
      memberInfoWelcomeShown
    );
    if (applicantData && !inSessionJointApplicant) {
      doGetVaultMember((response) => console.log(response));
    }
    if (
      applicantData?.id &&
      !memberInfoWelcomeShown &&
      !inSessionJointApplicant &&
      !actionBack
    ) {
      this.setState({
        returningMember: true,
      });
    }
    if (inSessionJointApplicant) {
      doDisableBack(true);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      applicantData,
      inSessionJointApplicant,
      vaultProductList,
      getActiveProduct,
      doUpdateProductToVault,
    } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);
    if (
      !activeProduct?.applicantId &&
      applicantData?.id &&
      !inSessionJointApplicant
    ) {
      if (vaultProductList && vaultProductList.length > 0) {
        vaultProductList[
          vaultProductList.findIndex((x) => x === activeProduct)
        ].applicantId = applicantData.id;
        doUpdateProductToVault(vaultProductList);
      }
    }
  }

  render() {
    const { returningMember, vault } = this.state;
    const {
      doVerifyDocument,
      formData,
      doSubmitDocVerify,
      getDataFromServer,
      continuehandler,
      isEdit,
      handleBack,
      scanDocument,
      steps,
      activeStepID,
      vaultProductList,
      getActiveProduct,
      finInfo,
      inSessionJointApplicant,
      sessionCompletedProducts,
      history,
      applicantData,
    } = this.props;

    return (
      <AboutYou
        doSubmitDocVerify={doSubmitDocVerify}
        verifyDoc={doVerifyDocument}
        formData={formData}
        isInEditFlow={isEdit}
        getDataFromServer={getDataFromServer}
        continuehandler={continuehandler}
        handleBack={handleBack}
        scanDocument={scanDocument}
        steps={steps}
        activeStepID={activeStepID}
        vaultProductList={vaultProductList}
        getActiveProduct={getActiveProduct}
        returningMember={returningMember}
        finInfo={finInfo}
        inSessionJointApplicant={inSessionJointApplicant}
        sessionCompletedProducts={sessionCompletedProducts}
        vault={vault}
        history={history}
        applicantData={applicantData}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  formData: state.AboutYouReducer.formData,
  updateApplicant: state.ComplianceReducer,
  isEdit: state.ApplicationReducer.isEdit,
  scanDocument: state.ApplicationReducer.scanDocument,
  vaultProductList: state.VaultReducer.vaultProductList,
  applicantData: state.ApplicationReducer.response,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  finInfo: state.MarketplaceReducer.finInfo,
  actionBack: state.ApplicationReducer.actionBack,
  sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doVerifyDocument: (documentData, callback) =>
      dispatch(doVerifyInstntDocument(documentData, callback)),
    doSubmitDocVerify: (submitData, callback) =>
      dispatch(doSubmitVerify(submitData, callback)),
    doUpdateProductToVault: (vaultList, callback) =>
      dispatch(updateProductToVault(vaultList, callback)),
    doGetVaultMember: (callback) => dispatch(getVaultMember(callback)),
  };
};

AboutYouContainer.propTypes = {
  doVerifyDocument: PropTypes.func,
  doSubmitDocVerify: PropTypes.func.isRequired,
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  isEdit: PropTypes.bool,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  scanDocument: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  getActiveProduct: PropTypes.func,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  sessionCompletedProducts: PropTypes.arrayOf(PropTypes.object),
  inSessionJointApplicant: PropTypes.oneOfType([PropTypes.object]),
  history: PropTypes.oneOfType([PropTypes.object]),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};

AboutYouContainer.defaultProps = {
  history: {},
  doVerifyDocument: () => {},
  formData: null,
  isEdit: false,
  steps: [],
  activeStepID: -1,
  vaultProductList: [],
  getActiveProduct: () => {},
  finInfo: {},
  inSessionJointApplicant: null,
  applicantData: null,
  sessionCompletedProducts: [],
};

const AboutYouWrapper = withApplicantHOC(AboutYouContainer);

export default connect(mapStateToProps, mapDispatchToProps)(AboutYouWrapper);
