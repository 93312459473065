import { call, put, takeLatest } from "redux-saga/effects";
import { GET_VAULT_MEMBER } from "../../../Redux/Actions/actionTypes";
import GetVaultMemberRequest from "../../../Communication/ApiClasses/GetVaultMemberRequest";
import {
  doLoadingFinish,
  doLoadingStart,
} from "../../Common/ProgressIndicator/Actions/ProgressIndicatorActions";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(`${GET_VAULT_MEMBER}_SAGA`));
  const request = new GetVaultMemberRequest();
  try {
    const result = yield call(request.getData);
    yield put({
      type: `${GET_VAULT_MEMBER}_SUCCESS`,
      data: result.data,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${GET_VAULT_MEMBER}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${GET_VAULT_MEMBER}_SAGA`));
  }
}

// watcher
export default function* executeSaga() {
  yield takeLatest(GET_VAULT_MEMBER, handleSaga);
}
