import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import TaxReporting from "./TaxReporting";
import * as ROUTES from "Constants/Routes";
import {
  getCountryList,
  getEmployerPositionCodes,
  getIncomeSourcesList,
  getStatusList,
} from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { bindActionCreators } from "redux";
import {
  getCountriesTax,
  getOccupationCodes,
} from "../../Redux/Actions/ApplicationAction";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import { findCode } from "../../Utils/CommonUtilities";
import { isNewMember, showTaxInfo } from "../../Utils/LogicUtilities";
import { HTTP_STATUS } from "../../Communication/Constants";
import { updateVaultMember } from "../AboutYou/Actions/AboutYouActions";

class TaxReportingContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      newMember: true,
      countriesTax: [],
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      inSessionJointApplicant,
      processTemplate,
      continuehandler,
      actionBack,
      doDisableBack,
      applicantData,
      applicationCompleteList,
      handleBack,
      products,
      getActiveProduct,
      finInfo,
      sessionCompletedProducts,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    if (inSessionJointApplicant) {
      //check joint step exist in process template
      console.log(processTemplate);
      let step = processTemplate.flow.find(
        (s) => s.componentName === AppConstants.APPLICATIONSTEP.TAX_INFO
      );
      if (!step) {
        if (actionBack) {
          // no going back from employment page
          continuehandler(null, AppConstants.APPLICATIONSTEP.TAX_INFO);
        } else {
          continuehandler(null, AppConstants.APPLICATIONSTEP.TAX_INFO);
        }
      }

      // check employment is enabled as previous page
      step = processTemplate.flow.find(
        (s) => s.componentName === AppConstants.APPLICATIONSTEP.EMPLOYMENT
      );
      if (!step) {
        doDisableBack(true);
      }
    } else if (
      !showTaxInfo(
        applicationCompleteList,
        applicantData,
        null,
        activeProduct,
        finInfo,
        sessionCompletedProducts
      )
    ) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.TAX_INFO);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.TAX_INFO);
      }
    }
  }

  handleChange = () => {};

  handleContinue = (formDetails) => {
    console.log("handleContinue", formDetails);
    const {
      continuehandler,
      finInfo,
      vaultMember,
      doUpdateVaultMember,
      applicantData,
      inSessionJointApplicant,
      products,
      getActiveProduct,
    } = this.props;
    const {
      countryOption,
      countryName,
      areYouTaxResidentOfUsa,
      areYouTaxResidentOfOtherCountry,
      ssnUsa,
      ssn,
    } = formDetails;

    const selectedOption =
      countryOption.length > 1
        ? countryOption.find(
            (obj) => obj.taxResidentOtherName === formDetails.countryName
          )
        : countryOption[0];
    console.log("selectedOption", selectedOption);

    let taxResidency = "";
    if (selectedOption?.taxResidencyResult) {
      taxResidency = selectedOption?.taxResidencyResult?.taxResidency;
    }

    const fatcaInfo = {};

    if (countryName) {
      fatcaInfo.fatcaStatus =
        countryName === AppConstants.TAX_INFO.COUNTRY_USA ||
        countryName === AppConstants.TAX_INFO.COUNTRY_US ||
        countryName === AppConstants.TAX_INFO.COUNTRY_AMERICAN ||
        countryName === AppConstants.TAX_INFO.COUNTRY_UNITED_STATES ||
        areYouTaxResidentOfUsa
          ? finInfo.fatca
          : finInfo.noFatcaCrs;
      if (finInfo.useCRSTypeFieldFlag) {
        if (
          countryName === AppConstants.TAX_INFO.COUNTRY_USA ||
          countryName === AppConstants.TAX_INFO.COUNTRY_US ||
          countryName === AppConstants.TAX_INFO.COUNTRY_AMERICAN ||
          areYouTaxResidentOfUsa
        ) {
          fatcaInfo.fatcaType = finInfo.noFatcaCrs;
        } else {
          fatcaInfo.fatcaType = finInfo.oecdCrs;
        }
      } else {
        fatcaInfo.fatcaStatus = finInfo.oecdCrs;
      }
    } else {
      fatcaInfo.fatcaStatus = finInfo.noFatcaCrs;
      if (finInfo.useCRSTypeFieldFlag) {
        fatcaInfo.fatcaType = finInfo.noFatcaCrs;
      }
    }

    const updateRegulatoryFieldsInputModel = [];
    if (finInfo.useRegulatoryFieldsModel) {
      delete fatcaInfo.fatcaType;
      fatcaInfo.fatcaStatus = finInfo.noFatcaCrs;
      if (ssnUsa) {
        fatcaInfo.fatcaStatus = finInfo.fatca;
        taxResidency = finInfo.config.taxResidency.find(
          (item) => item.taxResidentUs
        )?.options[0].taxResidencyResult.taxResidency;
      }
      if (areYouTaxResidentOfOtherCountry) {
        if (!ssnUsa) {
          taxResidency = "";
        }
        updateRegulatoryFieldsInputModel.push({
          Code: 1,
          Value: finInfo.oecdCrs,
          Text: "OECD CRS Status",
          Type: "dropdown",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 2,
          Value: ssn,
          Text: "OECD CRS Tax ID",
          Type: "text",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 3,
          Value: selectedOption?.taxResidentOtherName,
          Text: "OECD Tax Residency",
          Type: "dropdown",
        });
      } else {
        updateRegulatoryFieldsInputModel.push({
          Code: 1,
          Value: finInfo.noFatcaCrs,
          Text: "OECD CRS Status",
          Type: "dropdown",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 2,
          Value: "",
          Text: "OECD CRS Tax ID",
          Type: "text",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 3,
          Value: "",
          Text: "OECD Tax Residency",
          Type: "dropdown",
        });
      }
    }

    const dispatchObject = {
      member: {
        sin: formDetails && formDetails.sin ? formDetails.sin : "",
      },
      regulatory: {
        ...fatcaInfo,
        taxResidency,
        ssn: formDetails && formDetails.ssn ? formDetails.ssn : "",
        taxResidencyOther: finInfo.useRegulatoryFieldsModel ? "" : countryName,
        updateRegulatoryFieldsInputModel,
      },
    };

    if (finInfo.useRegulatoryFieldsModel) {
      dispatchObject.regulatory.ssn =
        formDetails && formDetails.ssnUsa ? formDetails.ssnUsa : "";
    }
    if (!isNewMember(applicantData)) {
      if (inSessionJointApplicant) {
        doUpdateVaultMember(
          {
            ...inSessionJointApplicant.vault,
            sin: dispatchObject.member.sin,
            regulatoryFields: updateRegulatoryFieldsInputModel,
            ...fatcaInfo,
            taxResidency: dispatchObject.regulatory.taxResidency,
            taxResidencyOther: dispatchObject.regulatory.taxResidencyOther,
            ssn: dispatchObject.regulatory.ssn,
          },
          (res) => {
            console.log(res);
            if (res.status === HTTP_STATUS.OK) {
              dispatchObject.member.sin = applicantData.member.sin;
              continuehandler(
                dispatchObject,
                AppConstants.APPLICATIONSTEP.TAX_INFO
              );
            }
          }
        );
      } else {
        doUpdateVaultMember(
          {
            ...vaultMember,
            sin: dispatchObject.member.sin,
            regulatoryFields: updateRegulatoryFieldsInputModel,
            ...fatcaInfo,
            taxResidency: dispatchObject.regulatory.taxResidency,
            taxResidencyOther: dispatchObject.regulatory.taxResidencyOther,
            ssn: dispatchObject.regulatory.ssn,
          },
          (res) => {
            console.log(res);
            if (res.status === HTTP_STATUS.OK) {
              dispatchObject.member.sin = applicantData.member.sin;
              continuehandler(
                dispatchObject,
                AppConstants.APPLICATIONSTEP.TAX_INFO
              );
            }
          }
        );
      }
    } else {
      continuehandler(dispatchObject, AppConstants.APPLICATIONSTEP.TAX_INFO);
    }
  };

  render() {
    const { editMode, newMember } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      applicantData,
      countriesTax,
      isEdit,
      actionBack,
      products,
      getActiveProduct,
      processFlow,
      inSessionJointApplicant,
      vaultMember,
    } = this.props;
    let activeProduct = getActiveProduct(products);
    if (inSessionJointApplicant) {
      activeProduct = {
        ...activeProduct,
        prospect: inSessionJointApplicant.prospect,
      };
    }
    return (
      <>
        <TaxReporting
          editMode={editMode}
          newMember={newMember}
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          finInfo={finInfo}
          applicantData={applicantData}
          countriesTax={countriesTax}
          isEdit={isEdit}
          actionBack={actionBack}
          activeProduct={activeProduct}
          processFlow={processFlow}
          vaultMember={vaultMember}
          inSessionJointApplicant={inSessionJointApplicant}
        />
      </>
    );
  }
}

TaxReportingContainer.propTypes = {
  EmploymentAndIncomeStatus: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

TaxReportingContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  EmploymentAndIncomeStatus: state.EmploymentAndIncomeReducer,
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
  countriesTax: state.ApplicationReducer.countriesTax,
  processFlow: state.ApplicationReducer.processTemplate.flow,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
  vaultMember: state.ApplicationReducer.vaultMember,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      countryList: getCountryList,
      doGetCountriesTax: getCountriesTax,
      doUpdateVaultMember: updateVaultMember,
    },
    dispatch
  );
};

const TaxReportingWrapper = withApplicantHOC(TaxReportingContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxReportingWrapper);
