import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessOverdraft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessOverdraft: "",
      businessOverdraftErrorList: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_OVERDRAFT,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        businessOverdraft: !!activeProduct?.businessOverdraft,
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { businessOverdraft } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({
        request: { hasOverdraftProtection: businessOverdraft },
        vault: { businessOverdraft },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_OVERDRAFT);
  };

  handleChoice = (name, value) => {
    const errorObject = `${name}${STRINGS.COMMON.ERROR_LIST}`;
    this.setState({
      [name]: value,
      [errorObject]: "",
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;

    const { businessOverdraft, businessOverdraftErrorList } = this.state;

    const businessOverdraftRes = checkErrors(
      businessOverdraft,
      VALIDATIONS.BUSINESS_OVERDRAFT.OVERDRAFT
    );

    this.setState({
      businessOverdraftErrorList: businessOverdraftRes,
    });

    if (businessOverdraftRes.length > 0) {
      formIsValid = false;
    }

    return formIsValid;
  };

  render() {
    const { steps, activeStepID, removeApplication } = this.props;
    const { businessOverdraft, businessOverdraftErrorList } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ business-overdraft-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_OVERDRAFT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_OVERDRAFT.FORM.ACCOUNT_OVERDRAFT_LABEL}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        businessOverdraft
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="businessOverdraft"
                      onClick={(event) =>
                        this.handleChoice("businessOverdraft", true, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        !businessOverdraft
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="businessOverdraft"
                      onClick={(event) =>
                        this.handleChoice("businessOverdraft", false, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                  <div className="[ error-msg ]">
                    {businessOverdraftErrorList[0]}
                  </div>
                  <p className="[ col-12 overdraft-hint ]">
                    <small>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            STRINGS.BUSINESS_OVERDRAFT.FORM
                              .OVERDRAFT_PROTECTION_HINT_TEXT,
                        }}
                      />
                      <br />
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            STRINGS.BUSINESS_OVERDRAFT.FORM
                              .OVERDRAFT_PROTECTION_HINT_TEXT1,
                        }}
                      />
                    </small>
                  </p>
                </div>
              </div>

              {/* EDWIN — like with other FIs, the client may have different T&Cs for business overdrafts from their personal overdrafts */}
              {/* {finInfo.businessOverdraftTermsAndConditions && busiessNeedProtection && (
                <div>
                  <div className="[ drawer-content-container ] [ mb-3 ]">
                    <div className="[ terms-and-conditions__links-container ]">
                      <div className="[ alert alert-info ]" role="alert">
                        <h4 className="[ alert-heading ]">
                          {STRINGS.MEMBERCREATION.AGREE_TERMS}
                        </h4>
                        <hr />

                        {finInfo.businessOverdraftTermsAndConditionsDocument.length > 0 && (
                          <LinkTermComponent
                            linkTermsArray={
                              finInfo.businessOverdraftTermsAndConditionsDocument
                            }
                            linkClickHandler={this.linkClickHandler}
                          />
                        )}
                      </div>
                    </div>
                    <div className="[ divider ]" />
                    <div className="[ mb-3 ]">
                      <div className="[ row ] [ align-items-center ]">
                        <div className="[ col-12 ] ">
                          <div className=" [ checkbox custom-control custom-checkbox ]">
                            <input
                              type="checkbox"
                              checked={isAcceptedTerms}
                              onChange={this.handleChecked}
                              name="buinessTermscondition"
                              id="businessTermsCheckbox"
                              className="[ custom-control-input ]"
                            />
                            <label
                              htmlFor="businessTermsCheckbox"
                              className="[ custom-control-label ]"
                            >
                              <span className="[ form-checkbox__text ]">
                                {finInfo.businessOverdraftTermsAndConditions}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="[ error-msg ] [ col-12 ]">
                          {businessTermsConditionError}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessOverdraft.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessOverdraft.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessOverdraft;
