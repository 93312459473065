import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import updateComplianceData from "Redux/Actions/UpdateApplicantAction";
import { toggleBackFlag } from "Redux/Actions/ApplicationAction";
import AppConstants from "Constants/AppConstants";
import * as ROUTES from "Constants/Routes";

import {
  getAccountPurposeList,
  getIndicateWhoList,
} from "./Actions/ComplianceActions";
import Compliance from "./Compliance";
import {
  allowKycEdit,
  allowKycEditForJointInSession,
  isNewMember,
  isPepDataEqual,
  isThirdPartyDataEqual,
  showCompliance,
} from "../../Utils/LogicUtilities";
import {
  getIncomeSourcesList,
  getStatusList,
} from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { findCode, pepExist } from "../../Utils/CommonUtilities";
import { updateVaultMember } from "../AboutYou/Actions/AboutYouActions";
import { HTTP_STATUS } from "../../Communication/Constants";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import { setInSessionJointApplicant } from "../JointApplicant/Actions/JointApplicantAction";

class ComplianceContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
    };
  }

  componentDidMount() {
    const {
      dogetAccountPurposeList,
      dogetIndicateWhoList,
      doToggleBackFlag,
      applicantData,
      actionBack,
      continuehandler,
      handleBack,
      applicationCompleteList,
      vaultProductList,
      getActiveProduct,
      inSessionJointApplicant,
      accountPurposeList,
      indicateWhoList,
      EmploymentAndIncomeStatus,
      statusList,
      incomeSourcesList,
      doDisableBack,
      processTemplate,
      finInfo,
      sessionCompletedProducts,
    } = this.props;
    if (EmploymentAndIncomeStatus.statusList.length === 0) {
      statusList();
    }
    if (EmploymentAndIncomeStatus.incomeSourcesList.length === 0) {
      incomeSourcesList();
    }
    doToggleBackFlag(false); //dynamic steps should stop in compliance step
    const activeProduct = getActiveProduct(vaultProductList);
    if (
      showCompliance(
        applicantData,
        applicationCompleteList,
        inSessionJointApplicant,
        activeProduct,
        finInfo,
        sessionCompletedProducts
      )
    ) {
      if (inSessionJointApplicant) {
        const step = processTemplate.flow.find(
          (s) =>
            s.componentName === AppConstants.APPLICATIONSTEP.EMPLOYMENT ||
            s.componentName === AppConstants.APPLICATIONSTEP.TAX_INFO
        );
        if (!step) {
          doDisableBack(true);
        }
      }
      if (accountPurposeList?.length === 0) {
        dogetAccountPurposeList();
      }
      if (indicateWhoList?.length === 0) {
        dogetIndicateWhoList();
      }
    } else if (actionBack) {
      handleBack(AppConstants.APPLICATIONSTEP.COMPLIANCE);
    } else {
      continuehandler(null, AppConstants.APPLICATIONSTEP.COMPLIANCE);
    }
  }

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handleUpdateComplianceData = (formdata) => {
    console.log("handleUpdateComplianceData", formdata);
    const {
      continuehandler,
      occupationCodes,
      applicantData,
      getActiveProduct,
      vaultProductList,
      inSessionJointApplicant,
      sessionCompletedProducts,
      finInfo,
      vaultMember,
      doUpdateVaultMember,
      doUpdateProductToVault,
      doSetInSessionJointApplicant,
    } = this.props;
    let jurisdictionType = "";
    if (formdata.PEPDomestic) {
      jurisdictionType = AppConstants.COMPLIANCE.JURISDICTION_TYPE.DOMESTIC;
    } else if (formdata.PEPForeign) {
      jurisdictionType = AppConstants.COMPLIANCE.JURISDICTION_TYPE.FOREIGN;
    }

    const thirdPartyData = {
      name: formdata.thirdPartyName,
      relationship: formdata.thirdPartyRelationship,
    };

    const PEPData = {
      fundsSource: formdata.anySourceOfFunds,
      hio: formdata.HIO ? formdata.HIO : false,
      jurisdiction: formdata.anyJurisdiction,
      jurisdictionType,
      name: formdata.indicateName,
      officeHeld: formdata.anyOfficeHeld,
      relationship: formdata.anyRelationship,
    };
    const dispatchObject = {
      isAnyoneConsideredPEPHIO: formdata.isAnyoneConsideredPEPHIO,
      member: {
        intendedUseOfMembership: formdata.accountPurpose,
        pep: formdata.areYouPEPorHIO,
      },
      address: {
        mailingAddressLineType1: "",
        currentAddressSinceMonth: "",
        addressPreviousSinceMonth: "",
      },
      regulatory: {
        memberHasThirdParty: formdata.isThirdPartyAccount,
        memberIsPedp: formdata.areYouPEPorHIO
          ? formdata.memberPEPDomestic
          : false,
        memberIsPefp: formdata.areYouPEPorHIO
          ? formdata.memberPEPForeign
          : false,
        memberIsHio: formdata.areYouPEPorHIO ? formdata.memberHIO : false,
        memberOfficeHeld: formdata.areYouPEPorHIO
          ? formdata.memberOfficeHeld
          : "",
        memberJurisdiction: formdata.areYouPEPorHIO
          ? formdata.memberJurisdiction
          : "",
        memberFundsSource: formdata.areYouPEPorHIO
          ? formdata.memberFundsSource
          : "",
      },

      thirdParties: formdata.isThirdPartyAccount ? [thirdPartyData] : [],

      peps: formdata.isAnyoneConsideredPEPHIO ? [PEPData] : [],
    };

    if (
      formdata.employerPhoneSettings.page ===
      AppConstants.APPLICATIONSTEP.COMPLIANCE
    ) {
      const validMobileNo =
        formdata &&
        formdata.employerContactNumber.replace(
          AppConstants.EMPLOYMENTANDINCOME.CONTACT_NO_PATTERN,
          ""
        ); // For Removing special characters

      const PhoneArea = validMobileNo.substring(0, 3); // To get first 10 digit for employerPhoneArea
      const PhoneNumber = validMobileNo.substring(3); // To get first last 7 digit for employerPhone
      console.log("PhoneArea", PhoneArea);
      console.log("PhoneNumber", PhoneNumber);
      if (dispatchObject.employment) {
        dispatchObject.employment.employerPhoneArea = PhoneArea;
        dispatchObject.employment.employerPhone = PhoneNumber;
      } else {
        dispatchObject.employment = {
          employerPhoneArea: PhoneArea,
          employerPhone: PhoneNumber,
        };
      }
    }
    if (
      formdata.occupationCodeSettings.page ===
      AppConstants.APPLICATIONSTEP.COMPLIANCE
    ) {
      if (dispatchObject.employment) {
        dispatchObject.employment.occupationCode = formdata.occupationCode;
        const jobTitle = findCode(formdata.occupationCode, occupationCodes);
        dispatchObject.employment.jobTitle = jobTitle;
      } else {
        dispatchObject.employment = {
          occupationCode: formdata.occupationCode,
        };
      }
    }
    if (
      formdata.sourceOfIncomeSettings.page ===
      AppConstants.APPLICATIONSTEP.COMPLIANCE
    ) {
      if (dispatchObject.employment) {
        dispatchObject.employment.incomeType = formdata.sourceOfIncome;
      } else {
        dispatchObject.employment = {
          incomeType: formdata.sourceOfIncome,
        };
      }
    }
    // Call update applicant api using HOC
    const activeProduct = getActiveProduct(vaultProductList);
    if (
      !activeProduct.prospect &&
      allowKycEdit(
        applicantData,
        sessionCompletedProducts,
        finInfo,
        inSessionJointApplicant
      )
    ) {
      doUpdateVaultMember(
        {
          ...vaultMember,
          memberFundsSource: dispatchObject.regulatory.memberFundsSource,
          memberHasThirdParty: dispatchObject.regulatory.memberHasThirdParty,
          memberIsHio: dispatchObject.regulatory.memberIsHio,
          memberIsPedp: dispatchObject.regulatory.memberIsPedp,
          memberIsPefp: dispatchObject.regulatory.memberIsPefp,
          memberJurisdiction: dispatchObject.regulatory.memberJurisdiction,
          memberOfficeHeld: dispatchObject.regulatory.memberOfficeHeld,
          thirdParties: dispatchObject.thirdParties,
          peps: dispatchObject.peps,
          pep: dispatchObject.member.pep,
        },
        (res) => {
          console.log(res);
          if (res.status === HTTP_STATUS.OK) {
            if (!pepExist(applicantData) && pepExist(dispatchObject)) {
              vaultProductList[
                vaultProductList.findIndex((x) => x === activeProduct)
              ].pepAdded = true;
              doUpdateProductToVault(vaultProductList);
            } else if (pepExist(applicantData) && !pepExist(dispatchObject)) {
              vaultProductList[
                vaultProductList.findIndex((x) => x === activeProduct)
              ].pepDeleted = true;
              doUpdateProductToVault(vaultProductList);
            } else if (
              dispatchObject.regulatory.memberFundsSource !==
                applicantData.regulatory.memberFundsSource ||
              dispatchObject.regulatory.memberHasThirdParty !==
                applicantData.regulatory.memberHasThirdParty ||
              dispatchObject.regulatory.memberIsHio !==
                applicantData.regulatory.memberIsHio ||
              dispatchObject.regulatory.memberIsPedp !==
                applicantData.regulatory.memberIsPedp ||
              dispatchObject.regulatory.memberIsPefp !==
                applicantData.regulatory.memberIsPefp ||
              dispatchObject.regulatory.memberJurisdiction !==
                applicantData.regulatory.memberJurisdiction ||
              dispatchObject.regulatory.memberOfficeHeld !==
                applicantData.regulatory.memberOfficeHeld ||
              !isThirdPartyDataEqual(applicantData, dispatchObject) ||
              !isPepDataEqual(applicantData, dispatchObject)
            ) {
              vaultProductList[
                vaultProductList.findIndex((x) => x === activeProduct)
              ].pepUpdated = true;
              doUpdateProductToVault(vaultProductList);
            }

            continuehandler(
              dispatchObject,
              AppConstants.APPLICATIONSTEP.COMPLIANCE
            );
          }
        }
      );
    }
    if (
      inSessionJointApplicant &&
      allowKycEditForJointInSession(applicantData, finInfo)
    ) {
      doUpdateVaultMember(
        {
          ...inSessionJointApplicant.vault,
          inSessionJoint: inSessionJointApplicant.applicantId,
          memberFundsSource: dispatchObject.regulatory.memberFundsSource,
          memberHasThirdParty: dispatchObject.regulatory.memberHasThirdParty,
          memberIsHio: dispatchObject.regulatory.memberIsHio,
          memberIsPedp: dispatchObject.regulatory.memberIsPedp,
          memberIsPefp: dispatchObject.regulatory.memberIsPefp,
          memberJurisdiction: dispatchObject.regulatory.memberJurisdiction,
          memberOfficeHeld: dispatchObject.regulatory.memberOfficeHeld,
          thirdParties: dispatchObject.thirdParties,
          peps: dispatchObject.peps,
          pep: dispatchObject.member.pep,
          pepAdded: !pepExist(applicantData) && pepExist(dispatchObject),
          pepDeleted: pepExist(applicantData) && !pepExist(dispatchObject),
          pepUpdated:
            dispatchObject.regulatory.memberFundsSource !==
              applicantData.regulatory.memberFundsSource ||
            dispatchObject.regulatory.memberHasThirdParty !==
              applicantData.regulatory.memberHasThirdParty ||
            dispatchObject.regulatory.memberIsHio !==
              applicantData.regulatory.memberIsHio ||
            dispatchObject.regulatory.memberIsPedp !==
              applicantData.regulatory.memberIsPedp ||
            dispatchObject.regulatory.memberIsPefp !==
              applicantData.regulatory.memberIsPefp ||
            dispatchObject.regulatory.memberJurisdiction !==
              applicantData.regulatory.memberJurisdiction ||
            dispatchObject.regulatory.memberOfficeHeld !==
              applicantData.regulatory.memberOfficeHeld ||
            !isThirdPartyDataEqual(applicantData, dispatchObject) ||
            !isPepDataEqual(applicantData, dispatchObject),
        },
        (res) => {
          console.log(res);
          if (res.status === HTTP_STATUS.OK) {
            continuehandler(
              dispatchObject,
              AppConstants.APPLICATIONSTEP.COMPLIANCE
            );
          }
        }
      );
    } else {
      continuehandler(dispatchObject, AppConstants.APPLICATIONSTEP.COMPLIANCE);
    }
  };

  render() {
    const { modalType, modal, showModal } = this.state;
    const {
      accountPurposeState,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      EmploymentAndIncomeStatus,
      occupationCodes,
      vaultProductList,
      getActiveProduct,
      processFlow,
      inSessionJointApplicant,
      applicantData,
      vaultMember,
      sessionCompletedProducts,
    } = this.props;

    let activeProduct = getActiveProduct(vaultProductList);
    if (inSessionJointApplicant) {
      activeProduct = {
        ...activeProduct,
        prospect: inSessionJointApplicant.prospect,
      };
    }

    return (
      <Compliance
        statusList={EmploymentAndIncomeStatus.statusList}
        occupationCodes={occupationCodes}
        handleUpdateComplianceData={this.handleUpdateComplianceData}
        accountPurposeArray={accountPurposeState.accountPurposeList}
        indicateWhoArray={accountPurposeState.indicateWhoList}
        bindFormData={accountPurposeState.data ? accountPurposeState.data : ""}
        type={modalType}
        modal={modal}
        showModal={showModal}
        toggleModal={this.toggleModal}
        backClick={this.backClick}
        getDataFromServer={getDataFromServer}
        handleBack={handleBack}
        steps={steps}
        activeStepID={activeStepID}
        finInfo={finInfo}
        incomeSourcesList={EmploymentAndIncomeStatus.incomeSourcesList}
        activeProduct={activeProduct}
        processFlow={processFlow}
        applicantData={applicantData}
        vaultMember={vaultMember}
        sessionCompletedProducts={sessionCompletedProducts}
        inSessionJointApplicant={inSessionJointApplicant}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dogetAccountPurposeList: () => dispatch(getAccountPurposeList()),
    dogetIndicateWhoList: () => dispatch(getIndicateWhoList()),
    doUpdateComplianceData: (formDetails, aplicationId, callback) =>
      dispatch(updateComplianceData(formDetails, aplicationId, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    statusList: () => dispatch(getStatusList()),
    incomeSourcesList: () => dispatch(getIncomeSourcesList()),
    doUpdateVaultMember: (payLoad, callback) =>
      updateVaultMember(payLoad, callback),
    doUpdateProductToVault: (vaultList, callback) =>
      dispatch(updateProductToVault(vaultList, callback)),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
  };
};

const mapStateToProps = (state) => ({
  accountPurposeList: state.ComplianceReducer.accountPurposeList,
  indicateWhoList: state.ComplianceReducer.indicateWhoList,
  accountPurposeState: state.ComplianceReducer,
  applicantData: state.ApplicationReducer.response,
  actionBack: state.ApplicationReducer.actionBack,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  vaultProductList: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  finInfo: state.MarketplaceReducer.finInfo,
  EmploymentAndIncomeStatus: state.EmploymentAndIncomeReducer,
  occupationCodes: state.ApplicationReducer.occupationCodes,
  processTemplate: state.ApplicationReducer.processTemplate,
  processFlow: state.ApplicationReducer.processTemplate.flow,
  sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
  vaultMember: state.ApplicationReducer.vaultMember,
});

ComplianceContainer.propTypes = {
  dogetIndicateWhoList: PropTypes.func.isRequired,
  dogetAccountPurposeList: PropTypes.func.isRequired,
  accountPurposeState: PropTypes.objectOf(PropTypes.any).isRequired,
  continuehandler: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doToggleBackFlag: PropTypes.func.isRequired,
  accountPurposeList: PropTypes.arrayOf(PropTypes.object),
  indicateWhoList: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.objectOf(PropTypes.any),
  EmploymentAndIncomeStatus: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  occupationCodes: PropTypes.arrayOf(PropTypes.object),
};
ComplianceContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  accountPurposeList: [],
  indicateWhoList: [],
  finInfo: {},
  occupationCodes: [],
};

const ComplianceWrapper = withApplicantHOC(ComplianceContainer);

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceWrapper);
