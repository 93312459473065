import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessAboutYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nonMember: false,
      show: [],
      foundMemberList: [],
      additionalMemberList: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_ABOUT_YOU,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeProduct !== this.props.activeProduct) {
      this.setFormData();
    }
  }

  setFormData = (data) => {
    const { activeProduct } = this.props;
    let memberSelected = activeProduct?.foundMemberList?.find(
      (x) => x.selectedMember
    );
    let nonMember = false;
    if (!memberSelected) {
      memberSelected = activeProduct?.additionalMemberList?.find(
        (x) => x.selectedMember
      );
    }
    if (!memberSelected) {
      nonMember = true;
    }
    this.setState({
      foundMemberList: activeProduct?.foundMemberList,
      additionalMemberList: activeProduct?.additionalMemberList,
      nonMember,
    });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { memberIndex, foundMemberList, additionalMemberList } = this.state;
    let valid = true;

    if (valid) {
      handleContinue({ foundMemberList, additionalMemberList });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_ABOUT_YOU);
  };

  handleChoice = (name, value, type) => {
    console.log("handleChoice", name, value, type);
    let { foundMemberList, additionalMemberList, nonMember } = this.state;
    foundMemberList.map((x) => (x.selectedMember = false));
    additionalMemberList.map((x) => (x.selectedMember = false));
    nonMember = false;
    if (type) {
      if (type === "none") {
        nonMember = true;
      } else {
        foundMemberList[value].selectedMember = true;
      }
    } else {
      additionalMemberList[value].selectedMember = true;
    }
    this.setState({
      foundMemberList,
      additionalMemberList,
      nonMember,
    });
  };

  verifyData = () => {
    const { memberIndex } = this.state;
    let formIsValid = true;

    if (memberIndex === "none") {
      // do store none value
    } else {
      // do store selected member in the list
    }

    return formIsValid;
  };

  formatPhoneNumber(phoneNumberString) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return "";
  }

  displayMemberRoles = (member) => {
    const roles = [];
    if (member?.owner) roles.push(STRINGS.BUSINESS_MEMBER.FORM.OWNER_LABEL);
    if (member?.designation) {
      roles.push(member.designation);
    } else if (member?.officeDirector) {
      roles.push(STRINGS.BUSINESS_MEMBER.FORM.OFFICER_DIRECTOR_LABEL);
    }
    if (member?.authorizedSignatory)
      roles.push(STRINGS.BUSINESS_MEMBER.FORM.AUTHORIZED_SIGNATORY_LABEL);

    return roles.join(", ");
  };

  render() {
    const { steps, activeStepID, handleEditPage, activeProduct } = this.props;
    const {
      show,
      memberList,
      memberIndex,
      foundMemberList,
      additionalMemberList,
      nonMember,
    } = this.state;
    // console.log(this.state);
    const memberDetailComponent = (member, index) => (
      <>
        <div className="[ row ] [ details-section__item ]">
          <div className="[ col-sm-3 ]">
            <div className="[ details-section__item-label ]">
              <input
                type="radio"
                value={index}
                name="memberIndex"
                onChange={(event) =>
                  this.handleChoice("memberIndex", index, member.type)
                }
                checked={!!member.selectedMember}
              />
            </div>
          </div>
          <div className="[ col-sm-3 ]">
            <div className="[ details-section__item-description ]">
              {member?.firstName} {member?.lastName}
            </div>
          </div>
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-description ]">
              {this.displayMemberRoles(member)}
            </div>
          </div>
        </div>
      </>
    );

    const memberListComponent = [
      ...(foundMemberList?.length > 0
        ? foundMemberList?.map((member, index) => (
            <>{memberDetailComponent(member, index)}</>
          ))
        : []),
      ...(additionalMemberList?.length > 0
        ? additionalMemberList?.map((member, index) => (
            <>{memberDetailComponent(member, index)}</>
          ))
        : []),
    ];

    const memberListContainerComponent = (
      <>
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.BUSINESS_MEMBER.FORM.GROUP_LABEL}
              </h3>
            </div>
          </div>

          {memberListComponent}
          <hr />
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-3 ]">
              <div className="[ details-section__item-label ]">
                <input
                  type="radio"
                  value="none"
                  name="memberIndex"
                  onChange={(event) =>
                    this.handleChoice("memberIndex", true, "none")
                  }
                  checked={nonMember}
                />
              </div>
            </div>
            <div className="[ col-sm-3 ]">
              <div className="[ details-section__item-description ]">
                {STRINGS.BUSINESS_ABOUT_YOU.NONE}
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const actionComponent = (
      <div className="[ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_REVIEW.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ] [ mb-3 ]">
            <div className="[ col-sm-12 col-md-9 ] [ mb-3 ]">
              <div className="[ form-subtitle ]">
                {STRINGS.BUSINESS_ABOUT_YOU.SUB_HEADER}
              </div>
              <div className="[ form-description ]">
                {STRINGS.BUSINESS_ABOUT_YOU.DESCRIPTION1}
              </div>
              <div className="[ form-description ]">
                {STRINGS.BUSINESS_ABOUT_YOU.DESCRIPTION2}
              </div>

              {/* 
                      BUSINESS COMPLIANCE
                  */}
              {memberListContainerComponent}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        blockBack={true}
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessAboutYou.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessAboutYou.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessAboutYou;
