import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import SearchMemberRequest from "../../Communication/ApiClasses/SearchMemberRequest";
import { STORE_MEMBERSHIPS_DATA } from "../Actions/actionTypes";

// worker
function* handleAction(action) {
  yield put(doLoadingStart(actionTypes.SEARCH_MEMBER));

  const searchMemberRequest = new SearchMemberRequest(action.data);
  try {
    const result = yield call(searchMemberRequest.getData);
    yield put({
      type: `${actionTypes.SEARCH_MEMBER}_SUCCESS`,
      response: result.data,
    });
    if (action.data?.cifId) {
      yield put({
        type: `${actionTypes.STORE_MEMBERSHIPS_DATA}`,
        data: result.data.MembershipsData,
      });
    }
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.SEARCH_MEMBER}_ERROR`,
      error,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.SEARCH_MEMBER));
  }
}

// watcher
export default function* handleSaga() {
  yield takeLatest(actionTypes.SEARCH_MEMBER, handleAction);
}
