import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";
import AppConstants from "Constants/AppConstants";

import {
  getProductFromVault,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  getLendingProduct,
  updateLendingProduct,
} from "Components/LoanDetails/Actions/LoanDetailsAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import { callCreateInveriteAccountList } from "Components/Inverite/Actions/InveriteAction";
import { HTTP_STATUS } from "Communication/Constants";
import Disbursement from "./Disbursement";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";
import InveriteAccountList from "../Inverite/InveriteAccountList";
import { getApplicantBankingProductList } from "../AccountInformation/Actions/AccountInformationAction";
import STRINGS from "../../Constants/Strings";

class DisbursementContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      choice: "",
      disbursement: {},
      accountList: [],
      productList: [],
      accountError: [],
      otherAccountError: [],
    };
  }

  componentDidMount() {
    const {
      products,
      getActiveProduct,
      bankingProductList,
      doGetApplicantBankingProductList,
      finInfo,
    } = this.props;
    console.log("componentDidMount");
    const activeProduct = getActiveProduct(products);
    if (activeProduct && finInfo?.demandProductCodes) {
      if (activeProduct.accountList) {
        this.setState({
          accountList: activeProduct.accountList,
        });
      }
      if (activeProduct.disbursement) {
        this.setState({
          disbursement: activeProduct.disbursement,
        });
      }
      if (bankingProductList && bankingProductList.length > 0) {
        const productList = bankingProductList.products?.filter((product) =>
          finInfo.demandProductCodes?.includes(product.productCode)
        );
        console.log("productList", productList);
        this.setState({
          productList,
        });
      } else {
        doGetApplicantBankingProductList(
          activeProduct.applicantId,
          (response) => {
            const productList = response.products?.filter((product) =>
              finInfo.demandProductCodes?.includes(product.productCode)
            );
            this.setState({
              productList,
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      products,
      getActiveProduct,
      bankingProductList,
      doGetApplicantBankingProductList,
      finInfo,
    } = this.props;
    console.log("componentDidUpdate");
    if (finInfo !== prevProps.finInfo) {
      const activeProduct = getActiveProduct(products);
      if (activeProduct) {
        if (activeProduct.accountList) {
          this.setState({
            accountList: activeProduct.accountList,
          });
        }
        if (activeProduct.disbursement) {
          this.setState({
            disbursement: activeProduct.disbursement,
          });
        }

        console.log("finInfo", finInfo);

        if (bankingProductList && bankingProductList.length > 0) {
          const productList = bankingProductList.products?.filter((product) =>
            finInfo.demandProductCodes?.includes(product.productCode)
          );
          console.log("productList", productList);
          this.setState({
            productList,
          });
        } else {
          doGetApplicantBankingProductList(
            activeProduct.applicantId,
            (response) => {
              const productList = response.products?.filter((product) =>
                finInfo.demandProductCodes?.includes(product.productCode)
              );
              this.setState({
                productList,
              });
            }
          );
        }
      }
    }
  }

  updateDisbursement = (disbursement) => {
    console.log("updateDisbursement", disbursement);
    if (disbursement) {
      if (
        disbursement.accountType === AppConstants.ACCOUNT_TYPE.OTHER &&
        disbursement.selectedOtherAccount
      ) {
        this.setState({
          disbursement,
          accountError: [],
          otherAccountError: [],
        });
      } else {
        this.setState({
          disbursement,
          accountError: [],
        });
      }
    }
  };

  handleChange = () => {};

  handleContinue = () => {
    console.log("handleContinue");
    const { disbursement } = this.state;
    const {
      continuehandler,
      products,
      getActiveProduct,
      doUpdateProductToVault,
      doUpdateLendingProduct,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    let disbursementData = {};

    if (disbursement.accountType) {
      if (disbursement.accountType === AppConstants.ACCOUNT_TYPE.OTHER) {
        if (disbursement.selectedOtherAccount) {
          disbursementData = {
            DisbDep1AcctNo: disbursement.selectedOtherAccount,
            DisbDep1Type: disbursement.accountType,
            DisbComment: disbursement.comment,
          };
        } else {
          this.setState({
            otherAccountError: [STRINGS.DISBURSEMENT.ERROR.REQUIRED_OTHER],
          });
          return;
        }
      } else {
        disbursementData = {
          DisbDep1AcctNo: disbursement.basicDetails.acctNo,
          DisbDep1Type: disbursement.accountType,
          DisbComment: disbursement.comment,
        };
      }

      doUpdateLendingProduct(
        activeProduct.applicationId,
        activeProduct.doximProductId,
        {
          lpDisbursementDetails: disbursementData,
        },
        (res) => {
          console.log(res);
          products[0].disbursement = disbursement;
          doUpdateProductToVault(products, () => {
            continuehandler(null, AppConstants.APPLICATIONSTEP.DISBURSEMENT);
          });
        }
      );
    } else {
      this.setState({
        accountError: [STRINGS.DISBURSEMENT.ERROR.REQUIRED],
      });
    }
  };

  toggleModal = () => {};

  showModal = () => {
    this.setState({});
  };

  handlePopupModalBtnClick = () => {
    const { signatureCompleted } = this.state;
    const { continuehandler } = this.props;
    if (signatureCompleted) {
      continuehandler({}, AppConstants.APPLICATIONSTEP.DISBURSEMENT);
    }
  };

  openInverite = () => {
    console.log("openInverite");
    const { doCreateInveriteAccountList } = this.props;
    doCreateInveriteAccountList((response) => {
      console.log(response);
      if (response.status === HTTP_STATUS.OK) {
        sessionStorage.setItem(
          AppConstants.SESSION.INVERITE_ACCOUNTLIST_REQUEST_GUID,
          response.data.requestGuid
        );
        this.setState({
          iframeModal: true,
          iframeUrl: response.data.iframeUrl,
        });
      }
    });
  };

  processInverite = (data) => {
    const { doUpdateProductToVault, products } = this.props;
    const { disbursement } = this.state;
    console.log("processInverite", data);
    const updateAccountList = data.accountList.map((acct) => {
      return {
        account: acct.account,
        account_description: acct.account_description,
        bank: acct.bank,
        institution: acct.institution,
        membership_number: acct.membership_number,
        routing_code: acct.routing_code,
        transit: acct.transit,
        type: acct.type,
      };
    });
    products[0].accountList = updateAccountList;
    doUpdateProductToVault(products, () => {
      this.setState({
        iframeModal: false,
        accountList: updateAccountList,
        disbursement: {
          ...disbursement,
          accountType: AppConstants.ACCOUNT_TYPE.OTHER,
        },
      });
    });
  };

  closeInveriteWindow = () => {
    this.setState({
      iframeModal: false,
    });
  };

  render() {
    const {
      choice,
      modal,
      modalType,
      showModal,
      iframeModal,
      iframeUrl,
      accountList,
      disbursement,
      productList,
      accountError,
      otherAccountError,
    } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <Disbursement
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          openInverite={this.openInverite}
          accountList={accountList}
          disbursement={disbursement}
          updateDisbursement={this.updateDisbursement}
          productList={productList}
          accountError={accountError}
          otherAccountError={otherAccountError}
          iframeModal={iframeModal}
        />
        {iframeModal && (
          <InveriteAccountList
            iframeUrl={iframeUrl}
            iframeModal={iframeModal}
            toggleModal={this.toggleModal}
            showModal={iframeModal}
            popupBtnClick={this.toggleModal}
            processInverite={this.processInverite}
            closeBtnClick={this.closeInveriteWindow}
          />
        )}
      </>
    );
  }
}

DisbursementContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.oneOfType([PropTypes.object]),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  doCreateInveriteAccountList: PropTypes.func.isRequired,
  doUpdateProductToVault: PropTypes.func.isRequired,
  doUpdateLendingProduct: PropTypes.func.isRequired,
  doGetApplicantBankingProductList: PropTypes.func.isRequired,
};

DisbursementContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  finInfo: {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantData: state.ApplicationReducer.response,
  bankingProductList: state.AccountInformationReducer.bankingProductList,
  finInfo: state.MarketplaceReducer.finInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLendingProduct: (applicationId, doximProductId, callback) =>
      dispatch(getLendingProduct(applicationId, doximProductId, callback)),
    doUpdateLendingProduct: (
      applicationId,
      doximProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLendingProduct(applicationId, doximProductId, payLoad, callback)
      ),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doCreateInveriteAccountList: (callback) =>
      dispatch(callCreateInveriteAccountList(callback)),
    doGetApplicantBankingProductList: (applicantId, callback) =>
      dispatch(getApplicantBankingProductList(applicantId, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const DisbursementWrapper = withApplicantHOC(DisbursementContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisbursementWrapper);
