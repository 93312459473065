import * as actionTypes from "Redux/Actions/actionTypes";
import AppConstants from "../../../Constants/AppConstants";
import { getProductCategoryList } from "../../../Utils/CommonUtilities";
import { S_BANKING_TYPE } from "../../../Communication/Constants";

const initialState = {
  productList: [],
  categoryList: [],
  bankingType: sessionStorage.getItem(S_BANKING_TYPE)
    ? sessionStorage.getItem(S_BANKING_TYPE)
    : AppConstants.BANKING_TYPE.PERSONAL,
  activeTab: 0,
};

const MarketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_PRODUCT_LIST:
      return {
        ...state,
        productList: action.data,
        categoryList: getProductCategoryList(action.data),
      };
    case actionTypes.FILTER_PRODUCT:
      return {
        ...state,
        productListFiltered: action.productList,
        categoryListFiltered: action.categoryList,
      };

    case actionTypes.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case actionTypes.STORE_FI_INFO:
      return {
        ...state,
        finInfo: process.env.REACT_APP_TEST_CORE
          ? { ...action.data, coreBankingType: process.env.REACT_APP_TEST_CORE }
          : action.data,
      };
    case actionTypes.FILTER_BANKING_TYPE:
      sessionStorage.setItem(S_BANKING_TYPE, action.bankingType);
      return {
        ...state,
        bankingType: action.bankingType,
      };

    case actionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default MarketplaceReducer;
