import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddApplicationSlot from "Communication/ApiClasses/AddApplicationSlot";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import InviteApplicationSlot from "Communication/ApiClasses/InviteApplicationSlot";
import AppConstants from "../../../Constants/AppConstants";

// worker
function* handleSaga(action) {
  console.log(action);
  yield put(
    doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.ADD_JOINT_APPLICANTS)
  );
  try {
    const inviteRequest = [];
    let result = {};
    for (const request of action.data) {
      const addApplicationSlot = new AddApplicationSlot(request);

      result = yield call(addApplicationSlot.getData);
      console.log("add joint result", result);
      const body = {
        email: request.jointApplicants[0].extra.email,
        msisdn: request.jointApplicants[0].extra.msisdn,
      };
      const inviteReq = {
        body,
        applicationId: request.applicationId,
        slotId: result.data.slots[0].id,
      };
      inviteRequest.push(inviteReq);
    }
    for (const request of inviteRequest) {
      try {
        const inviteApplicationSlot = new InviteApplicationSlot(request);
        const inviteResult = yield call(inviteApplicationSlot.getData);
        console.log("invite result", inviteResult);
      } catch (e) {
        console.error("inivite", e);
      }
    }
    yield put({
      type: actionTypes.ADD_JOINT_APPLICANTS_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ADD_JOINT_APPLICANTS_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.ADD_JOINT_APPLICANTS)
    );
  }
}

export default function* applicationWatcherSaga() {
  yield takeLatest(actionTypes.ADD_JOINT_APPLICANTS, handleSaga);
}
